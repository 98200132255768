import { ViewControllerService, ViewControllerMember } from '../view/ViewControllerService';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

import { RulesService } from '@smartobjx/smart.connectors';
import { AbstractRule } from '@smartobjx/smart.objx.models';
import { Tools } from 'src/app/shared/Tools';
import { CustomValidator } from 'src/app/shared/validation';
import { AuthService } from '../authentication/auth.service';

export default class AbstractRuleMediator {
    constructor(
        private _server: any,
        private _viewController: ViewControllerService,
        private _info: MatSnackBar,
        private _authService: AuthService,  

    ) { }
    get server(): RulesService { return this._server; }
    get viewController(): ViewControllerService { return this._viewController; }
    get info(): MatSnackBar { return this._info; }

    parseRule(exp: string): Observable<AbstractRule> {
        return this.server.parseRule(null, exp);
    }

    saveRule(
        model: any,
        rule: AbstractRule,
        view: ViewControllerMember,
        type: string,
        resetForm: () => void = null
    ) {
        const parent = this.viewController.getParent(model) as any;
        const parentView = this.viewController.getParentView(model) as any;


        const i = parent ? parent.PrimRules.indexOf(model) : -1;

        view.Loading = true;
        if (CustomValidator.is(rule, 'rule')) {
            this.parseRule(`"${rule.ExpressionText}"`)
                .subscribe(parsed => {
                    (rule as any).PrimRuleElements = (parsed as any).PrimRuleElements;
                    if (!_.isEmpty(type)) {
                        CustomValidator.SwitchType((rule as any).PrimRuleElements, type)
                    }
                    this.tempPreSave(rule, view, parent, parentView, i);
                });
        } else {
            this.tempPreSave(rule, view, parent, parentView, i, resetForm);
        }
    }
    // remove after full implementation
    private tempPreSave(rule: AbstractRule, view: ViewControllerMember = null, parent: any = null, parentView: ViewControllerMember, i: number = -1, resetForm: () => void = null) {
       
      //  rule.OwnerID = this._authService.getPOV()

        if (view.hasObservable) {
            this.newPreSave(rule, view, parent, parentView, i, resetForm);
        } else {
            this.preSave(rule, view, parent, parentView, i);
        }
    }

    private newPreSave(rule: AbstractRule, view: ViewControllerMember = null, parent: any = null, parentView: ViewControllerMember, i: number = -1, resetForm: () => void = null) {
        const isRuleset = CustomValidator.is(rule, 'ruleset');


        //    if (Tools.isEmptyGuid(rule.OID)) {
        //        parent.Version = rule.Version; // fix: on save new child, update parent date
        //        parent.PrimRules.push(rule);
        //        view.Loading = true;
        //        view.observable.update({ loading: true });
        //        this.save(parent)
        //            .then(updatedRule => {
        //                view.observable.resolve({ model: updatedRule });
        //
        //                parent = updatedRule as any;
        //                if (isRuleset) {
        //                    view.updateModel(parent.PrimRules.filter(o => o.Name === rule.Name)[0]);
        //                    resetForm();
        //                    view.Loading = false;
        //                } else {
        //                    view.close(true);
        //                }
        //                if(!_.isNull(parentView)){
        //                    parentView.SaveParentsList(rule.Version)
        //                }
        //            });
        //    } else {
        view.Loading = true;
        this.save(rule)
            .then(updatedRule => {
                view.updateModel(updatedRule);
                let added = false;
                if (!_.isNull(parent)) {
                    //parent.OwnerID = updatedRule.OwnerID
                    if (i >= 0) {
                        parent.PrimRules[i] = updatedRule;
                    }
                    else {
                        const i = parent ? (parent.PrimRules as Array<any>).indexOf(x=>x.OID == rule.Replaces ) : -1;
                        if( i >= 0){
                            parent.PrimRules[i] = updatedRule;
                        }
                        else{
                            parent.PrimRules.push(updatedRule)
                        }
                        added = true
                    }
                }

                if (isRuleset) {
                    resetForm();
                    view.Loading = false;
                } else {
                    view.close(true);
                }
                if (!_.isNull(parentView) && (parent.OwnerID == updatedRule.OwnerID || (added && parent.OwnerID != updatedRule.OwnerID) )) {
                    parentView.SaveParentsList(rule.Version)
                }
            });
        //    }
    }

    private preSave(rule: AbstractRule, childView: ViewControllerMember = null, parent: any = null, parentView: ViewControllerMember, i: number = -1) {
        const isRuleset = CustomValidator.is(rule, 'ruleset');
        childView.Loading = true;
        let added = false
        this.save(rule)
            .then(updatedRule => {
                childView.updateModel(updatedRule);
                if (!_.isNull(parent)) {
                    if (i >= 0) {
                        parent.PrimRules[i] = updatedRule;
                    }
                    else {
                        parent.PrimRules.push(updatedRule)
                        added = true
                    }
                }
                if (isRuleset) {
                    // resetForm();
                    childView.Loading = false;
                } else {
                    childView.close(true);
                }
                if (!_.isNull(parentView) && !_.isNull(parent) && (parent.OwnerID == updatedRule.OwnerID || (added && parent.OwnerID != updatedRule.OwnerID) ))  {
                    parentView.SaveParentsList(rule.Version)
                }
            });
        //if (Tools.isEmptyGuid(rule.OID)) {
        //    parent.Version = rule.Version; // fix: on save new child, update parent date
        //    parent.PrimRules.push(rule);
        //    let parentView = this.viewController.getView(parent);
        //    childView.Loading = true;
        //    parentView.Loading = true;
        //    this.save(parent)
        //        .then(updatedRule => {
        //            parentView.updateModel(updatedRule);
        //            parent = updatedRule as any;
        //            if (isRuleset) {
        //                childView.updateModel(parent.PrimRules.filter(o => o.Name === rule.Name)[0]);
        //                childView.Loading = false;
        //            } else {
        //                childView.close(true);
        //            }
        //            parentView.Loading = false;
        //            if(!_.isNull(parentView)){
        //                parentView.SaveParentsList(rule.Version)
        //            }

        //        });
        //} else {
        //    childView.Loading = true;
        //    this.save(rule)
        //        .then(updatedRule => {
        //            childView.updateModel(updatedRule);
        //            if (i >= 0) {
        //                parent.PrimRules[i] = updatedRule;
        //            }

        //            if (isRuleset) {
        //                childView.Loading = false;
        //            } else {
        //                childView.close(true);
        //            }
        //            if(!_.isNull(parentView)){
        //                parentView.SaveParentsList(rule.Version)
        //            }
        //        });
        //}
    }

    private async save(rule: AbstractRule): Promise<AbstractRule> {
        let actionMessage = "The changes were saved successfully";
        if (!Tools.isEmptyGuid(rule.OID)) {
           // await this.server.findRuleOn(rule.OID, Tools.dateToURLStringAsDate(rule.Version)).toPromise().then(
           //     () => null,
           //     () => actionMessage = "A new version of this rule was created."
           // );
        }
        const useCaseID = this.viewController.selectedUseCaseID;
        let resp = await this.server.saveRuleByUseCase(useCaseID, null, rule).toPromise();
        this.info.open(actionMessage, '', {
            duration: 2000,
            verticalPosition: 'top',
            horizontalPosition: 'end',
            panelClass: 'info-primary'
        });
        return resp;
    }
}