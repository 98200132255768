import { Injectable } from '@angular/core';
import { ViewControllerMember, ViewControllerService } from '../view/ViewControllerService';
import { Observable, Subscriber } from 'rxjs';
import { AbstractRule, UseCase, Rule, RuleSet, CalculationRule, CallbackRule } from '@smartobjx/smart.objx.models';
import { from, forkJoin } from 'rxjs';
import { tap, concatMap, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

import { RulesService, UseCaseService } from '@smartobjx/smart.connectors';
import { Tools } from 'src/app/shared/Tools';
import AbstractRuleMediator from './abstract-rule.mediator';
import { CustomValidator } from 'src/app/shared/validation';
import { AuthService } from '../authentication/auth.service';
import * as moment from "moment";

export function factory() {
    return (
        _server: RulesService,
        _useCaseServer: UseCaseService,
        _vc: ViewControllerService,
        _sb: MatSnackBar,
        _authService: AuthService,  
    ): Mediator => {
        return new Mediator(_server, _useCaseServer, _vc, _sb,_authService);
    };
}

@Injectable({
    providedIn: 'root'
})
export default class Mediator extends AbstractRuleMediator {
    constructor(
        server: RulesService,
        private useCaseServer: UseCaseService,
        viewController: ViewControllerService,
        info: MatSnackBar,
        private authService: AuthService,  
    ) {
        super(server, viewController, info,authService)
    }

    saveRuleDirect(useCaseID: string, rule: AbstractRule): Observable<any> {
        return useCaseID
            ? this.server.saveRuleByUseCase(useCaseID, null, rule)
            : this.server.saveRuleByUseCase('00000000-0000-0000-0000-000000000000', null, rule);
    }
    saveUseCase(rule: AbstractRule): Observable<any> {
        return this.useCaseServer.saveUseCase(null,
            new UseCase({
                Name: rule.Name,
                RuleID: rule.OID,
                Version: rule.Version
            })
        );
    }
    disableRule(useCaseID: string, rule: AbstractRule): Observable<any> {
        return this.server.disableRuleTemp(useCaseID, null, rule);
    }
    enableRule(useCaseID: string, rule: AbstractRule): Observable<any> {
        return this.server.enableRuleTemp(useCaseID, null, rule);
    }

    //copy from use-case-browser
    findRuleOn(view: ViewControllerMember, ruleID: string, date: Date) {
        view.Loading = true;
        date = CustomValidator.fixDate(date);
        date = CustomValidator.fixSecondstoEnd(date);
        let offset = moment.parseZone(date).utcOffset();
        date = moment.utc(date).add(-1 * offset, "minutes").format() as any;
        return this.server.findRuleOn(ruleID, date,this.authService.GetSelectedPerspective())
            .pipe(tap(
                (abstractRule: AbstractRule) => {
                    view.Loading = false;
                    view.updateModel(abstractRule);
                },
                e => { view.Loading = false; }
            )
            );
    }

    findRuleOnWithVersionInfo(view: ViewControllerMember, ruleID: string, date: Date) {
        view.Loading = true;
        date = CustomValidator.fixDate(date);
        date = CustomValidator.fixSecondstoEnd(date);
        let offset = moment.parseZone(date).utcOffset();
        date = moment.utc(date).add(-1 * offset, "minutes").format() as any;
        return this.server.findRuleOnWithVersionInfo(ruleID, Tools.dateToURLStringAsDate(date),this.authService.GetSelectedPerspective())
            .pipe(tap(
                (data: any) => {
                    view.Loading = false;
                    view.updateModel(data.rule as AbstractRule);
                    view.updateData({ changedChildren: data.changedChildren });
                },
                e => {
                    view.Loading = false;
                }
            )
            );
    }

    newRule(view: ViewControllerMember, parent: RuleSet, startDate: Date, parentComponent) {
        let rule = new Rule();
        const now = new Date();
        const version = startDate > now ? startDate : now;
        rule.Version = version;

        this.newAbstractRule(view, rule, parent, version, parentComponent);
    }
    newRuleSet(view: ViewControllerMember, parent: RuleSet, startDate: Date, parentComponent) {
        let rule = new RuleSet();
        const now = new Date();
        const version = startDate > now ? startDate : now;
        rule.Version = version;

        this.newAbstractRule(view, rule, parent, version, parentComponent);
    }
    newCallbackRule(view: ViewControllerMember, parent: RuleSet, startDate: Date, parentComponent) {
        let rule = new CallbackRule();
        rule.Version = startDate;

        this.newAbstractRule(view, rule, parent, startDate, parentComponent);
    }
    newCalculationRule(view: ViewControllerMember, parent: RuleSet, startDate: Date, parentComponent) {
        let rule = new CalculationRule();
        rule.Algorithm.Name = "Unnamed Algorithm";
        rule.Version = startDate;

        this.newAbstractRule(view, rule, parent, startDate, parentComponent);
    }

    private newAbstractRule(view: ViewControllerMember, rule: AbstractRule, parent: RuleSet, startDate: Date, parentComponent) {
        const observable = new Observable(subscriber => {
            this.viewController.editRuleWithParent({ rule, parent, subscriber, startDate }, parentComponent);
        });

        observable.subscribe({
            next(data: any) { // custom this part to separate every call (update and resolve)
                if (data.loading) {
                    view.Loading = true;
                } else {
                    view.updateModel(data.model);
                }
            },
            error: e => {
                console.error(e);
                view.Loading = false;
            },
            complete: () => view.Loading = false
        })
    }

    editRule(rule: AbstractRule, parent: RuleSet, versionDateData: any, callback: () => void, startDate?: Date, parentComponent?) {
        this.simpleRuleAction(
            subscriber => {
                this.viewController.editRuleWithParent({ rule, parent, versionDateData, subscriber, startDate }, parentComponent, versionDateData);
            },
            callback
        );
    }

    debugRule(rule: AbstractRule, parent: RuleSet, versionDateData: any, callback: () => void, parentComponent) {
        this.simpleRuleAction(
            subscriber => {
                this.viewController.showDebugger(parentComponent, rule, parent, { versionDateData, subscriber });
            },
            callback
        );
    }

    showRuleVersions(rule: AbstractRule, parent: RuleSet, date: Date, versionDates: any[], callback: () => void) {
        this.simpleRuleAction(
            subscriber => {
                let viewRef = this.viewController.showVersions(date, versionDates, parent, subscriber);
                if (CustomValidator.is(rule, 'ruleset')) {
                    this.findRuleOnWithVersionInfo(viewRef, rule.OID, date).toPromise();
                } else {
                    this.findRuleOn(viewRef, rule.OID, date).toPromise();
                }
            },
            callback
        );
    }

    simpleRuleAction(action: (subscriber: Subscriber<any>) => void, callback: () => void) {
        const observable = new Observable(action);

        observable.subscribe({
            error: e => console.error(e),
            complete: callback
        })
    }

    saveRuleWithDisabledOrEnabledChildren(model: any, view: ViewControllerMember, rule: any, date: Date, stack: any[], resetForm: () => void = null) {
        const useCaseID = this.viewController.selectedUseCaseID;

        // parallel issue
        // --> 2+ actions (disable/enable) before create replacement for RS
        // --> when there is no placement for the parent both actions tries to create one. Getting two replacements for the same RS.
        // so, must be sequencial...
        view.Loading = true;
        const observable = forkJoin(
            from(stack).pipe(
                concatMap(o => {
                    let updatedRule = Object.assign({}, o.rule, { Version: date });
                    let call = o.disable
                        ? this.disableRule(useCaseID, updatedRule)
                        : this.enableRule(useCaseID, updatedRule);

                    // replace old rules

                    return call.pipe(
                        map(r => {
                            const i = rule.PrimRules.indexOf(o.rule);
                            rule.PrimRules[i] = r;
                            return r;
                        })
                    );
                })
            )
        );

        observable.subscribe(() => { // after all calls
            this.saveRule(
                model,
                rule,
                view,
                null,
                resetForm
            );
        });
    }


    createUseCaseFromRuleSet(view: ViewControllerMember, ruleset: RuleSet, resetForm: () => void) {
        let usecase: UseCase = new UseCase();
        usecase.Name = ruleset.Name;
        usecase.Version = ruleset.Version;

        view.Loading = true;
        view.observable.update({ loading: true });

        this.saveRuleDirect(null, ruleset)
            .subscribe((newRule: AbstractRule) => {
                if (newRule != null) {
                    this.saveUseCase(newRule)
                        .subscribe((newUseCase: UseCase) => {
                            // this.viewController.selectedUseCaseID = newUseCase.OID;
                            view.observable.resolve({ name: newUseCase.Name, OID: newUseCase.OID });
                            view.updateModel(newRule);
                            const version = CustomValidator.ensureDate(newRule.Version);
                            if (version > (new Date())) {
                                view.updateData({ versionDateData: { date: version, showTime: false, altClass: false } });
                            }
                            view.updateData({ newUseCase: false });
                            view.Loading = false;
                            resetForm();
                        });
                } else {
                    throw 'something went wrong';
                }
            });
    }
}