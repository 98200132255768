import { Component, Inject, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { CustomValidator, ALL_LETTERS_OR_ALL_NUMBER_OR_CONSTANT_REGEXP } from '../shared/validation';

@Component({
    selector: 'calculation-rule-advanced-view',
    templateUrl: './calculation-rule-advanced-view.component.html',
    styleUrls: [ './calculation-rule-advanced-view.component.scss' ]
})
export class CalculationRuleAdvancedViewComponent implements OnInit, AfterContentChecked  {
    // #region Event Handlers
    add(andOr: string, previousIndex: number) {
        this.mainValidations.insert(previousIndex + 1, this.getNewValidations(andOr));
    }
    remove(elementIndex: number) {
        this.mainValidations.removeAt(elementIndex);
        this.finalExpression = null;
    }
    
    refreshIf(ev: any) {
        if(ev.keyCode >= 32 && ev.keyCode <= 111 )
            this.refresh();
    }
    refresh() {
        let result = '', valid = true;
        let expressions: SimpleRuleExpression[] = this.mainValidations.value.map((o: Partial<SimpleRuleExpression>) => new SimpleRuleExpression(o));
        for (let i = 0; i < expressions.length && valid; i++) {
            let e = expressions[i];
            if(valid = e.isValid()) {
                result += e.toString();
            }
        }
        if (valid)
            this.finalExpression = result;
    }
    accept() {
        // console.log(this.form)
        // console.log(this.finalExpression) 
    }
    close() {
        this.dialogRef.close();
    }
    firstInputHasError(index: number){
        return this.inputHasError(index, 'First');
    }
    secondInputHasError(index: number){
        return this.inputHasError(index, 'Second');
    }
    // #endregion
    
    // #region private methods
    private createSimpleRuleExpression(e: string, connector?: string) {
        let ep = e.split(' ');
        return new SimpleRuleExpression({
            Connector: connector,
            First: ep[0], Operator: ep[1], Second: ep[2]
        })
    }
    private testAndAddToList(connector: string, fullExpression: string, list: SimpleRuleExpression[]) {
        const CONNECTOR: string = ` ${connector} `;
        if (!!~fullExpression.indexOf(CONNECTOR)) {
            let expressions = fullExpression.split(CONNECTOR);
            expressions.forEach((e, i) => {
                list.push(this.createSimpleRuleExpression(e, i===1 ? connector : null));
            })
        }
    }
    private getExpressionsAsList(fullExpression: string) : SimpleRuleExpression[] {
        let list: SimpleRuleExpression[] = [];
        this.testAndAddToList('AND', fullExpression, list);
        this.testAndAddToList('OR', fullExpression, list);
        if(!list.length) {
            list.push(this.createSimpleRuleExpression(fullExpression));
        }
        return list;
    }
    private getFromGroupWithExpression(expression: SimpleRuleExpression) {
        return new UntypedFormGroup({
            Connector: new UntypedFormControl( expression.Connector ),
            First: new UntypedFormControl( expression.First, { validators: [Validators.required, CustomValidator.allLettersOrAllNumbersOrConstant] } ),
            Operator: new UntypedFormControl( expression.Operator, { validators: [Validators.required] } ),
            Second: new UntypedFormControl( expression.Second, { validators: [Validators.required, CustomValidator.allLettersOrAllNumbersOrConstant] } ),
        })
    }
    private getNewValidations(andOr?: string) {
        let expression = new SimpleRuleExpression();
        if(andOr) expression.Connector = andOr;
        return this.getFromGroupWithExpression(expression);
    }
    private getControls(index: number) {
        return this.mainValidations.controls[index];
    }
    // numberInputHasError(index: number){
    //     return this.getControls(index)
    //         .get('numberInput')
    //         .hasError('invalidNumber');
    // }
    private inputHasError(index: number, name: string) {
        this.refresh();
        return this.getControls(index)
            .get(name)
            .hasError('invalidAllLettersOrAllNumber')
    }
    // #endregion
    
    // #region Construction and Initialization
    constructor(
        private changeDetector: ChangeDetectorRef,
        public dialogRef: MatDialogRef<CalculationRuleAdvancedViewComponent>,
        // public data: any
        @Inject(MAT_DIALOG_DATA) public data: any,
        // // @Inject(MAT_DIALOG_DATA) public data: Rule,
        // private server: RulesService ) {
        // if(data.PrimRuleElements && data.PrimRuleElements.length){
        //     this.ruleText = this.createExpression(data.PrimRuleElements);
        // }
    ) {
        // console.log(data) //expression

        // this.expressionParts.push(new SimpleRuleExpression());

    }

    onNoClick(): void {
        this.close();
    }
    ngOnInit() {
        let formArray = new UntypedFormArray([]);

        if(this.data && this.data.algorithm){
            // console.log(this.data);
            this.getExpressionsAsList(this.data.algorithm)
                .forEach(o => {
                    formArray.push(this.getFromGroupWithExpression(o));
                });
        } else {
            formArray.push(this.getNewValidations());
            // formArray = new FormArray([ this.getNewValidations() ])
        }
        this.form = new UntypedFormGroup({
            mainValidations: formArray
        });

    }
    ngAfterContentChecked() : void {
        this.changeDetector.detectChanges(); // fix finalExpression view
    }
    get mainValidations(): UntypedFormArray {
      return this.form.get("mainValidations") as UntypedFormArray;
    }
    // #endregion
    
    // #region Data Elements
    public form: UntypedFormGroup;
    public operators = [
        {value:'<', name:'< (lower than)'},
        {value:'>', name:'> (greater than)'},
        {value:'==', name:'== (equal to)'},
        {value:'!=', name:'!= (not equal to)'}
    ];
    public expressionParts: SimpleRuleExpression[] = [];
    public finalExpression: string;

    public helpText: string = `Some operators:
        < (Less than) / > (Greater than) / 
        == (Equal to) / != (Not equal to) / 
        And / Or / 
        All Of / Any Of / At Least`;

    public invalidAllLettersOrAllNumberMessage = "All letters or all numbers only!";
    // #endregion
}

// local class
class SimpleRuleExpression {
    constructor(config?: Partial<SimpleRuleExpression>) {
        Object.assign(this, config);
    }
    Connector: string;
    First: string = '';
    Operator: string = '';
    Second: string = '';

    ValidOperators = ['<', '>', '!=', '=='];
    ValidBinaryOperators = ['AND', 'OR'];

    toString() {
        return (this.Connector ? ` ${this.Connector} ` : '') + `${this.fixDot(this.First)} ${this.Operator} ${this.fixDot(this.Second)}`
    }
    isValid() : boolean {
        return ( !this.Connector || this.isPartOf(this.Connector, this.ValidBinaryOperators) )
            && ALL_LETTERS_OR_ALL_NUMBER_OR_CONSTANT_REGEXP.test(this.First)
            && this.isPartOf(this.Operator, this.ValidOperators)
            && ALL_LETTERS_OR_ALL_NUMBER_OR_CONSTANT_REGEXP.test(this.Second);
    }
    private isPartOf(item: any, list: any){
        return !!~list.indexOf(item)
    }
    private fixDot(number: string){
        return number[number.length-1] === '.'
            ? number.substring(0, number.length-1)
            : number;
    }
}