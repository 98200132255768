<mat-toolbar color="primary">
  <mat-toolbar-row>
    <mat-icon svgIcon="smart_objx_colorwhite" class="smart-objx"></mat-icon>
	<mat-divider class="style-fix" [vertical]="true"></mat-divider>
    <span class="rules">{{ title }}</span>
    <span class="toolbar-spacer"></span>
      <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>account_circle</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <!-- <a routerLink="/" routerLinkActive="active" style="text-decoration: none;">
          <button mat-menu-item>smart.rules</button>
        </a>
        <a routerLink="/structures" routerLinkActive="active" style="text-decoration: none;">
          <button mat-menu-item disabled>smart.structures</button>
        </a> -->
        <ng-container *ngIf="getShowMenu()">
          <span mat-menu-item >{{ perspective }}</span>
          <span mat-menu-item >{{ owner }}</span>
          <mat-divider></mat-divider>
        </ng-container>
        <button mat-menu-item (click)="logout()" >Log out</button>
      </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
<router-outlet></router-outlet>