import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// #region material



// import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from 'ngx-mat-datetime-picker';

// #endregion
import { RuleSetEditorComponent } from '../rule-set-editor/rule-set-editor.component';
import { RuleEditorComponent } from '../rule-editor/rule-editor.component';
import { FactbaseDisplayComponent } from '../factbase-display/factbase-display.component';
import { RuleDebuggerComponent } from '../rule-debugger/rule-debugger.component';
import { RuleDebuggerStepperComponent } from '../rule-debugger-stepper/rule-debugger-stepper.component';
import { UseCaseBrowserComponent } from '../use-case-browser/use-case-browser.component';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';
import { RuleDialogComponent } from '../rule-dialog/rule-dialog.component';
import { RuleAdvancedViewComponent } from '../rule-advanced-view/rule-advanced-view.component';
import { CalculationRuleAdvancedViewComponent } from '../calculation-rule-advanced-view/calculation-rule-advanced-view.component';
import { RuleVersionsComponent } from '../rule-versions/rule-versions.component';
import { RuleSetAdmComponent } from '../rule-set-adm/rule-set-adm.component';
import { StructuresComponent } from '../structures-adm/structures-adm.component';
import { CardListComponent } from '../card-list/card-list.component';
import { CustomDateComponent } from '../custom-date/custom-date.component';
import { RuleDebuggerAdvancedComponent } from '../rule-debugger-advanced/rule-debugger-advanced.component';
import { RuleSetNotReplaceableComponent } from '../rule-set-not-replaceable/rule-set-not-replaceable.component';
import { VersionCaptionComponent } from '../version-caption/version-caption.component';
import { UseCaseGeneratorComponent } from '../use-case-generator/use-case-generator.component';
import { SmartComponentsModule } from '@smartobjx/smart.components';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTreeModule } from '@angular/material/tree';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatGridListModule } from '@angular/material/grid-list';
import { DragDropModule } from '@angular/cdk/drag-drop';
const routes: Routes = [];
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // CommonModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatSelectModule,
    MatInputModule,
    MatGridListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatSidenavModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatTableModule,
    MatTreeModule,
    MatRadioModule,
    SmartComponentsModule,
    // Material custom
    // NgxMatDatetimePickerModule,
    // NgxMatTimepickerModule,
    // smartObjx
    DragDropModule,
    RouterModule.forRoot(routes, {}),
  ],
  declarations: [
    VersionCaptionComponent,
    RuleSetEditorComponent,
    RuleEditorComponent,
    UseCaseBrowserComponent,
    SimpleDialogComponent,
    RuleDialogComponent,
    RuleAdvancedViewComponent,
    CalculationRuleAdvancedViewComponent,
    FactbaseDisplayComponent,
    RuleDebuggerComponent,
    RuleDebuggerStepperComponent,
    RuleVersionsComponent,
    RuleSetAdmComponent,
    StructuresComponent,
    CardListComponent,
    CustomDateComponent,
    RuleDebuggerAdvancedComponent,
    RuleSetNotReplaceableComponent,
    UseCaseGeneratorComponent,
    
  ]
})
export class StandardModule { }