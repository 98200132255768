import { Injectable } from '@angular/core';
import { ViewControllerMember, ViewControllerService } from '../view/ViewControllerService';
import { Observable } from 'rxjs';
import { UseCase, RuleSet, AbstractRule } from '@smartobjx/smart.objx.models';
import { CustomValidator } from 'src/app/shared/validation';
import { Tools } from 'src/app/shared/Tools';
import { tap } from 'rxjs/operators';

import { RulesService, UseCaseService } from '@smartobjx/smart.connectors';
import { AuthService } from '../authentication/auth.service';
import { error } from 'console';

export function factory() {
    return (_server: RulesService, _useCaseServer: UseCaseService, _vc: ViewControllerService, _authService: AuthService): Mediator => {
        return new Mediator(_server, _useCaseServer, _vc, _authService);
    };
}

@Injectable({
    providedIn: 'root'
})
export default class Mediator {
    constructor(
        private server: RulesService,
        private useCaseServer: UseCaseService,
        private viewController: ViewControllerService,
        private AuthService: AuthService) { }

    getUseCases(view: ViewControllerMember): Observable<UseCase[]> {
        this.useCaseServer.configuration.SubscriberToken = this.AuthService.getTokenId();
        this.useCaseServer.configuration.POVToken = this.AuthService.getPOV();
        this.server.configuration.SubscriberToken = this.AuthService.getTokenId();
        this.server.configuration.POVToken = this.AuthService.getPOV();
        view.Loading = true;
        return this.useCaseServer.findAllUseCases(this.AuthService.GetSelectedPerspective())
            .pipe(tap(
                () => { view.Loading = false; },
                error => { view.Loading = false; }
            )
            );
    }

    findRuleWith(view: ViewControllerMember, ruleID: string) {
        view.Loading = true;
        return this.server.findRuleWith(ruleID,this.AuthService.GetSelectedPerspective())
            .pipe(tap(
                (abstractRule: AbstractRule) => {
                    let ruleSet = abstractRule as RuleSet;
                    ruleSet.Version = CustomValidator.ensureDate(ruleSet.Version);
                    view.Loading = false;
                    view.updateModel(ruleSet);
                },
                e => {
                    view.Loading = false;
                }
            )
            );
    }

    findRuleOnWithVersionInfo(view: ViewControllerMember, ruleID: string, date: Date) {
        view.Loading = true;
        return this.server.findRuleOnWithVersionInfo(ruleID, Tools.dateToURLStringAsDate(date),this.AuthService.GetSelectedPerspective())
            .pipe(tap(
                (data: any) => {
                    view.Loading = false;
                    view.updateModel(data.rule as AbstractRule);
                    view.updateData({ changedChildren: data.changedChildren });
                },
                e => {
                    view.Loading = false;
                }
            )
            );
    }

    findRuleOn(view: ViewControllerMember, ruleID: string, date: Date) {
        view.Loading = true;
        return this.server.findRuleOn(ruleID, Tools.dateToURLStringAsDate(date),this.AuthService.GetSelectedPerspective())
            .pipe(tap(
                (abstractRule: AbstractRule) => {
                    view.Loading = false;
                    view.updateModel(abstractRule);
                },
                e => {
                    view.Loading = false;
                }
            )
            );
    }
    findRule(view: ViewControllerMember, ruleID: string) {
        view.Loading = true;
        return this.server.findRuleWith(ruleID,this.AuthService.GetSelectedPerspective())
            .pipe(tap(
                (abstractRule: AbstractRule) => {
                    view.Loading = false;
                    view.updateModel(abstractRule);
                },
                e => {
                    view.Loading = false;
                }
            )
            );
    }

    deleteUseCaseWith(name: string): Promise<any> {
        return this.useCaseServer.deleteUseCaseWith(name).toPromise();
    }
    disableUseCaseWith(name: string): Promise<any> {
        return this.useCaseServer.disableUseCase(name).toPromise();
    }
    enableUseCaseWith(name: string): Promise<any> {
        return this.useCaseServer.enableUseCase(name).toPromise();
    }
    showGenerateUseCase(view: ViewControllerMember, callback: (updateList: boolean) => void) {
        let observable = new Observable(subscriber => {
            let viewRef = this.viewController.showUseCaseGenerator(subscriber); // show use case gen and drop all other views
            viewRef.Loading = false;
        });

        observable.subscribe({
            next(data: any) { // custom this part to separate every call (update and resolve)
                if (data.loading) {
                    view.Loading = true;
                } else {
                    callback(data.updateList);
                }
            },
            error: e => {
                console.error(e);
                view.Loading = false;
            },
            // complete: () => view.Loading = false // we want to wait the callback
        })
    }
    newUseCase(view: ViewControllerMember, parentComponent, callback: (name: string, oid: string) => void) {
        let observable = new Observable(subscriber => {
            let viewRef = this.viewController.showUseCaseRuleset(true, parentComponent, subscriber); // show ruleset and drop all other views
            if (viewRef !== undefined && viewRef !== null) viewRef.Loading = false;
        });

        observable.subscribe({
            next(data: any) { // custom this part to separate every call (update and resolve)
                if (data.loading) {
                    view.Loading = true;
                } else {
                    callback(data.name, data.OID);
                }
            },
            error: e => {
                console.error(e);
                view.Loading = false;
            },
            // complete: () => view.Loading = false // we want to wait the callback
        })
    }

    editUseCase(usecase: UseCase, clear: () => void, update: () => void, next: (rule) => void) {
        const observable = new Observable(subscriber => {
            let viewRef = this.viewController.showUseCaseRuleset(false, subscriber); // show ruleset and drop all other views
            if (!viewRef) {
                subscriber.error();
                return;
            }
            this.findRule(viewRef, usecase.RuleID)
                .subscribe({
                    next,
                    complete: update,
                    error: e => {
                        // tries to obtain future dates versions
                        this.findRuleWith(viewRef, usecase.RuleID)
                            .subscribe({
                                next,
                                complete: () => {
                                    update();
                                    viewRef.showVersionDate();
                                }
                            });
                    }
                });
        });

        observable.subscribe({
            error: e => { }, // on error just keep waiting
            complete: clear
        })
    }
    showRuleVersions(usecase: UseCase, date: Date, versionDates: any[], callback: () => void) {
        const observable = new Observable(subscriber => {
            let viewRef = this.viewController.showVersions(date, versionDates, null, subscriber);
            this.findRuleOnWithVersionInfo(viewRef, usecase.RuleID, date).toPromise();
        });

        observable.subscribe({
            error: e => console.error(e),
            complete: callback
        })
    }

    debugUseCase(parentComponent, usecase: UseCase, clear: () => void, update: () => void) {
        const observable = new Observable(subscriber => {
            let viewRef = this.viewController.showDebugger(parentComponent, null, null, { subscriber }); // show ruleset and drop all other views
            if (!viewRef) {
                subscriber.error();
                return;
            }
            this.findRuleOn(viewRef, usecase.RuleID, new Date())
                .subscribe(() => {
                    update();
                });
        });

        observable.subscribe({
            error: e => { }, // on error just keep waiting
            complete: clear
        })
    }
}