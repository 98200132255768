<mat-drawer-container class="full-height">
  <mat-drawer mode="side" opened *ngIf="skipIntro" style="width: 320px;">
    <mat-card style="padding: 8px; height: 100%;">
      <!-- <mat-form-field class="change-perspective">
        <mat-label>CHANGE PERSPECTIVE</mat-label>
        <mat-select>
          <mat-option *ngFor="let perspective of perspectives" [value]="perspective">
            {{ perspective }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->
      <button mat-flat-button
        class="change-perspective"
        (click)="this.addPerspective()"
      >
        CHANGE PERSPECTIVE
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-card-title class="title" style="border-bottom: unset;">
        <span>Perspective</span>
        <span class="toolbar-spacer"></span>
        <a color="primary" class="link">+ Add perspective</a>
      </mat-card-title>
      <mat-list>
        <mat-list-item class="custom">
          <div class="name">{{ perspectiveName }}</div>
          <div class="options">
            <button mat-icon-button (click)="this.onCreate.emit(record)" matTooltip="delete">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-list-item>
      </mat-list>
      <mat-card-title class="subtitle subscriber">
        <span>SUBSCRIBER</span>
      </mat-card-title>
      <div class="text">
        <span>Lous Vouitton</span>
      </div>
      <mat-card-title class="subtitle">
        <span>CLIENTS</span>
      </mat-card-title>
      <div class="text">
        <span>You haven´t added any clients yet</span>
      </div>
      <mat-card-title class="subtitle">
        <span>CUSTOMERS</span>
      </mat-card-title>
      <div class="text">
        <span>You haven´t added any customers yet</span>
      </div>
      <mat-card-title class="subtitle">
        <span>PARTNERS</span>
      </mat-card-title>
      <div class="text">
        <span>You haven´t added any partners yet</span>
      </div>
    </mat-card>
  </mat-drawer>
  <mat-drawer-content>
    <div style="padding: 5% 0;text-align: center;" *ngIf="!skipIntro">
      <div style="text-align: center;">
        <mat-icon svgIcon="use_cases_empty" class="use-cases-empty"></mat-icon>
      </div>
      <div class="h4">This section is empty</div>
      <div class="h6">Start by creating a perspective</div>
      <div style="padding-top: 20px;">
        <button mat-raised-button
            color="primary"
            class="toolbar-bottom-button"
            (click)="this.addPerspective()"
        >Add a perspective</button>
      </div>
    </div>
    <div *ngIf="skipIntro">
      tree
    </div>
  </mat-drawer-content>
</mat-drawer-container>