import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
// import { MatDialog } from '@angular/material/dialog';
// import { MatSnackBar } from '@angular/material/snack-bar';
// import { HttpErrorResponse } from '@angular/common/http';
// import { AbstractRule, RuleSet, Rule, UseCase, Factbase } from '@smartobjx/smart.objx.models';
// import { ViewControllerService } from '../core-services/view/ViewControllerService';
// import { RulesService } from '@smartobjx/smart.connectors';
// import { HttpService } from '../core-services/http/http.service';
// // import { RuleDialogComponent } from '../rule-dialog/rule-dialog.component';
// import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';
// import { UseCaseViewComponent } from '../use-case-view/use-case-view.component';
// import { Title } from '@angular/platform-browser';
// import { trigger, transition, style, animate, state } from '@angular/animations'
// import { CustomValidator } from '../shared/validation'
  
@Component( {
    selector: 'structures-adm',
    templateUrl: './structures-adm.component.html',
    styleUrls: [ './structures-adm.component.scss' ],
    // animations: [
    //   trigger(
    //       'card-movement', [
    //         transition(':enter', [
    //             style({transform: 'translateX(-10%)', opacity: 0}),
    //             animate('100ms', style({transform: 'translateX(0)', 'opacity': 1}))
    //         ]),
    //         transition(':leave', [
    //             style({transform: 'translateX(0)', 'opacity': 1}),
    //             animate('100ms', style({transform: 'translateX(-10%)', 'opacity': 0}))
    //         ]),
    //     ]
    //   )
    // ]
} )
export class StructuresComponent // implements OnInit, OnDestroy
{
  addPerspective(){
    this.skipIntro = true;
  }
  skipIntro: boolean = false;
  private perspectives: string[] = ['perspective1', 'perspective2'];
  private perspectiveName: string = 'perspective1';
}