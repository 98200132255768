import { Injectable } from '@angular/core';
import { RulesService } from '@smartobjx/smart.connectors';
import { ViewControllerService, ViewControllerMember } from '../view/ViewControllerService';
import { MatSnackBar } from '@angular/material/snack-bar';
import AbstractRuleMediator from './abstract-rule.mediator';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthService } from '../authentication/auth.service';

export function factory() {
  return (
    _server: RulesService,
    _vc: ViewControllerService,
    _sb: MatSnackBar,
    _authService: AuthService,  

  ): Mediator => {
    return new Mediator( _server, _vc, _sb ,_authService);
  };
}

@Injectable({
    providedIn: 'root'
})
export default class Mediator extends AbstractRuleMediator {
  constructor(
    server: any,
    viewController: ViewControllerService,
    info: MatSnackBar,
    authService: AuthService,  

  ) {
    super(server, viewController, info,authService)
  }

  showAdvancedView( view: ViewControllerMember, expressionControl: UntypedFormControl, callback: () => void ) {
    const observable = new Observable(subscriber => {
        view.showAdvancedView( expressionControl.value, subscriber );
    });

    observable.subscribe({
        next(data: any) { // custom this part to separate every call (update and resolve)
          expressionControl.setValue( data );
          callback();
        },
        error: e => console.error(e)
    })
  }
}