<mat-card class="full-height mat-main-card" [class.mat-card-raised]="isActive">
    <mat-progress-bar mode="indeterminate" class="card-top" [class.hidden]="!isLoading"></mat-progress-bar>
    <mat-card-title class="overline">{{ 'versions' | uppercase }}</mat-card-title>
    <mat-icon class="close" (click)="view.close()">close</mat-icon>
    <ng-container *ngIf="!isLoading">
        <mat-card-content>
            <version-caption
                [selectedDate]="selectedDate"
                [initialDate]="initialDate"
                [versionDates]="versionDates"
                (onChange)="onChangeSelectedDate( $event )"
                [firstVersionsDate]="firstVersionsDate"
                [isNotInitialDate]="isNotInitialDate">
            </version-caption>
                
            <div class="h5">{{ model.Name }}</div>

            <div class="body-2">{{ model.Description }}</div>

            <div class="body-2" style="margin-top: 10px;">Start date:</div>
            <div class="h6 value">{{ startDateAsString | date: 'MM/dd/yyyy \'at\' HH:mm' }}</div>

            <ng-container *ngIf="viewType == 'rule'">
                <div class="body-2" style="margin-top: 10px;">Condition:</div>
                <div class="h6 value">{{ model.ExpressionText }}</div>
            </ng-container>

            <ng-container *ngIf="viewType == 'calculationrule'">
                <div class="body-2" style="margin-top: 10px;">Condition:</div>
                <div class="h6 value">{{ model.Rule.ExpressionText }}</div>
                <div class="body-2" style="margin-top: 10px;">Algorithm name:</div>
                <div class="h6 value">{{ model.Algorithm.Name }}</div>
                <div class="body-2">Calculation:</div>
                <div class="h6 value">{{ model.Algorithm.ExpressionText }}</div>
            </ng-container>

            <ng-container *ngIf="viewType == 'callbackrule'">
                <div class="body-2" style="margin-top: 10px;">Condition:</div>
                <div class="h6 value">{{ model.Rule.ExpressionText }}</div>
                <div class="body-2">URL:</div>
                <div class="h6 value">{{ model.URL }}</div>
            </ng-container>

            <div class="body-2" style="margin-top: 20px;">Status:</div>
            <div class="h6" [class.disabled]="model.Disabled" [class.enabled]="!model.Disabled">{{ (model.Disabled ? 'Disabled' : 'Enabled') }}</div>

            <ng-container *ngIf="viewType == 'ruleset'">
                <mat-card-title class="rules-title" style="margin-top: 20px;">
                    <span>Rules</span>
                </mat-card-title>
                
                <span>Conjunctive:&nbsp;</span><span style="font-weight: bold">{{ conjunctiveType }}</span><span> | Short Circuit:&nbsp;</span><span style="font-weight: bold">{{ shortCircuit }}</span>

                <div *ngIf="model.PrimRules && model.PrimRules.length == 0" class="h5 no-children">No rules defined</div>

                <card-list [(model)]="model.PrimRules"
                    [showType]="true"
                    [showEdit]="false"
                    [showDelete]="false"
                    [hideOptions]="true"
                    [canHover]="false"
                    [showVersionOn]="this.showVersionOn()"
                ></card-list>
            </ng-container>
        </mat-card-content>

        <!-- footer -->
        <mat-card-actions *ngIf="!isLoading" fxFlexAlign="end" align="end">
            <mat-divider></mat-divider>
            <button mat-raised-button
                color="primary"
                class="toolbar-bottom-button"
                (click)="debug()"
            >Test</button>
            <button mat-raised-button
                color="primary"
                class="toolbar-bottom-button"
                (click)="edit()"
            >Edit</button>
        </mat-card-actions>
    </ng-container>
</mat-card>