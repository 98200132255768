import { Component, OnInit, forwardRef } from '@angular/core';
import { MarketService, MarketServiceConfiguration } from '@smartobjx/smart.connectors';
import { Subscription } from '@smartobjx/smart.objx.models';
import { AuthService } from '../core-services/authentication/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import Mediator from '../core-services/mediator/login-portal.mediator';
import { environment } from '../../environments/environment';
//import { BroadcastService, MsalService } from '@azure/msal-angular';
import { CryptoUtils, Logger } from 'msal';
import { MatDialog } from '@angular/material/dialog';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';
let serviceConfig: MarketServiceConfiguration = new MarketServiceConfiguration({
  basePath: 'http://localhost/smart.Market.Service'
});

@Component({
  selector: 'app-login-portal-demo',
  templateUrl: './login-portal-demo.component.html',
  styleUrls: ['./login-portal-demo.component.scss']
})
export class LoginPortalDemoComponent implements OnInit {
  errors
  pov: any;
  _token: string;
  keep: boolean = false;
  selectedSubscription: any;
  dataOwners: any;
  showPerspectives = false
  demoSubscriptions: Subscription[];
  isIframe = false;
  loggedIn = false;
  perspectives: any[];
  selectedPerspective: any;
  showOwner: boolean=false;
  constructor(
    private authService: AuthService,
    private mediator: Mediator,
    private route: ActivatedRoute,
    //   private authADService: MsalService,
    //  private broadcastService: BroadcastService,
    public _dialog: MatDialog,

    private _router: Router) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        if (!_.isNil(params) && !_.isNil(params.token)) {
          this._router.navigate(
            ['/registerSubscription'],
            { queryParams: { offerMarket: params.token } }
          );
          //  this.mediator.ActiveMarketPlaceOffer(params.token).subscribe(token => {
          //    console.log(token)
          //  })
        }
      })
      this.isLoadingTokens = true
    this.mediator.GetTokensforSmartrules("BF3FAE18-BECE-47EB-8FFA-3AA18C6DF849", "BF3FAE18-BECE-47EB-8FFA-3AA18C6DF849")
      .subscribe((subs: any) => {
        this.demoSubscriptions = subs;
        this.isLoadingTokens = false;
      })

  }
  perspectiveSelected() {
    this.isLoading = true
    this.getNestedClients(this.selectedPerspective.OID);
  }

  changeShowPerspectives() {
    if (!_.isNil(this.selectedSubscription) && this.selectedSubscription.AuthTokens.length > 0) {
      this.tokenSelected()
    }
  }

  tokenSelected() {
    this.token = this.selectedSubscription.AuthTokens[0].OID
    this.isLoading = true
 

    this.mediator.GetPerspectives(this.selectedSubscription.AuthTokens[0].OID, this.selectedSubscription.OID).subscribe((perspectives) => {
      this.showPerspectives = true
      this.perspectives = _.map(perspectives, (x: any) => { return { Name: x.Name, OID: x.OID } }).reduce((accumulator, current) => {
        if (!accumulator.find((item) => item.OID === current.OID)) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
      this.isLoading = false
    })

  }

  private getNestedClients(OID) {
    this.mediator.GetNestedClients("BF3FAE18-BECE-47EB-8FFA-3AA18C6DF849", this.selectedSubscription.AuthTokens[0].OID, OID)
      .subscribe(orgs => {
        this.showOwner = true
        this.isLoading = false; this.dataOwners = orgs;
      }, err => {
        if (err.status == 404) {
        }
      });
  }
  
  get token(): string {
    return this._token;
  }

  set token(value: string) {
    this._token = value;
    // this.isLoading = true;
    this.disablePOVs = true;
    this.hasErrors = false;
    this.organizations = [];
  }

  setDefaultToken(event) {
    // this.token = 'fd8efd80-f215-4c73-95f2-23841e98acbd';
    this.token = '5f87bf0e89a8427aaa5a8f4c01ffd777';
  }

  login(event) {
    if (this.token != null && this.pov != null) {
      this.hasErrors = false;
      this.authService.setTokenId(this.token);
      this.authService.setPOV(this.pov.OID.toString());

      this.authService.userName = null;
      this.authService.ownerName = null
      this.isLoading = true;
      this.mediator.checkLoginAndGetName(this.pov.OID.toString(), this.token, _.isNil(this.selectedPerspective) ? this.pov.OwnerID.toString() : this.selectedPerspective.OID)
        .subscribe({
          next: (sub: any) => {
            this.authService.userName = sub.name;
            this.authService.ownerName = this.pov.Name
            this.authService.PerspectiveName = this.selectedPerspective.Name
          },
          error: e => {
            console.error(e);
            this.isLoading = false;
            this.hasErrors = true;
          },
          complete: () => {
            this.isLoading = false;
            localStorage.setItem('perspectiveSelected', _.isNil(this.selectedPerspective) ? this.pov.OwnerID.toString() : this.selectedPerspective.OID);

            if (this.keep) {
              localStorage.setItem('authToken', this.token);
              localStorage.setItem('authPov', this.pov.OID.toString());
            }
            this._router.navigate(['asd']);
          }
        });
    }
  }

  logins: any[] = environment.logins;
  organizations: any[] = [];
  _test_organizations: any[] = [
    { id: '45ef2936-160e-47dc-bd17-3dc0060acec9', name: 'SaaS publisher' },
    { id: '25ef2936-160e-47dc-bd17-3dc0060acec9', name: 'Client' },
    { id: '05ef2936-160e-47dc-bd17-3dc0060acec9', name: 'Customer' }
  ]
  isLoading: boolean = false;
  isLoadingTokens: boolean = true;
  disablePOVs: boolean = true;
  hasErrors: boolean = false;
  errorMessage: string = 'Error: check the data and try again.';
  tokens: any = [];
}