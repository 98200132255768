import { Injectable } from '@angular/core';
import { ViewControllerMember, ViewControllerService } from '../view/ViewControllerService';
import { Observable } from 'rxjs';
import { Factbase, AbstractRule } from '@smartobjx/smart.objx.models';
import { tap } from 'rxjs/operators';

import { RulesService } from '@smartobjx/smart.connectors';

export function factory() {
 return (_server: RulesService, viewController: ViewControllerService): Mediator => {
  return new Mediator( _server, viewController );
 };
}

@Injectable({
  providedIn: 'root'
})
export default class Mediator {
  constructor( private server: RulesService,
    private viewController: ViewControllerService ) {}

  private regExQuote = /"|“|”/g;
  
  debugRule( rule: AbstractRule, ifb: Factbase, runAll: boolean, ignoreCall: boolean ) {
    this.viewController.clearBy(rule);

    let view = this.viewController.showDebuggerAdvanced(); 
    if ( runAll ){ // run
      this.runAll( view, rule.OID, ifb, ignoreCall ).toPromise();
    } else { // steps
      this.profile( view, rule.OID, ifb, ignoreCall ).toPromise();
    }

    this.viewController.moveViewToEnd();
  }
  debugRuleOn( rule: AbstractRule, ifb: Factbase, runAll: boolean, selectedDate : Date, ignoreCall: boolean ) {
    this.viewController.clearBy(rule);

    let view = this.viewController.showDebuggerAdvanced(); 
    if (runAll){ // run
      this.runAllOn( view, rule.OID, selectedDate, ifb, ignoreCall ).toPromise();
    } else { // steps
      this.profileOn( view, rule.OID, selectedDate, ifb, ignoreCall ).toPromise();
    }

    this.viewController.moveViewToEnd();
  }

  runAll( view: ViewControllerMember, ruleID: string, ifb: Factbase, ignoreCall: boolean ): Observable<Factbase>{
   view.Loading = true;
   return this.server.debug( ruleID, ignoreCall ,null,ifb  )
    .pipe( tap(
     ( fb: Factbase ) => {
      view.replaceData({ Factbase: fb, InitialFactbase: ifb });
      view.Loading = false;
     },
     e => {
      view.replaceData({ ErrorMessage: e.message, ErrorDetails: e.error });
      view.Loading = false;
     }
    )
   );
  }
  runAllOn( view: ViewControllerMember, ruleID: string, date: Date, ifb: Factbase, ignoreCall: boolean ): Observable<Factbase>{
   view.Loading = true;
   return this.server.debugOn( ruleID, date,ignoreCall,null, ifb )
    .pipe( tap(
     ( fb: Factbase ) => {
      view.replaceData({ Factbase: fb, InitialFactbase: ifb });
      view.Loading = false;
     },
     e => { 
      view.replaceData({ ErrorMessage: e.message, ErrorDetails: e.error });
      view.Loading = false;
     }
    )
   );
  }

  profile( view: ViewControllerMember, ruleID: string, ifb: Factbase, ignoreCall: boolean ): Observable<any>{
   view.Loading = true;
   return this.server.profile( ruleID,ignoreCall,null, ifb,  )
    .pipe( tap(
     ( p: any ) => {
      view.replaceData({ Results: p, InitialFactbase: ifb });
      view.Loading = false;
     },
     e => { 
      view.replaceData({ ErrorMessage: e.message, ErrorDetails: e.error });
      view.Loading = false;
     }
    )
   );
  }
  
  profileOn( view: ViewControllerMember, ruleID: string, date: Date, ifb: Factbase, ignoreCall: boolean ): Observable<any>{
   view.Loading = true;
   return this.server.profileOn( ruleID, date,ignoreCall,null, ifb  )
    .pipe( tap(
     ( p: any ) => {
      view.replaceData({ Results: p, InitialFactbase: ifb });
      view.Loading = false;
     },
     e => { 
      view.replaceData({ ErrorMessage: e.message, ErrorDetails: e.error });
      view.Loading = false;
     }
    )
   );
  }

  findRuleVariablesWithId( modelID: string, date: Date ): Observable<any>{
   return this.server.findRuleVariablesWithId( modelID );
  }

  // file
  saveFactbaseAsJSON( factbase: Factbase, fileName: string ){
    let list = [];
    factbase.Facts.forEach( (fact: any) => list.push({ Name: fact.Name, Value: fact.Value }) );
    let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(list));
    let tempLink = document.createElement('a');
    tempLink.setAttribute("href", dataStr);
    tempLink.setAttribute("download", fileName + ".json");
    document.body.appendChild(tempLink); // required for firefox
    tempLink.click();
    tempLink.remove();
  }

  mergeDataFromFileToFactbase( list: any[], factbase: any ){
    list.map(o => {
      let facts = factbase.Facts.filter(f => f.Name === o.Name);
      if(facts.length){
        let fact = facts[0];
        fact.Value = this.convertByFactType(fact, o.Value);
      }
    });
  }

  readJSON( data: any ): any[]{
    return JSON.parse( data );
  }

  readCSV( data: any, delimiter: string = ','): any[]{
    let re = new RegExp(
      "(?<="+ delimiter + "|^)\"(.*?)\"|[^"+ delimiter + "]+|\n(?="+ delimiter +")",
      "gi"
    );
    let list = [];
    data.replace(/\n/g, '').split('\r').forEach( ( line: string ) => {
      const name = re.exec( line )[0]; 
      const value = re.exec( line )[0];
      re.lastIndex = 0;
      list.push({ Name: name.replace(this.regExQuote, ''), Value: value.replace(this.regExQuote, '') }); 
    });
    return list;
  }

  convertByFactType(fact: any, value: string){
    if (this.factIsNumber(fact)) {
      return parseFloat(value);
    } else if (this.factIsString(fact)) {
      return value;
    } else if (this.factIsBoolean(fact)) {
      return value === "true" || value === "1";
    } else if (this.factIsDate(fact)) {
      return new Date(value);
    }
  }

  // tools
  
  factIsNumber(fact: any) {
    return this.is(fact, 'NumericConstant');
  }
  
  factIsListNumber(fact: any) {
   return this.is(fact, 'NumericListConstant');
  }

  factIsString(fact: any) {
    return this.is(fact, 'StringConstant');
  }

  factIsBoolean(fact: any) {
    return this.is(fact, 'PropositionConstant');
  }

  factIsDate(fact: any) {
   return this.is(fact, 'DateConstant');
  }
  
  private is(fact: any, name: string){
    return fact.$type.includes('smart.Objx.Rules.' + name);
  }

  prepareFile( el: any, error: (m: string) => void ){
    return new Observable(subscriber => {
      if(el.files.length){
        const file = el.files[0];
        switch (file.type){
          case 'application/json':
          case 'application/vnd.ms-excel':
          case 'text/csv':
            this.readFromFile(file, subscriber);
            break;
          default:
            subscriber.error('Invalid file format.');
            subscriber.complete();
        }
      } else {
        subscriber.error('File not loaded.');
        subscriber.complete();
      }
    });
  }
  
  readFromFile( file: any, subscriber: any ){
    let reader = new FileReader();
    reader.addEventListener("load", async (e: any) => {
      switch (file.type) {
        case 'application/json':
          try{
            const jsonData = this.readJSON(reader.result);
            subscriber.next({ file, parsed: jsonData});
            subscriber.complete();
          }
          catch (e) {
            onError(e);
          }
          break;
        case 'application/vnd.ms-excel':
        case 'text/csv':
          try{
            const csvData = this.readCSV(reader.result);
            subscriber.next({ file, parsed: csvData});
            subscriber.complete();
          }
          catch (e) {
            onError(e);
          }
          break;
        default:
          console.warn('unknown type:', file.type);
          subscriber.error('Unknown type.');
          subscriber.complete();
      }
    });
    
    reader.readAsText(file);

    const onError = (e: any) => {
      subscriber.error('The selected file was unable to read. Please review the file and try again.');
      subscriber.complete();
    };
  }
}