import { Component, Inject, OnInit, Input, ViewChild } from '@angular/core';
import { AbstractRule, Factbase } from '@smartobjx/smart.objx.models';
// import { RulesService } from '@smartobjx/smart.connectors';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FactbaseDisplayComponent } from '../factbase-display/factbase-display.component';

@Component({
  selector: 'rule-debugger-stepper',
  templateUrl: './rule-debugger-stepper.component.html',
  styleUrls: ['./rule-debugger-stepper.component.scss']
})
export class RuleDebuggerStepperComponent implements OnInit {
    @Input() rule: AbstractRule;
    @Input() initialFactbase: Factbase;
    @ViewChild(FactbaseDisplayComponent, {static: false}) factbaseDisplay: FactbaseDisplayComponent;
    currentFactbase: Factbase = new Factbase();
    finalFactbase: Factbase;
    stepCount: number=0;

    // #region Construction and Initialization
    constructor(
        // private service: RulesService,
        public dialogRef: MatDialogRef<RuleDebuggerStepperComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.finalFactbase = data.finalFactbase;
        this.initialFactbase = data.initialFactbase;
        // if(data.PrimRuleElements && data.PrimRuleElements.length){
        //     this.ruleText = this.createExpression(data.PrimRuleElements);
        // }
    }

    // constructor(
    //     public dialogRef: MatDialogRef<RuleDialogComponent>,
    //     @Inject(MAT_DIALOG_DATA) public data: any,
    //     // @Inject(MAT_DIALOG_DATA) public data: Rule,
    //     private server: RulesService ) {
    //     if(data.PrimRuleElements && data.PrimRuleElements.length){
    //         this.ruleText = this.createExpression(data.PrimRuleElements);
    //     }
    // }

    onNoClick(): void {
        this.dialogRef.close();
    }

    // #endregion
    ngOnInit() {
    //     this.service.debug(this.rule.OID, this.initialFactbase)
    //         .subscribe(fb => {
    //             this.finalFactbase = fb;
        setTimeout(() => {
            this.refresh();
        }, 100)
    //             console.log(fb);
    //         });
    }

    stepForwards() {
        //console.log(this.stepcount)
        if (this.stepCount < this.finalFactbase.Facts.length - this.initialFactbase.Facts.length) {
            this.stepCount++;
        }
        this.refresh();
    }

    stepBackwards() {
        if (this.stepCount > 0) {
            this.stepCount--;
        }
        this.refresh();
    }

    private refresh() {

        this.currentFactbase.Facts = this.finalFactbase.Facts.slice(0, this.initialFactbase.Facts.length + this.stepCount);
        this.factbaseDisplay.refresh();
    }
}


