import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import Mediator from '../core-services/mediator/rule-set-editor.mediator';
import { AbstractRule, RuleSet, AndConjunctive, OrConjunctive } from '@smartobjx/smart.objx.models';
import { ViewControllerService, ViewControllerMember } from '../core-services/view/ViewControllerService';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';
import { CustomValidator } from '../shared/validation';
import { Tools } from '../shared/Tools';
import { AuthService } from '../core-services/authentication/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'rule-set-editor',
    templateUrl: './rule-set-editor.component.html',
    styleUrls: ['./rule-set-editor.component.scss'],
})
export class RuleSetEditorComponent implements OnInit, OnDestroy {
    updateRefresh: any;
    onDestroy$: Subject<boolean> = new Subject();

    // #region Event Handlers
    SaveParents(version) {

        let model = this.mergeFormToModel(false);
        this.model.Version = version
        this.view.Model = this.model;

        let parentComponent = this.viewController.getParentComponentForOid(model);

        if (!_.isNil(parentComponent)) {
            this.onSaveModel(true,parentComponent)
           
        }

    }
    // #region public
    onSaveModel(UpdateVersion?,parentComponent?) {
        if (!UpdateVersion && this.isUntouched) return;
        const date = this.i_startDate;
        if (this.checkDateValues(date) || UpdateVersion) {
            let model = this.mergeFormToModel(UpdateVersion);
            const resetForm = () => {
                this.form.reset();
                this.disableOrEnableStack = [];
                if(parentComponent){
                //   parentComponent.SaveParentsList(UpdateVersion)
                }
            };

            // Due to call response time issues, only children update when saving
            if (this.disableOrEnableStack.length) {
                this.mediator.saveRuleWithDisabledOrEnabledChildren(
                    this.model,
                    this.view,
                    model,
                    date as Date,
                    this.disableOrEnableStack,
                    resetForm
                );
            } else {
                if (this.newUseCase)
                    this.mediator.createUseCaseFromRuleSet(this.view, model, resetForm);
                else {
                    this.mediator.saveRule(
                        this.model,
                        model,
                        this.view,
                        null,
                        resetForm
                    );
                }
            }
        } else {
            this.openDialog(this.startDateMinVerificationDialog);
        }
    }

    openDialog(el: any, options: any = null) {
        return this._dialog.open(el, options);
    }
    checkStartDateBefore(fn: (startDate: Date) => void) {
        const startDate = this.i_startDate;
        if (!startDate) {
            this.fireStartDateError();
            return;
        }

        const now = new Date();
        const version = startDate > now ? startDate : now;

        fn(version);
    }
    // #region from view
    appendRule() {
        this.checkStartDateBefore(startDate => {
            this.selectedIndex = -1;
            this.mediator.newRule(this.view, this.model, startDate, this);
        });
    }

    appendRuleSet() {
        this.checkStartDateBefore(startDate => {
            this.selectedIndex = -1;
            this.mediator.newRuleSet(this.view, this.model, startDate, this);
        });
    }

    appendActivityRule() {
        this.checkStartDateBefore(startDate => {
            this.selectedIndex = -1;
            this.mediator.newCallbackRule(this.view, this.model, startDate, this);
        });
    }

    appendCalculationRule() {
        this.checkStartDateBefore(startDate => {
            this.selectedIndex = -1;
            this.mediator.newCalculationRule(this.view, this.model, startDate, this);
        });
    }

    fireStartDateError() {
        this._info.open('Start Date must be valid before this action', '', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'end',
            panelClass: 'info-warn'
        });
    }
    append(rule: AbstractRule) {
        const startDate = this.i_startDate;
        if (!startDate) {
            this.fireStartDateError();
            return;
        }
        const now = new Date();
        this.mediator.editRule(
            rule,
            this.model,
            this.versionDateData,
            () => this.selectedIndex = -1,
            startDate > now ? startDate : now,
            this
        );
        this.updateSelected(rule);
    }

    // prim rules - children
    onDebug(rule: AbstractRule) {
        this.mediator.debugRule(
            rule,
            this.model,
            this.versionDateData,
            () => this.selectedIndex = -1,
            this
        );
        this.updateSelected(rule);
    }

    onVersions(rule: AbstractRule, date: Date, versionDates: any[]) {
        this.mediator.showRuleVersions(
            rule,
            this.model,
            date,
            versionDates,
            () => this.selectedIndex = -1
        );
        this.updateSelected(rule);
    }

    onDisableOrEnable(rule: AbstractRule) {
        this.updateSelected(rule);
        let disable = !rule.Disabled;
        this.openDialog(SimpleDialogComponent, {
            panelClass: 'smart-objx',
            autoFocus: false,
            data: {
                title: 'Attention',
                titleClass: 'warning',
                matIcon: 'warning_amber',
                button1Text: `Yes, ${(disable ? 'disable' : 'enable')} it`,
                content: `Do you really want to ${(disable ? 'disable' : 'enable')} the `
                    + this.getType(rule)
                    + ` "<b>${rule.Name}</b>" ?`
            }
        }).afterClosed().toPromise()
            .then(action => {
                (document.activeElement as any).blur(); // fix force blur on x
                this.selectedIndex = -1;
                if (action) {
                    this.viewController.clearBy(this.model);
                    this.stackToDisableOrEnable({ rule: rule, disable: disable })
                }
            });
    }
    onDelete(rule: AbstractRule) {
        this.updateSelected(rule);
        this.openDialog(SimpleDialogComponent, {
            panelClass: 'smart-objx',
            autoFocus: false,
            data: {
                title: 'Are you sure?',
                titleClass: 'error',
                matIcon: 'delete_forever',
                content: 'Do you really want to want to delete the '
                    + this.getType(rule)
                    + ` "<b>${rule.Name}</b>" ?`
                    + '\r\nThis process cannot be undone.'
            }
        }).afterClosed().toPromise()
            .then(action => {
                (document.activeElement as any).blur(); // fix force blur on x
                if (action) {
                    let primRules = (this.model as any).PrimRules,
                        selectedIndex = primRules.indexOf(rule);

                    primRules.splice(selectedIndex, 1);
                    this.viewController.clearBy(this.model);
                }
            });
    }
    onChanges() {
        (this.form as any).pristine = false;
    }
    checkDateValues(date: Date) {
        return date >= this.minDate;
    }

    toggleAdvancedOptions() {
        this.advOptEnabled = !this.advOptEnabled;
    }

    simpleDate(date: any) {
        return Tools.dateToString(date);
    }
    markStartDateAsDirty() {
        this.startDateForceInvalid = true;
    }
    fixStartDateAndSave() {
        this.i_startDate = this.priorToParent ? this.minDate : new Date();
        this.onSaveModel();
    }
    fixMinDate(date: Date) {
        const diff = this.minDate.getTime() - date.getTime();
        if (diff > 0 && diff < 100) {
            this.minDate = date;
        }
    }
    // #endregion

    // #endregion

    // #region private
    private checkStartDate() {
        const { startDate } = this;
        if (startDate) {
            this.minDate = startDate;
        }
    }
    private updateSelected(rule: AbstractRule) {
        this.selectedIndex = (this.model as any).PrimRules.indexOf(rule)
    }

    private getType(record: any) {
        let type = (record as any).$type;
        if (!type) return 'undefined';
        if (type.includes('Rules.RuleSet,')) return 'ruleset';
        if (type.includes('Rules.Rule,')) return 'rule';
    }

    private getControl(name: string): UntypedFormControl {
        return this.form.get(name) as UntypedFormControl;
    }

    private mergeFormToModel(UpdateVersion) {
        let model = Object.assign({}, this.model as any);
        model.Name = this.form.value.name;
        model.Description = this.form.value.description;
        model.Version = UpdateVersion ? this.model.Version : this.i_startDate;
        model.NotReplaceable = this.form.value.notReplaceable;
        // removed the "set" method, that is never called, and added the process here
        var newType = this.form.value.conjunctiveType === "And" ? new AndConjunctive() : new OrConjunctive();
        newType.ShortCircuit = this.form.value.shortCircuit;
        model.Conjunctive = newType;
        return model;
    }

    private stackToDisableOrEnable(data: any) {
        let filter = this.disableOrEnableStack.filter(o => o.rule === data.rule);
        if (filter.length) {
            const i = this.disableOrEnableStack.indexOf(filter[0]);
            this.disableOrEnableStack.splice(i, 1);
        } else {
            this.disableOrEnableStack.push(data);
        }
    }
    private showWarning() {
        this.openDialog(SimpleDialogComponent, {
            panelClass: 'smart-objx',
            autoFocus: false,
            data: {
                title: 'Attention',
                titleClass: 'warning',
                matIcon: 'warning_amber',
                button1Text: 'Yes, close the panel',
                button2Text: 'No, keep the panel open',
                button1Color: 'primary',
                content: 'Are you sure you want to close this panel?'
                    + '\r\nThe changes won´t be saved.'
            }
        }).afterClosed().toPromise()
            .then(action => {
                if (action) {
                    this.view.close(true);
                }
            });
    }
    private registerEvents() {
        this.view.Events.onClose$ = () => {
            if (!this.isUntouched) {
                this.showWarning();
            }
            return this.isUntouched;
        };
    }
    // #endregion
    // #endregion

    // #region Construction & Finalization
    constructor(
        private mediator: Mediator,
        public _dialog: MatDialog,
        private viewController: ViewControllerService,
        private fb: UntypedFormBuilder,
        private _authService: AuthService,
        private _info: MatSnackBar
    ) {
        // init minDate
        this.minDate = new Date();
        this.advOptEnabled = false;
        this.pov = this._authService.getPOV();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.unsubscribe();
    }

    ngOnInit() {

        this.updateRefresh = this.viewController.updateRootForReplace.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            console.log(this.model)
        })

        this.checkStartDate();
        this.registerEvents();
        let model = this.model;
        if (typeof model.Version === 'string' && !!model.Version) {
            model.Version = CustomValidator.ensureDate(model.Version);
        }
        // only works on edit a RuleSet that is not "UseCase". Instead, it will be replaced at ngOnChanges
        this.form = this.fb.group({
            name: [this.replaceDefaultName(model), Validators.required],
            description: [this.replaceDefaultDescription(model), Validators.nullValidator],
            shortCircuit: [(model.Conjunctive as any).ShortCircuit, [Validators.required]],
            conjunctiveType: [this.conjunctiveType, [Validators.required]],
            notReplaceable: [model.NotReplaceable || false, [Validators.required]]
        })
        this.i_startDate = new Date(model.Version.getTime());
        this.initialDate = model.Version;
        this.fixMinDate(model.Version);


    }
    ngOnChanges(changes: any): void {
        this.checkStartDate();
        if (changes.model && !changes.model.firstChange) {
            let model = changes.model.currentValue;

            if (typeof model.Version === 'string' && !!model.Version) {
                model.Version = CustomValidator.ensureDate(model.Version);
            }

            // is called after retrieving the RuleSet from "UseCase"
            let conjunctiveAsAny = model.Conjunctive as any;
            this.form.patchValue({
                name: model.Name,
                description:  model.Description,
                shortCircuit: conjunctiveAsAny.ShortCircuit,
                conjunctiveType: conjunctiveAsAny.$type.includes('smart.Objx.Rules.AndConjunctive') ? "And" : "Or",
                notReplaceable: model.NotReplaceable
            });
            this.i_startDate = new Date(model.Version.getTime());
            this.initialDate = model.Version;
        }

        // focus name
        if (changes.isLoading && changes.isLoading.currentValue == false && !this.disableFocused) {
            setTimeout(() => {
                this.nameInputRef.nativeElement.focus();
                // this.nameInputRef.nativeElement.select();
                this.disableFocused = true;
            }, 0);
        }
    }

    replaceDefaultDescription(model: RuleSet): string {
        return Tools.isEmptyGuid(model.OID) && this.asUseCase ? "Describes what the use case element controls." : model.Description;
    }
    replaceDefaultName(model: RuleSet) {
        return Tools.isEmptyGuid(model.OID) && this.asUseCase ? "Unnamed use case" : model.Name;
    }

    // #endregion

    // #region Properties

    get model(): RuleSet {
        return this.i_Model;
    }

    get conjunctiveType(): string {
        return (this.model.Conjunctive as any).$type.includes('smart.Objx.Rules.AndConjunctive') ? "And" : "Or";
    }

    @Input()
    set model(newModel: RuleSet) {
        this.i_Model = newModel;
        this.selectedIndex = -1;
    }

    get selectedIndex(): number {
        return this.i_SelectedIndex;
    }

    set selectedIndex(clickedItemIndex: number) {
        this.i_SelectedIndex = clickedItemIndex;
    }

    get descriptionHint(): string {
        return this.asUseCase
            ? 'This text should be a description of the place with applications where the <b>Use Case</b> is used, such as "Save Employee" or "Process Payroll". These rules can be reused by any application with similar workflow requirements.'
            : 'This text should be a description of the actions the <b>Ruleset</b> performs. This text must help the users to undertand what the use case does.';
    }
    get formIsDisabled(): boolean {
        return !this.form || (this.form.pristine && this.disableOrEnableStack.length === 0) || !this.form.valid;
    }
    get priorToParent(): boolean {
        const now = new Date();
        return now < this.minDate;
    }

    // the parent date (will be used as minDate)
    get startDate(): Date {
        const { Data } = this.view;
        return Data ? Data.startDate : undefined;
    }
    minDate: Date;
    initialDate: Date; // the current version date
    advOptEnabled: boolean;

    get isNew(): boolean {
        return !!this.model && !this.model.OID;
    }
    private get isUntouched(): boolean {
        return this.form.pristine && this.disableOrEnableStack.length == 0;
    }

    private i_startDate: Date = new Date();
    startDateForceInvalid: boolean = false;
    // #endregion

    // #region Data Elements
    private i_Model: RuleSet;
    private i_SelectedIndex: number;
    private form: UntypedFormGroup;
    private disableOrEnableStack: any[] = [];
    private pov: string;

    @Input() view: ViewControllerMember;
    get isLoading(): boolean { return this.view.Loading; };
    get isActive(): boolean { return this.view.Active; };
    get asUseCase(): boolean { return this.view.asUseCase(); };
    get newUseCase(): boolean { return this.view.newUseCase(); };
    get versionDateData(): boolean { return this.view.Data && this.view.Data.versionDateData; };

    @ViewChild('nameInput', { static: false }) nameInputRef: any;
    private disableFocused: boolean = false;
    // formattedMessage: string;
    fixDateValue: string;
    @ViewChild('startDateMinVerification', { static: false }) startDateMinVerificationDialog: any;
    // #endregion

    // #region Event Emitters
    // #endregion
}