import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractRule } from '@smartobjx/smart.objx.models';
import Mediator from '../core-services/mediator/use-case-generator.mediator';
import { ViewControllerMember } from '../core-services/view/ViewControllerService';

import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component( {
  selector: 'use-case-generator',
  templateUrl: './use-case-generator.component.html',
  styleUrls: [ './use-case-generator.component.scss' ]
} )
export class UseCaseGeneratorComponent implements OnInit
{
  // #region Event Handlers
  checkExpression( name: string = 'expression' ) {
    let control = this.getControl( name );
    let expression = control.value;
    if( expression ) {
      control.setErrors( { 'loading': true } );
      const exp = `'${expression.replace(/\'/g, '\\\'')}'`;
      this.mediator.parse( exp )
        .subscribe( (parsed: any) => {
          control.setValue( parsed.ExpressionText );

          let elements = (parsed as any).PrimRuleElements;
          const errors = this.checkNameError( elements );
          control.setErrors( errors );
        }, (err)=> {
          console.error(err);
          control.setErrors( { 'error': true } );
        });
    } else {
      control.setErrors( { 'error': true } );
    }
  }

  onCreate()
  {
    if ( this.form.pristine ) return;
    // let model = this.mergeFormToModel();   
    const { value } = this.form;

    this.mediator.generateUseCase(
      value.usecaseName,
      value.amount,
      value.expression,
      value.algorithmExpression,
      this.view
    );
  }
  setInvalidExpression( e: any, name: string = 'expression' ){
    if( e.keyCode === 9 ) return;
    let control = this.getControl( name );
    if( control.hasError( 'error ') ) return;
    control.setErrors( { 'error': true } );
  }
  // #endregion
  
  // #region Private Methods
  
  private checkNameError( elements: any[] ){
    const { name } = this.form.value;
    return elements.filter(o => o.Name === name)
        .length ? { 'variableNameError': true } : null;
  }
  // #region FormProperties
  get expression() {
    return this.getControl('expression');
  }
  get algorithmExpression() {
    return this.getControl('algorithmExpression');
  }
  //#endregion

  private getControl(name: string): UntypedFormControl {
    return this.form.get(name) as UntypedFormControl;
  }

  // #endregion
  
  // #region Construction & Finalization
  constructor (
    private mediator: Mediator,
    public _dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private _info: MatSnackBar
  ) {
  }

  ngOnInit() {
    let model = {} as any;
    
    this.form = this.fb.group({
      usecaseName: [model.UsecaseName, Validators.required],
      amount: [model.Amount, Validators.required],
      expression: [model.Expression, Validators.required],
      algorithmExpression: [{ value: model.AlgorithmExpression, disabled: true }, Validators.required],
    });
	}

  // #endregion

  // #region Properties
  get model(): AbstractRule {
    return this.i_Model;
  }

  @Input()
  set model( newModel: AbstractRule ) {
    this.i_Model = newModel;
  }

  get formIsDisabled(): boolean {
    return !this.form || this.form.pristine || !this.form.valid;
  }

  private get isUntouched(): boolean {
    return this.form.pristine;
  }
  // #endregion

  // #region Data Elements
  private i_Model: AbstractRule;
  private form: UntypedFormGroup;
  get convertCheck(): boolean {
    return !this.form.controls.algorithmExpression.disabled;
  };
  set convertCheck( enable ) {
    if ( enable ){
      this.form.controls.algorithmExpression.enable();
    } else {
      this.form.controls.algorithmExpression.disable();
    }
  }

  Title: string = 'generate use case';
  selfLoading: boolean = false;
  
  checkExpressionErrorTip: string = 'The expression is not valid';

  @Input() view: ViewControllerMember;
  isLoading(): boolean { return this.view.Loading; };
  isActive(): boolean { return this.view.Active; };
  viewType(): string { return this.view.Type; };
  
  get canSave(){
    return !this.form.pristine;
  }

  @ViewChild('nameInput', {static: false}) nameInputRef: any;
  
  // #endregion

  // #region Event Emitters
  // #endregion
}