<mat-card class="full-height mat-main-card" [class.mat-card-raised]="isActive">
    <mat-progress-bar mode="indeterminate" class="card-top" [class.hidden]="!isLoading"></mat-progress-bar>
    <mat-card-title class="overline">{{ 'view ruleset' | uppercase }}</mat-card-title>
    <mat-icon class="close" (click)="view.close()">close</mat-icon>  
    
    <version-caption *ngIf="versionDateData"
        actionText="testing"
        [selectedDate]="versionDateData.date"
        [showTime]="versionDateData.showTime"
        [isNotInitialDate]="versionDateData.altClass"
    ></version-caption>

    <div class="caption replaceable">
        <ng-container>
            <div>This {{ (viewType == 'ruleset' ? 'ruleset' : 'rule')}} has been marked as <b>"Not Replaceable"</b> by it's parent.</div>
        </ng-container>    
    </div>
    <ng-container *ngIf="!isLoading">
        <mat-card-content>                

            <div class="h5">{{ model.Name }}</div>

            <div class="body-2">{{ model.Description }}</div>

            <div class="body-2" style="margin-top: 10px;">Start date:</div>
            <div class="h6 value">{{ startDateAsString | date: 'MM/dd/yyyy \'at\' HH:mm' }}</div>

            <ng-container *ngIf="viewType == 'rule'">
                <div class="body-2" style="margin-top: 10px;">Condition:</div>
                <div class="h6 value">{{ model.ExpressionText }}</div>
            </ng-container>

            <ng-container *ngIf="viewType == 'calculationrule'">
                <div class="body-2" style="margin-top: 10px;">Condition:</div>
                <div class="h6 value">{{ model.Rule.ExpressionText }}</div>
                <div class="body-2" style="margin-top: 10px;">Algorithm name:</div>
                <div class="h6 value">{{ model.Algorithm.Name }}</div>
                <div class="body-2">Calculation:</div>
                <div class="h6 value">{{ model.Algorithm.ExpressionText }}</div>
            </ng-container>
            
            <ng-container *ngIf="viewType == 'callbackrule'">
                <div class="body-2" style="margin-top: 10px;">Condition:</div>
                <div class="h6 value">{{ model.Rule.ExpressionText }}</div>
                <div class="body-2">URL:</div>
                <div class="h6 value">{{ model.URL }}</div>
            </ng-container>

            <div class="body-2" style="margin-top: 20px;">Status:</div>
            <div class="h6" [class.disabled]="model.Disabled" [class.enabled]="!model.Disabled">{{ (model.Disabled ? 'Disabled' : 'Enabled') }}</div>

            <ng-container *ngIf="viewType == 'ruleset'">
                <mat-card-title class="rules-title" style="margin-top: 20px;">
                    <span>Rules</span>
                </mat-card-title>
                
                <span>Conjunctive:&nbsp;</span><span style="font-weight: bold">{{ conjunctiveType }}</span><span> | Short Circuit:&nbsp;</span><span style="font-weight: bold">{{ shortCircuit }}</span>

                <div *ngIf="model.PrimRules && model.PrimRules.length == 0" class="h5 no-children">No rules defined</div>

                <card-list [(model)]="model.PrimRules"
                    (onEdit)="append( $event )"
                    (onDelete)="onDisableOrEnable( $event )"
                    (onDebug)="onDebug( $event )" showDebug="true"
                    (onVersions)="onVersions( $event.record, $event.date, $event.versionDates )"
                    [showType]="true"
                    [showVersions]="true"
                    [showDebug]="true"
                    [showDelete]="true"
                    [pov]="pov"
                ></card-list>
            </ng-container>
        </mat-card-content>      
    </ng-container>
</mat-card>