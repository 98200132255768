<mat-card class="full-height mat-main-card" [formGroup]="form">
    <mat-progress-bar mode="indeterminate" class="card-top" [class.hidden]="!isLoading"></mat-progress-bar>
    
    <mat-card-title class="overline">
        <button mat-icon-button color="primary" (click)="view.close()" matTooltip="close">
            <mat-icon>arrow_back_ios</mat-icon>
        </button>
        ADVANCED VIEW
    </mat-card-title>

    <mat-card-content class="rda-main-content" formArrayName="mainValidations">
        <span>Use the advanced options to write more complex rules.</span>

        <ng-container *ngFor="let control of mainValidations.controls; index as i" [formGroupName]="i">
            <mat-card class="expression-part">
                <div class="connector">
                    <div>{{(mainValidations.value[i].Connector || '').toLowerCase()}}</div>
                </div>
                <mat-form-field class="left-element" *ngIf="control.value.Operator != 'NOT'">
                    <input matInput formControlName="First" (change)="refresh()" (keyup)="refreshIf( $event )" />
                    <!-- <div *ngIf="firstInputHasError(i)" class="error-info">
                        {{ invalidAllLettersOrAllNumberMessage }}
                    </div> -->
                </mat-form-field>
                <mat-form-field class="operator">
                    <mat-select formControlName="Operator" (selectionChange)="refresh(); checkControl( control );" > <!-- [ngModelOptions]="{standalone: true}" temp -->
                        <mat-option *ngFor="let operator of operators" [value]="operator.value" [matTooltip]="operator.name">
                            {{operator.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="right-element">
                    <input matInput formControlName="Second" (change)="refresh()" (keyup)="refreshIf( $event )" />
                    <!-- <div *ngIf="secondInputHasError(i)" class="error-info">
                        {{ invalidAllLettersOrAllNumberMessage }}
                    </div> -->
                </mat-form-field>
                <div class="options">
                    <mat-icon class="options" color="primary" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{name: i}">add_box</mat-icon>
                    <mat-icon *ngIf="mainValidations.length > 1" class="options" color="primary" (click)="remove(i)">delete</mat-icon>
                </div>
            </mat-card>
        </ng-container>
    </mat-card-content>

    <mat-menu #menu="matMenu">
        <ng-template matMenuContent let-name="name">
            <button mat-menu-item (click)="add('AND', name)">and</button>
            <button mat-menu-item (click)="add('OR', name)">or</button>
        </ng-template>
    </mat-menu>
    <div *ngIf="finalExpression" class="result-view">
        <div class="title">Result</div>
        <div class="content">{{finalExpression}}</div>
    </div>
    <!-- footer -->
    <mat-card-actions fxFlexAlign="end" align="end">
        <button mat-button color="secondary" style="margin-top: 5px;"
            (click)="view.close()"
        >Close</button>
        <button mat-raised-button
            color="primary" 
            class="toolbar-bottom-button"
            [disabled]="form.invalid"
            (click)="accept()"
        >OK</button>
    </mat-card-actions>
</mat-card>