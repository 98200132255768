import { Component, OnInit, forwardRef } from '@angular/core';
import { MarketService, MarketServiceConfiguration } from '@smartobjx/smart.connectors';
import { Perspective, Subscription } from '@smartobjx/smart.objx.models';
import { AuthService } from '../core-services/authentication/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import Mediator from '../core-services/mediator/login-portal.mediator';
import { environment } from '../../environments/environment';
//import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Account, CryptoUtils, Logger } from 'msal';
let serviceConfig: MarketServiceConfiguration = new MarketServiceConfiguration({
  basePath: 'http://localhost/smart.Market.Service'
});

@Component({
  selector: 'app-login-portal',
  templateUrl: './login-portal.component.html',
  styleUrls: ['./login-portal.component.scss']
})
export class LoginPortalComponent implements OnInit {

  private pov: string;
  private _token: string;
  private keep: boolean;
  selectedSubscription: any;
  dataOwners: any;
  demoSubscriptions: Subscription[];
  isIframe = false;
  loggedIn = false;
  userlogged: Account;
  selectedPerspective: Perspective;
  constructor(
    private authService: AuthService,
    private mediator: Mediator,
    private route: ActivatedRoute,
  //  private authADService: MsalService,
 //   private broadcastService: BroadcastService,
    private _router: Router) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        if (!_.isNil(params) && !_.isNil(params.token)) {
          this._router.navigate(
            ['/registerSubscription'],
            { queryParams: { offerMarket: params.token } }
          );
          //  this.mediator.ActiveMarketPlaceOffer(params.token).subscribe(token => {
          //    console.log(token)
          //  })
        }
      })
    this.isIframe = window !== window.parent && !window.opener;

   // this.userlogged = this.authADService.getAccount()

    //this.mediator.GetTokensforSmartrules("BF3FAE18-BECE-47EB-8FFA-3AA18C6DF849", "BF3FAE18-BECE-47EB-8FFA-3AA18C6DF849")
    //  .subscribe((subs: any) => {
    //    this.demoSubscriptions = subs;
    //    this.isLoadingTokens = false;
    //    if (!_.isNil(this.userlogged)) {
    //      let userfound = this.demoSubscriptions.find(sub => sub.OID == this.userlogged.accountIdentifier)
    //      this.selectedSubscription = userfound
    //      this.tokenSelected()
    //      this.login(null)
    //    }
    //  })

   // this.mediator.GetMsalSubcriptions().subscribe((x) => console.log(x));

    console.log(this.userlogged)
    this.checkoutAccount();

   //this.broadcastService.subscribe('msal:loginSuccess', () => {
   //  this.checkoutAccount();
   //});

   //this.authADService.handleRedirectCallback((authError, response) => {
   //  if (authError) {
   //    console.error('Redirect Error: ', authError.errorMessage);
   //    return;
   //  }

   //  console.log('Redirect Success: ', response);
   //});

   //this.authADService.setLogger(new Logger((logLevel, message, piiEnabled) => {
   //  console.log('MSAL Logging: ', message);
   //}, {
   //  correlationId: CryptoUtils.createNewGuid(),
   //  piiLoggingEnabled: false
   //}));

   //this.subMsal()




  }
  private subMsal() {
   // this.broadcastService.getMSALSubject().subscribe({
   //   next: (msalSubject) => {
   //     console.log(msalSubject);
//
   //   }
   // }
   // );
  }

  checkoutAccount() {
  //  this.loggedIn = !!this.authADService.getAccount();
  }

  loginAD() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

   // if (isIE) {
   //   this.authADService.loginRedirect();
   // } else {
   //   this.authADService.loginRedirect();
   // }
  }

  logout() {
    this.authService.logOut();
    // this.authADService.logout();
  }
  get token(): string {
    return this._token;
  }

  set token(value: string) {
    this._token = value;
    // this.isLoading = true;
    this.disablePOVs = true;
    this.hasErrors = false;
    this.organizations = [];
    //  // call structures to get all organizations for smartRules perspective
    //  this.mediator.getAllOrganizationsFor(value)
    //    .subscribe({
    //      next: (perspectives: any) => {
    //        if (perspectives.Success == false) {
    //          console.error(perspectives.error);
    //          this.organizations = this._test_organizations;
    //          this.errorMessage = perspectives.error;
    //          this.hasErrors = true;
    //        } else {
    //          delete perspectives.$id;
    //          this.organizations = Object.keys(perspectives).map((k) => ({ id: k, name: perspectives[k] }));
    //          this.organizations = this.organizations.concat(this._test_organizations); // temp - adds old POVs
    //        }
    //        // console.log(perspectives);
    //      },
    //      error: e => {
    //        console.error(e);
    //        this.organizations = this._test_organizations;
    //        this.isLoading = false;
    //        this.disablePOVs = false;
    //
    //        // this.errorMessage = 'No "smartRules" perspective defined';
    //        this.hasErrors = true;
    //      },
    //      complete: () => {
    //        this.isLoading = false;
    //        this.disablePOVs = false;
    //      }
    //    });
  }

  setDefaultToken(event) {
    // this.token = 'fd8efd80-f215-4c73-95f2-23841e98acbd';
    this.token = '5f87bf0e89a8427aaa5a8f4c01ffd777';
  }
  tokenSelected() {
    this.isLoading = true;
    this.token = this.selectedSubscription.AuthTokens[0].OID
    this.pov = this.selectedSubscription.OID
    this.mediator.GetNestedClients("BF3FAE18-BECE-47EB-8FFA-3AA18C6DF849", this.selectedSubscription.AuthTokens[0].OID, this.selectedSubscription.OID)
      .subscribe(orgs => { this.dataOwners = orgs; this.isLoading = false }, err => {
        if (err.status == 404) {

        }
      })

  }
  login(event) {
    if (this.token != null && this.pov != null) {
      this.hasErrors = false;
      this.authService.setTokenId(this.token);
      this.authService.setPOV(this.pov.toString());

      this.authService.userName = null;
      this.authService.userName = null;
      this.authService.ownerName = null
      this.isLoading = true;
      this.mediator.checkLoginAndGetName(this.pov.toString(), this.token,{})
        .subscribe({
          next: (name: any) => {
            this.authService.userName = name;
 
          },
          error: e => {
            console.error(e);
            this.isLoading = false;
            this.hasErrors = true;
          },
          complete: () => {
            this.isLoading = false;
            localStorage.setItem('perspectiveSelected', _.isNil(this.selectedPerspective) ? this.pov.toString() : this.selectedPerspective.OID);

            if (this.keep) {
              localStorage.setItem('authToken', this.token);
              localStorage.setItem('authPov', this.pov.toString());
            }
            this._router.navigate(['asd']);
          }
        });
    }
  }

  logins: any[] = environment.logins;
  organizations: any[] = [];
  _test_organizations: any[] = [
    { id: '45ef2936-160e-47dc-bd17-3dc0060acec9', name: 'SaaS publisher' },
    { id: '25ef2936-160e-47dc-bd17-3dc0060acec9', name: 'Client' },
    { id: '05ef2936-160e-47dc-bd17-3dc0060acec9', name: 'Customer' }
  ]
  isLoading: boolean = false;
  isLoadingTokens: boolean = true;
  disablePOVs: boolean = true;
  hasErrors: boolean = false;
  errorMessage: string = 'Error: check the data and try again.';
  tokens: any = [];
}