import { Component, Input, Output, EventEmitter, Type, ViewChild, OnChanges } from '@angular/core';
import { AbstractRule, Factbase, NumericConstant, StringConstant, PropositionConstant, NumericListConstant, DateConstant } from '@smartobjx/smart.objx.models';
import { ViewControllerService, ViewControllerMember } from '../core-services/view/ViewControllerService';
import { FactbaseDisplayComponent } from '../factbase-display/factbase-display.component';
import Mediator from '../core-services/mediator/rule-debugger.mediator';
// import { RuleDebuggerStepperComponent } from '../rule-debugger-stepper/rule-debugger-stepper.component'; // do not remove for now
import { Tools } from '../shared/Tools';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'rule-debugger',
  templateUrl: './rule-debugger.component.html',
  styleUrls: ['./rule-debugger.component.scss']
})
export class RuleDebuggerComponent implements OnChanges {
  // #region Event Handlers
  run( all: boolean = true ) {
    if( this.versionDateData ){
      this.mediator.debugRuleOn( this.model, this.factbase, all, this.selectedDate, this.ignoreCall );
    } else {
      this.mediator.debugRule( this.model, this.factbase, all, this.ignoreCall );
    }
  }

  reset(){
    if(this.buttonDisabledCondition()) return;
    this.getVariables(false)
      .subscribe(data => {
        this.factbase = new Factbase();
        this.addVariables(data);
        this.selfLoading = false;
      })
  }

  getVariables(autoAdd: boolean = true){
    if(this.model.OID){
      this.selfLoading = true;      
      let call = this.mediator.findRuleVariablesWithId( this.model.OID, this.selectedDate );
      if(autoAdd)
        call.subscribe(data => {
          this.addVariables(data);
          this.selfLoading = false;
        });
      return call;
    }
  }

  addVariables(data: any){
    let facts = (data as Factbase).Facts;

    for (let i in facts){
      let fact = this.createByType(facts[i]);

      if( this.isDateTime( fact ) && fact.Value === null ){
        fact.Value = new Date();
        fact.Value.setHours(0,0,0,0);
      }

      this.factbase.Facts.push(fact);
    }
  }

  getTextByType(fact: any){
    if (this.mediator.factIsNumber(fact)) {
      return '<b>Numeric variables</b> only support numbers.';
    } else if (this.mediator.factIsString(fact)) {
      return '<b>String variables</b> support letters, numbers, spaces, commas and more.';
    } else if (this.mediator.factIsBoolean(fact)) {
      return '<b>Proposition variables</b> only support True or False.';
    } else if (this.mediator.factIsListNumber(fact)) {
      return '<b>Numeric list variables</b> only support numbers with comma as separator.';
    }
  }

  isListNumber(fact: any) {
    return this.mediator.factIsListNumber(fact);
  }

  isDateTime(fact: any) {
    return this.mediator.factIsDate(fact);
  }

  listNumberForLoop(fact: any) {
    return Array(fact.Value.length+1).fill(null);
  }
  
  trackByFn(index: any, item: any) {
    return index;
  }
  
  removeAt(fact: any, l: number){
    fact.Value.splice(l, 1);
  }
  
  buttonDisabledCondition(){
    return this.isLoading || !this.factbase || !this.factbase.Facts.length || this.viewController.query('any(debugger-advanced) and loading');
  }

  //#region Load variables
  @ViewChild('loadFileDialogContent', {static: false}) loadFileDialogContent: any; // to prepare the drag and drop utility
  private fileToLoad: any;
  private errorsToLoad: string;

  get isAdvancedUpload(): boolean {
    var div = document.createElement('div');
    return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
  }

  prepareDragAndDrop( el: any ){
    this.onEvents('drag dragstart dragend dragover dragenter dragleave drop', el, (e: any) => this.preventDefaultAndStopPropagation(e));
    this.onEvents('dragover dragenter', el, (e: any) => el.classList.add('is-dragover'));
    this.onEvents('dragleave dragend drop', el, (e: any) => el.classList.remove('is-dragover'));
    this.onEvents('drop', el, (e: any) => this.prepareFile( e.dataTransfer ));
  }
  onEvents(events: string, el: any, callback: (e: any) => void ){
    events.split(' ').forEach(en => 
      el.addEventListener(en, callback)
    );
  }
  preventDefaultAndStopPropagation( e: any ){
    e.preventDefault();
    e.stopPropagation();
  }
  prepareFile( el: any ){
    this.errorsToLoad = null;
    this.fileToLoad = null;
    this.mediator.prepareFile( el, m => this.errorsToLoad = m)
      .subscribe({
        next: (data: any) => {
          this.fileToLoad = data;
        },
        error: e => {
          this.errorsToLoad = e;
        }
      });
  }
  openDialog( el: any ) {
    if(this.buttonDisabledCondition()) return;
    this._dialog.open(el)
      .afterOpened().subscribe(() => {
        if(this.isAdvancedUpload){
          this.prepareDragAndDrop(this.loadFileDialogContent.nativeElement);
        }
      })
  }
  saveFile( el: any ){
    this.mediator.saveFactbaseAsJSON( this.factbase, el.value );
  }
  loadFile(){
    if(this.buttonDisabledCondition()) return;
    this.mediator.mergeDataFromFileToFactbase( this.fileToLoad.parsed, this.factbase );
    this.fileToLoad = null;
    this._info.open('File uploaded successfully', '', {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: 'info-primary'
    });
  }
  //#endregion

  // #endregion
  
  // #region Private Methods

  private createByType(fact: any) : PropositionConstant | StringConstant | NumericConstant | NumericListConstant | DateConstant {
    let type: Type<PropositionConstant | StringConstant | NumericConstant | NumericListConstant | DateConstant>;
    if (this.mediator.factIsNumber(fact)) {
      type = StringConstant;
    } else if (this.mediator.factIsString(fact)) {
      type = StringConstant;
    } else if (this.mediator.factIsBoolean(fact)) {
      type = PropositionConstant
    } else if (this.mediator.factIsListNumber(fact)) {
      type = NumericListConstant
    } else if (this.mediator.factIsDate(fact)) {
      type = DateConstant
    }
    
    let newFact = new type();
    newFact.Name = fact.Name;
    return newFact;
  }

    
  // #endregion

  // #region Construction & Finalization
  constructor (
    private mediator: Mediator,
    private viewController: ViewControllerService,
    private _dialog: MatDialog,
    private _info: MatSnackBar
  ) {}

  ngOnChanges() {
    this.getVariables();
  }
  // #endregion
  
  // #region Properties
  @Input() model: AbstractRule;
  // #endregion

  // #region Data Elements
  @ViewChild(FactbaseDisplayComponent, {static: false}) factbaseDisplay :FactbaseDisplayComponent;
  factbase: Factbase = new Factbase();
  ignoreCall: boolean = true;
  //The below are used for the selector and new fact constructor
  // propositionConstant = PropositionConstant;
  // stringConstant = StringConstant;
  // numericConstant = NumericConstant;
  // factType: Type<PropositionConstant | StringConstant | NumericConstant> = this.numericConstant;

  selfLoading: boolean = false;
  
  @Input() view: ViewControllerMember;
  get isLoading(): boolean { return this.view.Loading; };
  get isActive(): boolean { return this.view.Active; };
  get versionDateData() { return this.view.Data && this.view.Data.versionDateData; };
  get selectedDate() { return this.versionDateData ? Tools.dateToURLStringAsDate( this.versionDateData.date ) : null }

  // #endregion

  // #region Event Emitters
  @Output() close = new EventEmitter();
  // #endregion
}
