<mat-card class="full-height mat-main-card">
    <mat-progress-bar mode="indeterminate" class="card-top" [class.hidden]="!isLoading"></mat-progress-bar>
    
    <mat-card-title class="overline">
        <button mat-icon-button color="primary" (click)="view.close()" matTooltip="close">
            <mat-icon>arrow_back_ios</mat-icon>
        </button>
        EXECUTE TEST
    </mat-card-title>

    <mat-card-content class="rda-main-content">
        <span>This window will display the results of the test action.</span>
        <div *ngIf="ErrorMessage">
            <div class="error-message">Error:</div>
            <div class="error">{{ ErrorMessageBrief }}</div>
            <a *ngIf="!showErrorMessage" color="primary" class="link" (click)="showFullErrorMessage()">Click for full message</a>
            <div *ngIf="showErrorMessage" class="error">
                <div>{{ ErrorMessage }}</div>
                <div>{{ ErrorDetails }}</div>
            </div>
        </div>
        <!-- factbase list -->
        <mat-card-content *ngIf="Factbase">
            <mat-list>
                <mat-list-item *ngFor="let fact of factbaseFacts; let i = index" class="record" [class.last]="factbaseFacts.length-1 == i">
                    <mat-icon mat-list-icon style="margin: auto;"
                        [class.rotate]="getIconForRun(fact) === 'link_off'" [matTooltip]="getTooltipForRun(fact)"
                        *ngIf="runIsNotVariable(i) && factbaseFacts.length-1 != i"
                    >{{getIconForRun(fact)}}</mat-icon>
                    <div class="name">{{fact.Name}}</div>
                    <div class="value"
                        [class.success]="runIsNotVariable(i) && factbaseFacts.length-1 != i && fact.Value === true"
                        [class.error]="runIsNotVariable(i) && factbaseFacts.length-1 != i && fact.Value === false"
                    >
                        <mat-icon mat-list-icon *ngIf="runIsNotVariable(i) && booleanIcon( fact.Value ) == 'true'">done</mat-icon>
                        <mat-icon mat-list-icon *ngIf="runIsNotVariable(i) && booleanIcon( fact.Value ) == 'false'">close</mat-icon>
                        <div>{{fact.Value}}</div>
                    </div>
                </mat-list-item>
            </mat-list>
        </mat-card-content>

        <!-- steps list -->
        <mat-card-content *ngIf="Results" #stepListElement>
            <!-- <div style="font-size: 28px;margin-top: 20px;">OLD VERSION</div>
            <mat-list>
                <ng-container *ngFor="let record of Results; let i = index">
                    <mat-list-item *ngFor="let fact of record.Content" class="record">
                        <div class="name">{{ fact.Name }}</div>
                        <div class="value"
                            [class.success]="fact.Value === true"
                            [class.error]="fact.Value === false"
                        >{{ fact.Value }}</div>
                    </mat-list-item>

                    <mat-list>
                        <mat-list-item class="title">
                            {{ record.Expression }}
                        </mat-list-item>
                        <mat-list-item class="record step">
                            <div class="name">{{ record.Name }}</div>
                            <div class="value"
                                [class.success]="record.Value === true"
                                [class.error]="record.Value === false"
                            >
                                {{ record.Value }}
                            </div>
                        </mat-list-item>
                    </mat-list>
                </ng-container>
            </mat-list> -->

            <!-- new v1 -->
            <!-- <div style="font-size: 28px;margin-top: 20px;">NEW VERSION 1</div> -->
            <mat-list>
                <ng-container *ngIf="seletectedStep < steps; else lastPageView">
                    <ng-container *ngFor="let step of getSteps; let i = index">
                        <mat-list-item *ngIf="stepIsVariable( step ); else rule" class="record" #stepListItemElement>
                            <div class="name">{{ step.Name }}</div>
                            <div class="value">
                                <div>{{ step.Value }}</div>
                            </div>
                        </mat-list-item>
                        <ng-template #rule>
                            <mat-list-item #currentStep #stepListItemElement
                                class="record step-v1"
                                [class.expanded]="currentStep.showExpression"
                                [class.ruleset]="step.Type==='RuleSet'"
                                [class.rule]="step.Type!=='RuleSet'"
                                (click)="currentStep.showExpression=!currentStep.showExpression"
                            >
                                <div class="index">{{ step.Index }}</div>
                                <div class="rule">
                                    <mat-icon mat-list-icon
                                        [class.rotate]="getIconForStepper(step) === 'link_off'"
                                        class="type"
                                        [matTooltip]="getTooltipForStepper(step)"
                                    >{{ getIconForStepper(step) }}</mat-icon>
                                    <div class="name">{{ step.Name }}</div>
                                    <div class="value"
                                        [class.success]="step.Value === true"
                                        [class.error]="step.Value === false"
                                    >
                                        <mat-icon mat-list-icon *ngIf="booleanIcon( step.Value ) == 'true'" style="margin: 0;">done</mat-icon>
                                        <mat-icon mat-list-icon *ngIf="booleanIcon( step.Value ) == 'false'" style="margin: 0;">close</mat-icon>
                                        <div>{{ step.Value }}</div>
                                    </div>
                                </div>
                                <div *ngIf="currentStep.showExpression" class="expression" #expZoom [class.zoomed]="expZoom.Zoomed === true" (click)="expZoom.Zoomed=!expZoom.Zoomed;">
                                    <div class="type">{{ step.Type }}</div>
                                    <div [innerHTML]="boldNames(step.Expression)"></div>
                                </div>
                            </mat-list-item>
                        </ng-template>
                    </ng-container>
                </ng-container>
                <ng-template #lastPageView>
                    <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ list: lastPage }"></ng-container>
                </ng-template>

                <ng-template #recursiveListTmpl let-list="list" let-indexPrefix="indexPrefix">
                    <ng-container *ngFor="let step of list">
                        <mat-list-item *ngIf="stepIsVariable( step ); else ruleLastPage" class="record">
                            <div class="name">{{ step.Name }}</div>
                            <div class="value">
                                <div>{{ step.Value }}</div>
                            </div>
                        </mat-list-item>
                        <ng-template #ruleLastPage>
                            <mat-list-item #currentStep
                                class="record step-v1"
                                [class.ruleset]="step.Type==='RuleSet'"
                                [class.rule]="step.Type!=='RuleSet'"
                                (click)="currentStep.showExpression=!currentStep.showExpression"
                            >
                                <div class="index">{{ getIndex( indexPrefix, step.Index ) }}</div>
                                <div class="rule">
                                    <mat-icon mat-list-icon
                                        [class.rotate]="getIconForStepper(step) === 'link_off'"
                                        class="type"
                                        [matTooltip]="getTooltipForStepper(step)"
                                    >{{ getIconForStepper(step) }}</mat-icon>
                                    <div class="name">{{ step.Name }}</div>
                                    <div class="value"
                                        [class.success]="step.Value === true"
                                        [class.error]="step.Value === false"
                                    >
                                        <mat-icon mat-list-icon *ngIf="booleanIcon( step.Value ) == 'true'" style="margin: 0;">done</mat-icon>
                                        <mat-icon mat-list-icon *ngIf="booleanIcon( step.Value ) == 'false'" style="margin: 0;">close</mat-icon>
                                        <div>{{ step.Value }}</div>
                                    </div>
                                    <button mat-icon-button
                                        (click)="currentStep._showContent = !currentStep._showContent; $event.stopPropagation()"
                                        [matTooltip]="currentStep._showContent ? 'Collapse' : 'Expand'"
                                    >
                                        <mat-icon>{{ currentStep._showContent ? 'zoom_out' : 'zoom_in' }}</mat-icon>
                                    </button>
                                </div>
                                <div *ngIf="currentStep.showExpression" class="expression" [class.zoomed]="currentStep.Zoomed" (click)="currentStep.Zoomed=!currentStep.Zoomed;">
                                    <div class="type">{{ step.Type }}</div>
                                    <div [innerHTML]="boldNames(step.Expression)"></div>
                                </div>
                            </mat-list-item>
                            <ng-container *ngIf="currentStep._showContent">
                                <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ list: removeErrors(step.Content), indexPrefix: getIndex( indexPrefix, step.Index ) }"></ng-container>
                            </ng-container>
                        </ng-template>
                    </ng-container>
                </ng-template>

            </mat-list>
        </mat-card-content>

    </mat-card-content>
<!-- 
    <mat-list *ngIf="record" class="profiler-step">
        <mat-list-item class="title">
            {{ record.Expression }}
        </mat-list-item>
        <mat-list-item class="record last">
            <div class="name">{{ record.Name }}</div>
            <div class="value"
                [class.success]="record.Value === true"
                [class.error]="record.Value === false"
            >
                {{ record.Value }}
            </div>
        </mat-list-item>
    </mat-list> -->

    <!-- footer -->
    <mat-card-actions fxFlexAlign="end" align="end">
        <div class="pager" *ngIf="steps > 0">
            <button mat-icon-button (click)="jumpPage('first')" matTooltip="First step" matTooltipPosition="below">
                <mat-icon>first_page</mat-icon>
            </button>
            <button mat-icon-button (click)="jumpPage('prev')" matTooltip="Previous step" matTooltipPosition="below">
                <mat-icon>chevron_left</mat-icon>
            </button>
            <div class="location">{{ seletectedStep }} - {{ steps }}</div>
            <button mat-icon-button (click)="jumpPage('next')" matTooltip="Next step" matTooltipPosition="below">
                <mat-icon>chevron_right</mat-icon>
            </button>
            <button mat-icon-button (click)="jumpPage('last')" matTooltip="Last step" matTooltipPosition="below">
                <mat-icon>last_page</mat-icon>
            </button>
        </div>
        <button mat-raised-button
            color="primary"
            class="toolbar-bottom-button"
            (click)="view.close()"
        >OK</button>
    </mat-card-actions>
</mat-card>