import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AbstractRule, RuleSet } from '@smartobjx/smart.objx.models';
import { ViewControllerService, ViewControllerMember } from '../core-services/view/ViewControllerService';
import Mediator from '../core-services/mediator/rule-set-not-replaceable.mediator';
import { Tools } from '../shared/Tools';
import { AuthService } from '../core-services/authentication/auth.service';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';
import { CustomValidator } from '../shared/validation';

@Component({
    selector: 'rule-set-not-replaceable',
    templateUrl: './rule-set-not-replaceable.component.html',
    styleUrls: ['./rule-set-not-replaceable.component.scss']
})
export class RuleSetNotReplaceableComponent implements OnInit {

    debug() {
        let rule = Tools.createCopy(this.model);
        this.viewController.showDebugger(this, rule, this.model, null);
    }

    append(rule: AbstractRule) {
        const viewRef = this.viewController
            .editRuleWithParent({ rule, parent: this.model, versionDateData: this.versionDateData }, this.versionDateData);
        viewRef.Events.onClosed$.then(() => {
            this.selectedIndex = -1;
        })
        setTimeout(() => {
            this.updateSelected(rule);
        }, 100);
    }

    // prim rules - children
    onDebug(rule: AbstractRule) {
        const viewRef = this.viewController.showDebugger(this, rule, this.model, { versionDateData: this.versionDateData });
        viewRef.Events.onClosed$.then(() => {
            this.selectedIndex = -1;
        })
        setTimeout(() => {
            this.updateSelected(rule);
        }, 100);
    }

    onDisableOrEnable(rule: AbstractRule) {
        this.updateSelected(rule);
        let disable = !rule.Disabled;
        this._dialog.open(SimpleDialogComponent, {
            panelClass: 'smart-objx',
            autoFocus: false,
            data: {
                title: 'Attention',
                titleClass: 'warning',
                matIcon: 'warning_amber',
                button1Text: `Yes, ${(disable ? 'disable' : 'enable')} it`,
                content: `Do you really want to ${(disable ? 'disable' : 'enable')} the `
                    + this.getType(rule)
                    + ` "<b>${rule.Name}</b>" ?`
            }
        }).afterClosed().toPromise()
            .then(action => {
                (document.activeElement as any).blur(); // fix force blur on x
                this.selectedIndex = -1;
                if (action) {
                    this.viewController.clearBy(this.model);
                    this.stackToDisableOrEnable({ rule: rule, disable: disable })
                }
            });
    }

    onVersions(rule: AbstractRule, date: Date, versionDates: any[]) {
        this.mediator.showRuleVersions(
            rule,
            this.model as RuleSet,
            date,
            versionDates,
            () => this.selectedIndex = -1
        );
        this.updateSelected(rule);
    }

    private updateSelected(rule: AbstractRule) {
        this.selectedIndex = (this.model as any).PrimRules.indexOf(rule)
    }

    private stackToDisableOrEnable(data: any) {
        let filter = this.disableOrEnableStack.filter(o => o.rule === data.rule);
        if (filter.length) {
            const i = this.disableOrEnableStack.indexOf(filter[0]);
            this.disableOrEnableStack.splice(i, 1);
        } else {
            this.disableOrEnableStack.push(data);
        }
    }

    private getType(record: any) {
        let type = (record as any).$type;
        if (!type) return 'undefined';
        if (type.includes('Rules.RuleSet,')) return 'ruleset';
        if (type.includes('Rules.Rule,')) return 'rule';
    }

    // #endregion
    // #region Construction & Finalization
    constructor(
        private viewController: ViewControllerService,
        private mediator: Mediator,
        public _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private router: Router,
        private _authService: AuthService
    ) {
        this.pov = this._authService.getPOV();
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }
    // #endregion

    // #region Properties
    get model(): AbstractRule {
        return this.i_Model;
    }

    @Input()
    set model(newModel: AbstractRule) {
        this.i_Model = newModel;
        this.selectedIndex = -1;

    }

    get startDateAsString(): Date {
        return CustomValidator.ensureDate(this.model.Version);
    }

    get selectedIndex(): number {
        return this.i_SelectedIndex;
    }

    set selectedIndex(clickedItemIndex: number) {
        this.i_SelectedIndex = clickedItemIndex;
    }

    get viewType() {
        return Tools.getType(this.model);
    }

    get conjunctiveType(): string {
        let model = this.model as RuleSet;
        return (model.Conjunctive as any).$type.includes('smart.Objx.Rules.AndConjunctive') ? 'And' : 'Or';
    }
    get shortCircuit(): string {
        let model = this.model as RuleSet;
        return (model.Conjunctive as any).ShortCircuit ? 'Yes' : 'No';
    }
    // #endregion

    // #region Data Elements
    i_Model: AbstractRule;
    i_SelectedIndex: number;
    pov: string;
    disableOrEnableStack: any[] = [];
    get versionDateData(): boolean { return this.view.Data && this.view.Data.versionDateData; };
    get selectedDate() { return this.versionDateData ? Tools.dateToURLStringAsDate((this.versionDateData as any).date) : false }
    @ViewChild('picker', { static: false }) picker: any;

    @Input() view: ViewControllerMember;
    get isLoading(): boolean { return this.view.Loading; };
    get isActive(): boolean { return this.view.Active; };

    // #endregion

    // #region Events
    // #endregion
}
