import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {  
  private _tokenId = null;
  private _pov = null;
  userName = null;
  ownerName: null;
  PerspectiveName: any;
  
  getUser(){
    if(this._pov){
      return this.userName;
    }
    return null;
  }
  setTokenId(token) {
    this._tokenId = token;
  }

  getTokenId(): any {
    return this._tokenId;
  }
  GetSelectedPerspective() {
    return localStorage.getItem('perspectiveSelected');

  }

  setPOV(pov: String) {
    this._pov = pov;
    
    var e = new CustomEvent('povchanged', { detail: pov });

    window.dispatchEvent(e);
  }

  getPOV(): any {
    return this._pov;
  }

  isAuthenticated() {
    if(this._tokenId != null)
      return true;
    
    // check stored session
    const token = localStorage.getItem('authToken');
    const pov = localStorage.getItem('authPov');    
    if (token && pov) {
      this.setTokenId(token);
      this.setPOV(pov);
      return true;
    }

    // not logged in
    return false;
  }

  logOut() {
    this._tokenId = null;
    this._pov = null;
    localStorage.removeItem('authToken');
    localStorage.removeItem('authPov');
    localStorage.removeItem('perspectiveSelected');
  }

  public httpClient: HttpClient
  constructor(public http: HttpClient) {
    this.httpClient = http
  }
}
