import { Component, forwardRef } from '@angular/core';
// import { ActivatedRoute } from '@angular/router';
import { RulesService, RulesServiceConfiguration, StructuresService, StructuresServiceConfiguration, SettingsService , SettingsServiceConfiguration, UseCaseService, UseCaseServiceConfiguration } from '@smartobjx/smart.connectors';
import { AuthService } from './core-services/authentication/auth.service';
import { ViewControllerService } from './core-services/view/ViewControllerService';
import { combined } from './core-services/mediator/combined';
import { rulesConfigurationService } from './core-services/rulesConfigurationService';
import { structuresConfigurationService } from './core-services/structuresConfigurationService';
import { Router, NavigationEnd } from '@angular/router';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { viewControllerService } from './core-services/viewControllerService-factory';
import { settingsConfigurationService } from './core-services/settingsConfigurationService';
import { useCaseConfigurationService } from './core-services/useCaseConfigurationService';
// import { AbstractRule, RuleSet, UseCase } from '@smartobjx/smart.objx.models';
// import { HttpErrorResponse } from '@angular/common/http';
// import { MatDialog } from '@angular/material/dialog';

export function RulesFactoryService() {
    return (_rulesServiceConfiguration: RulesServiceConfiguration): RulesService => {
      return new RulesService(_rulesServiceConfiguration);
    };
  }
  
export function StructuresFactoryService() {
    return (_structuresServiceConfiguration: StructuresServiceConfiguration): StructuresService => {
      return new StructuresService(_structuresServiceConfiguration);
    };
  }

  
export function SettingsFactoryService() {
    return (_settingsServiceConfiguration: SettingsServiceConfiguration): SettingsService => {
      return new SettingsService(_settingsServiceConfiguration);
    };
  }
  export function UseCaseFactoryService() {
    return (_useCaseServiceConfiguration: UseCaseServiceConfiguration): UseCaseService => {
      return new UseCaseService(_useCaseServiceConfiguration);
    };
  }
  

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
   
      
        {
            provide: UseCaseServiceConfiguration,
            useFactory: useCaseConfigurationService(),
            deps: [AuthService]
        },
        {
            provide: RulesServiceConfiguration,
            useFactory: rulesConfigurationService(),
            deps: [AuthService]
        },
        {
            provide: StructuresServiceConfiguration,
            useFactory: structuresConfigurationService(),
            deps: [AuthService]
        },
        {
            provide: ViewControllerService,
            useFactory: viewControllerService(),
            deps: [AuthService]
        },
        {
            provide: SettingsServiceConfiguration,
            useFactory: settingsConfigurationService(),
            deps: [AuthService]
        },
        {
            provide:RulesService,
            useFactory: RulesFactoryService(),
            deps: [RulesServiceConfiguration]

            
        },
        {
            provide:SettingsService,
            useFactory: SettingsFactoryService(),
            deps: [SettingsServiceConfiguration]

            
        },
        {
            provide:StructuresService,
            useFactory: StructuresFactoryService(),
            deps: [StructuresServiceConfiguration]

            
        },    {
            provide:UseCaseService,
            useFactory: UseCaseFactoryService(),
            deps: [UseCaseServiceConfiguration]

            
        },
       // RulesService,
     //StructuresService,
     //SettingsService,
     //UseCaseService,
        ...combined(),
    ]
})
export class AppComponent {
    // private model: AbstractRule[] = [];
    // private rulesets: RuleSet[] = [];
    // private usecases: UseCase[] = [];

    constructor(
        private router: Router,
        private matIconRegistry: MatIconRegistry,
        private viewController: ViewControllerService,
        private domSanitizer: DomSanitizer,
        private authSvc: AuthService) {

        console.log('test pipeline')

        this.registerSvgIcon("smart_objx_colorblack", "smartObjx-logo_smartObjx-colorblack-RGB");
        this.registerSvgIcon("smart_objx_colorwhite", "smartObjx-logo_smartObjx-colorwhite-RGB");
        this.registerSvgIcon("smart_objx_black", "smartObjx-logo_smartObjx-black-RGB");
        this.registerSvgIcon("smart_objx_white", "smartObjx-logo_smartObjx-white-RGB");
        this.registerSvgIcon("use_cases_empty", "use-cases-empty");
        this.registerSvgIcon("dead_end", "dead-end");
        this.registerSvgIcon("upload_arrow", "upload-arrow");

        // router.events.subscribe(
        //     e => {
        //         if(e instanceof NavigationEnd){
        //           if (e.url.startsWith('/structures')){
        //             this.title = 'STRUCTURES';
        //           }else {
        //             this.title = 'RULES';
        //           }
        //         }
        //     }
        // )
        window.addEventListener('povchanged', e => {
            this.checkUser();
        });
    }
    private registerSvgIcon(name: string, assetName: string) {
        this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/${assetName}.svg`));
    }

    private checkUser() {
        // const auth = this.authSvc as any;
        // this.user = auth.getUser();
    }
    // {
    //     route.queryParams.subscribe( params =>
    //     {
    //         // serviceConfig.SubscriberToken = params[ 'subscriber' ] //'a03852ae-b59c-e811-80c2-000d3a911aad'
    //         serviceConfig.SubscriberToken = 'CF03EEF7-DD45-4EDC-ADAB-B570213C329B';
    //         this.overwriteFromServer();
    //     } )
    // }

    logout() {
        this.viewController.dispose();
        this.router.navigate(['/logout']);
        // this.user = null;
    }

    get user(): string {
        const auth = this.authSvc as AuthService;
        return auth.getUser();
    }

    get owner(): string {
        const auth = this.authSvc as AuthService;
        return auth.ownerName;
      }
    
      get perspective(): string {
        const auth = this.authSvc as AuthService;
        return auth.PerspectiveName;
      }
    getShowMenu(): boolean {
        return !!this.user;
    }
    title: string = 'RULES';
}
