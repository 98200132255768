<table mat-table [(dataSource)]="model.Facts" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
    <!-- Name Column -->
    <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let fact">
            <input *ngIf="editable" type="text" class="form-control" [(ngModel)]="fact.Name" />
            <div id="static_name" *ngIf="!editable"> {{fact.Name}}</div>
        </td>
    </ng-container>

    <!-- Value Column -->
    <ng-container matColumnDef="Value">
        <th mat-header-cell *matHeaderCellDef> Value </th>
        <td mat-cell *matCellDef="let fact">
            <input *ngIf="editable" type="text" class="form-control" [(ngModel)]="fact.Value" />
            <div id="static_value" *ngIf="!editable"> {{fact.Value}}</div>
        </td>
    </ng-container>

    <!-- Remove -->
    <ng-container matColumnDef="Remove">
        <th mat-header-cell *matHeaderCellDef> Remove </th>
        <td mat-cell *matCellDef="let fact">
            <button mat-icon-button (click)="removeRow(fact)">
                <mat-icon>close</mat-icon>
            </button>
         </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
    <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
</table>