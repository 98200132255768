import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { AbstractRule, RuleElement } from '@smartobjx/smart.objx.models';
import Mediator from '../core-services/mediator/rule-editor.mediator';
import { ViewControllerMember, ViewControllerService } from '../core-services/view/ViewControllerService';

import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';
import { CustomValidator } from '../shared/validation';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'rule-editor',
    templateUrl: './rule-editor.component.html',
    styleUrls: ['./rule-editor.component.scss']
})

export class RuleEditorComponent implements OnInit {
    showDateNumberSwitch: boolean;
    // #region Event Handlers
    types = ["String", "Numeric", "DateTime"]
    SelectedType: string;
    // #region Construction & Finalization
    constructor(
        private mediator: Mediator,
        public _dialog: MatDialog,
        private viewController: ViewControllerService,
        private fb: UntypedFormBuilder,
        private _info: MatSnackBar
    ) {
        // init minDate
        this.minDate = new Date();
        this.minDate.setHours(0, 0, 0, 0);
    }

    SaveParents(version) {
       
            let orginalModel = _.cloneDeep(this.model)
            this.model.Version = version
            this.view.Model = this.model;
            let parentComponent = this.viewController.getParentComponent(orginalModel);
            if (!_.isNil(parentComponent)) {
                parentComponent.SaveParentsList(version);
            } else {
                this.viewController.updateRootForReplace.next()
            }
    
    }


    onSaveModel(skipValidation = null) {
        if (_.isNull(skipValidation) && this.form.pristine) return;
        const date = this.i_startDate;
        if (this.checkDateValues(date) || skipValidation) {
            let model = this.mergeFormToModel();
            this.mediator.saveRule(
                this.model,
                model,
                this.view,
                this.SelectedType
            );
        } else {
            this.openDialog(this.startDateMinVerificationDialog);
        }
    }
    markStartDateAsDirty() {
        this.startDateForceInvalid = true;
    }
    fixStartDateAndSave() {
        this.i_startDate = this.priorToParent ? this.minDate : new Date();
        this.onSaveModel();
    }
    showAdvancedCalculationView() {
        // this._dialog.open(CalculationRuleAdvancedViewComponent, {
        //     panelClass: 'form',
        //     // disableClose: true,
        //     data: {
        //         algorithm: this.algorithmExpression.value
        //     }
        // })
        // .afterClosed()
        // .subscribe(val => {
        //     if (val) {
        //         this.algorithmExpression.setValue( val );
        //     }
        // });
    }
    setInvalidExpression(e: any, name: string = 'expression') {
        if (e.keyCode === 9) return;
        this.getControl(name)
            .setErrors({ 'error': true });
    }
    checkExpression(name: string = 'expression') {
        let control = this.getControl(name);
        let expression = control.value;
        if (expression) {
            control.setErrors({ 'loading': true });
            const exp = `'${expression.replace(/\'/g, '\\\'')}'`;
            this.SelectedType = ""
            this.mediator.parseRule(exp)
                .subscribe((parsed: any) => {
                    const newStr = parsed.ExpressionText.replace(/a\.[\u00A0\s]*m\./gi, 'AM').replace(/p\.[\u00A0\s]*m\./gi, 'PM'); ;
                    control.setValue(newStr);
                    let elements = (parsed as any).PrimRuleElements;
                    this.showDateNumberSwitch = this.checkVariable(elements)
                    if (name == 'algorithmExpression') {
                        this.RuleElements = elements;
                    } else {
                        this.PrimRuleElements = elements;
                    }
                    const errors = this.checkNameError(elements);

                    if (errors) this.showVariableNameError();

                    control.setErrors(errors);
                }, (err) => {
                    console.error(err);
                    control.setErrors({ 'error': true });
                })
        } else {
            control.setErrors({ 'error': true });
        }
    }
    ChangeVariableType(type) {
        if (this.RuleElements.length > 0) {
            CustomValidator.SwitchType(this.PrimRuleElements, type.value)

        } else {
            CustomValidator.SwitchType(this.RuleElements, type.value)

        }
    }


    checkVariable(elements: Array<any>) {
        let comparatorNames = [">", "<", "=<", ">="]
        if (comparatorNames.some(substring => elements.some(x => x.Name.includes(substring)))) {
            this.types = ["Numeric", "DateTime"]
        } else {
            this.types = ["String", "Numeric", "DateTime"]
        }

        return elements.filter(x => x.$type.includes("NumericVariable")).length >= 2 || (elements.filter(x => x.$type.includes("StringVariable")).length >= 2 && elements.some(x => x.Name == "=="))
    }


    showVariableNameError() {
        this._info.open('We have detected that the Rule name and the Variable in the Condition Box are identical. Please change one of the two to avoid errors.', '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'end',
            panelClass: 'info-warn'
        });
    }
    checkNameWithVariablesByControlName(controlName: string, elements: any[]) {
        const errorCode = 'variableNameError';
        const control = this.getControl(controlName);

        if (!control) return;

        const errors = this.checkNameError(elements);

        let newErrors = {};
        if (control.errors) // if has error, keep old errors except by errorCode
            for (const prop in control.errors)
                if (prop !== errorCode) newErrors[prop] = control.errors[prop];

        if (errors) {    // add this error to the others 
            control.setErrors(Object.assign(newErrors, errors));
            this.showVariableNameError();
        } else {         // rule's name is not in variables, then remove this error
            control.setErrors(Object.keys(newErrors).length ? newErrors : null);
        }
    }
    checkNameWithVariables() {
        if (this.viewType == 'calculationrule') {
            this.checkNameWithVariablesByControlName('ruleExpression', this.PrimRuleElements);
            this.checkNameWithVariablesByControlName('algorithmExpression', this.RuleElements);
        } else {
            this.checkNameWithVariablesByControlName('expression', this.PrimRuleElements);
        }
    }
    checkNameError(elements: any[]) {
        const { name } = this.form.value;
        return elements.filter(o => o.Name === name)
            .length ? { 'variableNameError': true } : null;
    }
    showAdvancedView(name: string = 'expression') {
        this.mediator.showAdvancedView(this.view, this.getControl(name), () => this.checkExpression(name));
    }

    openDialog(el: any) {
        this._dialog.open(el);
    }
    showUrlAdvancedOptions(el: any) {
        const values = this.form.value;
        const headers = (values.headers && values.headers.length ? values.headers : [{ key: "", value: "" }]).filter(o => !~o.key.indexOf('$id'));
        this.urlAdvancedOptionsForm = this.fb.group({
            username: [values.username],
            password: [values.password],
            headers: this.fb.array(
                headers.map(h => this.fb.group({ key: h.key, value: h.value }))
            )
        });
        this.openDialog(el);
    }
    saveUrlAdvancedOptions() {
        const { username, password, headers } = this.urlAdvancedOptionsForm.value;

        this.form.patchValue({ username, password });
        this.form.controls.headers = this.fb.array(
            headers.filter(o => o.key !== "" && o.value !== "").map(o => this.fb.group(o))
        );

        this.form.updateValueAndValidity();
    }
    addHeader() {
        const { headers } = this.urlAdvancedOptionsForm.controls as any;
        headers.push(this.fb.group({ key: "", value: "" }))
    }
    removeHeader(control: any) {
        const key = control.value.key;
        const { headers } = this.urlAdvancedOptionsForm.controls as any;
        const records = headers.controls.filter(o => o.value.key === key);
        if (records.length > 0) {
            headers.removeAt(headers.controls.indexOf(records[0]));
        }
        if (headers.controls.length === 0) {
            headers.push(this.fb.group({ key: "", value: "" }));
        }
    }
    // #endregion

    // #region Private Methods
    private checkStartDate() {
        const { startDate, initialDate } = this;
        if (startDate) {
            this.minDate = startDate;
        }
    }
    // #region FormProperties
    get expression() {
        return this.getControl('expression');
    }
    get ruleExpression() {
        return this.getControl('ruleExpression');
    }
    get algorithmExpression() {
        return this.getControl('algorithmExpression');
    }
    //#endregion

    private getControl(name: string): UntypedFormControl {
        return this.form.get(name) as UntypedFormControl;
    }
    private mergeFormToModel() {
        let model = Object.assign({}, this.model as any);
        const { value } = this.form;
        model.Name = value.name;
        model.Description = value.description;
        model.Version = this.i_startDate; //value.startDate;
        model.NotReplaceable = value.NotReplaceable;
        if (this.viewType == 'calculationrule') {
            model.Rule.Name = model.Name; // copy CalculationRule's name into inner Rule
            model.Rule.ExpressionText = value.ruleExpression;
            if (this.PrimRuleElements.length > 0) model.Rule.PrimRuleElements = this.PrimRuleElements;
            model.Algorithm.Name = value.algorithmName;
            model.Algorithm.ExpressionText = value.algorithmExpression;
            if (this.RuleElements.length > 0) model.Algorithm.RuleElements = this.RuleElements;
        } else if (this.viewType == 'callbackrule') {
            model.Rule.Name = model.Name;
            model.Rule.ExpressionText = value.expression;
            if (this.PrimRuleElements.length > 0) model.Rule.PrimRuleElements = this.PrimRuleElements;
            model.URL = value.url;
            if (value.username && value.password) {
                model.username = value.username;
                model.password = value.password;
            }
            if (value.headers.length > 0) {
                model.Headers = value.headers.reduce((a, o) => { a[o.key] = o.value; return a; }, {});
            } else {
                model.Headers = null;
            }
        } else {
            model.ExpressionText = value.expression;
            if (this.PrimRuleElements.length > 0) model.PrimRuleElements = this.PrimRuleElements;
        }
        return model;
    }

    private showWarning() {
        this._dialog.open(SimpleDialogComponent, {
            panelClass: 'smart-objx',
            autoFocus: false,
            data: {
                title: 'Attention',
                titleClass: 'warning',
                matIcon: 'warning_amber',
                button1Text: 'Yes, close the panel',
                button2Text: 'No, keep the panel open',
                button1Color: 'primary',
                content: 'Are you sure you want to close this panel?'
                    + '\r\nThe changes won´t be saved.'
            }
        }).afterClosed().toPromise()
            .then(action => {
                if (action) {
                    this.view.close(true);
                }
            });
    }

    private registerEvents() {
        this.view.Events.onClose$ = () => {
            if (!this.isUntouched) {
                this.showWarning();
            }
            return this.isUntouched;
        };
    }

    private checkDateValues(date: Date) {
        return date >= this.minDate; // || (this.initialDate != null && date == this.initialDate);
    }
    // #endregion



    ngOnInit() {
        this.checkStartDate();
        this.registerEvents();
        let model = (this.model as any);
        if (this.viewType == 'calculationrule') {
            this.Title = 'edit calculation rule';
            if (model.Rule.PrimRuleElements.length && model.Algorithm.RuleElements) {
                this.calculationIsValid = true;
            }
        }

        model.Version = CustomValidator.ensureDate(model.Version);

        if (this.viewType == 'calculationrule') {
            this.form = this.fb.group({
                name: [model.Name, Validators.required],
                description: [model.Description, Validators.nullValidator],
                notReplaceable: [model.NotReplaceable || false, [Validators.required]],
                ruleExpression: [model.Rule.ExpressionText, Validators.required],
                algorithmName: [model.Algorithm.Name, Validators.required],
                algorithmExpression: [model.Algorithm.ExpressionText, Validators.required]
            });
        } else if (this.viewType == 'callbackrule') {
            this.form = this.fb.group({
                name: [model.Name, Validators.required],
                description: [model.Description, Validators.nullValidator],
                expression: [model.Rule.ExpressionText, Validators.required],
                notReplaceable: [model.NotReplaceable || false, [Validators.required]],
                url: [model.URL, [Validators.required]],
                username: [model.Username],
                password: [model.Password],
                headers: this.fb.array(model.Headers
                    ? Object.keys(model.Headers).map(key => this.fb.group({
                        key, value: model.Headers[key]
                    }))
                    : []
                )
            });
        } else {
            this.form = this.fb.group({
                name: [model.Name, Validators.required],
                description: [model.Description, Validators.nullValidator],
                expression: [model.ExpressionText, Validators.required],
                notReplaceable: [model.NotReplaceable || false, [Validators.required]],
            });
        }
        this.i_startDate = new Date(model.Version.getTime());
        this.initialDate = model.Version;
    }

    ngOnChanges(changes: any) {
        this.checkStartDate();
        if (changes.model && !changes.model.firstChange) {
            let model = changes.model.currentValue;

            model.Version = CustomValidator.ensureDate(model.Version);

            this.form.patchValue({
                name: model.Name,
                description: model.Description
            });

            this.i_startDate = new Date(model.Version.getTime());
            this.initialDate = model.Version;
        }

        // focus name
        if (changes.isLoading && changes.isLoading.currentValue == false && !this.disableFocused) {
            setTimeout(() => {
                this.nameInputRef.nativeElement.focus();
                // this.nameInputRef.nativeElement.select();
                this.disableFocused = true;
            }, 0);
        }
    }
    // #endregion

    // #region Properties
    get model(): AbstractRule {
        return this.i_Model;
    }

    @Input()
    set model(newModel: AbstractRule) {
        this.i_Model = newModel;
        this.selectedIndex = undefined;
    }

    get selectedIndex(): number {
        return this.i_SelectedIndex;
    }

    set selectedIndex(clickedItemIndex: number) {
        this.i_SelectedIndex = clickedItemIndex;
    }

    get descriptionHint(): string {
        return 'This text should be a description of the actions the <b>Rule</b> performs.';
    }

    get modelHasActivity() { // future use 
        return (this.model as any).$type.includes('Activity');
    }

    get formIsDisabled(): boolean {
        return !this.form || this.form.pristine || !this.form.valid // || this.checkExpressionResult != 'success';
            || !!this.checkNameError(this.RuleElements) || !!this.checkNameError(this.PrimRuleElements);
    }
    private get isUntouched(): boolean {
        return this.form.pristine;
    }
    get priorToParent(): boolean {
        const now = new Date();
        return now < this.minDate;
    }

    // the parent date (will be used as minDate)
    get startDate(): Date {
        const { Data } = this.view;
        return Data ? Data.startDate : undefined;
    }
    minDate: Date;
    initialDate: Date; // the current version date
    // #endregion

    // #region Data Elements
    private i_Model: AbstractRule;
    private i_SelectedIndex: number;
    private form: UntypedFormGroup;
    private urlAdvancedOptionsForm: UntypedFormGroup;
    private i_startDate: Date = new Date();
    startDateForceInvalid: boolean = false;

    Title: string = 'edit rule';
    URL: string;
    calculationIsValid: boolean = false;
    selfLoading: boolean = false;

    checkExpressionErrorTip: string = 'The expression is not valid';

    RuleElements: any[] = [];
    PrimRuleElements: any[] = [];

    @Input() view: ViewControllerMember;
    get isLoading(): boolean { return this.view.Loading; };
    get isActive(): boolean { return this.view.Active; };
    private get viewType(): string { return this.view.Type; };
    get versionDateData(): { date: Date, showTime: boolean, altClass: boolean } { return this.view.Data && this.view.Data.versionDateData; };

    get canSave() {
        return !this.form.pristine && this.calculationIsValid;
    }
    @ViewChild('nameInput', { static: false }) nameInputRef: any;

    private disableFocused: boolean = false;
    fixDateValue: string;

    @ViewChild('startDateMinVerification', { static: false }) startDateMinVerificationDialog: any;
    // #endregion

    // #region Event Emitters
    // #endregion
}