<mat-card class="full-height mat-main-card" [class.mat-card-raised]="isActive">
  <mat-progress-bar mode="indeterminate" class="card-top" [class.hidden]="!isLoading && !selfLoading"></mat-progress-bar>
  
  <mat-card-title class="overline">CONFIGURE FACTBASE</mat-card-title>
  <mat-icon class="close" (click)="view.close()">close</mat-icon>
  
  <version-caption *ngIf="versionDateData"
    actionText="testing"
    [selectedDate]="versionDateData.date"
    [showTime]="versionDateData.showTime"
    [isNotInitialDate]="versionDateData.altClass"
  ></version-caption>

  <ng-container *ngIf="!isLoading">
    <mat-card-content class="rd-main-content">
      <div class="h5">{{ model.Name }}</div>
      <div class="body-2">{{ model.Description }}</div>

      <!-- main list -->
      <mat-card-title class="rules-title">
        <span>Facts</span>
        <span class="toolbar-spacer"></span>
        <ng-template #loadFileDialog>
          <div class="h6" matDialogTitle>Upload file with variables</div>
          <div *ngIf="errorsToLoad" class="caption error-messages">{{ errorsToLoad }}</div>
          <mat-dialog-content class="uploader-tools" #loadFileDialogContent>
            <div *ngIf="fileToLoad">File <b>{{ fileToLoad.file.name }}</b> ready.</div>
            <!-- <table *ngIf="fileToLoad">
              <tr><th>Name</th><th>Value</th></tr>
              <tr *ngFor="let record of fileToLoad.parsed; let i = index" >
                <td>{{ record.Name }}</td><td>{{ record.Value }}</td>
              </tr>
            </table> -->
            <ng-container *ngIf="isAdvancedUpload;else legacyBrowserMessage">
              <mat-icon svgIcon="upload_arrow" class="upload-icon"></mat-icon>
              <div>.CSV | .JSON</div>
              <div>You can also upload your file by</div>
            </ng-container>
            <ng-template #legacyBrowserMessage>
              <div>You can upload your file by</div>
            </ng-template>
            <label for="variableFileUploader" id="variableFileUploaderLabel" class="link load-file" [class.disabled]="buttonDisabledCondition()">clicking here.</label>
            <input type="file" name="variableFile" id="variableFileUploader" (change)="prepareFile( $event.target )" accept=".csv, application/JSON" />
          </mat-dialog-content>
          <mat-dialog-actions align="end">
            <button mat-button matDialogClose>close</button>
            <button mat-raised-button matDialogClose color="primary" (click)="loadFile()" [disabled]="!fileToLoad">Upload</button>
          </mat-dialog-actions>
        </ng-template>
        <a color="primary" class="link" (click)="openDialog(loadFileDialog)" [class.disabled]="buttonDisabledCondition()">Load variables from file</a>
        <a color="primary" class="link" (click)="reset()" [class.disabled]="buttonDisabledCondition()">Reset</a>
      </mat-card-title>
      <mat-card-content class="rd-facts">
        <div *ngFor="let fact of factbase.Facts; let i = index" >
          <div class="record" [class.column]="isDateTime(fact)">
            <mat-form-field class="disabled-but-looks-enabled" >
              <input matInput placeholder="Variable name" [ngModel]="fact.Name" disabled />
            </mat-form-field>
            <ng-container *ngIf="!isListNumber(fact) && !isDateTime(fact)">
              <mat-form-field>
                  <!-- <div>{{ fact.Value }}</div> -->
                  <input matInput placeholder="Value" [(ngModel)]="fact.Value" />
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="isDateTime(fact)">
              <custom-date [(model)]="fact.Value" [placeholder]="placeholder"></custom-date>
            </ng-container>
            <ng-container *ngIf="isListNumber(fact)">
              <!-- <div>{{ fact.Value }}</div> -->

              <div class="list">
                <mat-form-field *ngFor="let _ of listNumberForLoop(fact);let l = index; trackBy: trackByFn">
                  <input matInput [placeholder]="'Value(' + l + ')'" [(ngModel)]="fact.Value[l]" />
                  <mat-icon matSuffix *ngIf="fact.Value[l]!==undefined" class="my-icon" (click)="removeAt(fact, l)">close</mat-icon>
                </mat-form-field>
              </div>
            </ng-container>
          </div>
          <mat-hint [innerHTML]="getTextByType( fact )"></mat-hint>
        </div>
      </mat-card-content>
    </mat-card-content>

    <!-- footer -->
    
    <!-- formControlName="notReplaceable" -->
    <mat-checkbox
      [(ngModel)]="ignoreCall"
      color="accent"
      class="check-no-call"
    >Do not call the URLs</mat-checkbox>
    <mat-card-actions fxFlexAlign="end" align="end">
      <mat-divider></mat-divider>
      
      <button mat-raised-button
        color="primary"
        class="toolbar-bottom-button"
        [disabled]="buttonDisabledCondition()"
        (click)="openDialog(saveAs)"
      >Save variables</button>
      <ng-template #saveAs>
        <div class="h6" matDialogTitle>Do you want to save the variables?</div>
        <mat-dialog-content>
          <mat-form-field class="disabled-but-looks-enabled">
            <input matInput placeholder="File name" #fileName />
          </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
          <button mat-button matDialogClose>close</button>
          <button mat-raised-button matDialogClose color="primary" (click)="saveFile(fileName)" [disabled]="!fileName.value">Save</button>
        </mat-dialog-actions>
      </ng-template>
      <button mat-raised-button
        color="primary"
        class="toolbar-bottom-button"
        [disabled]="buttonDisabledCondition()"
        (click)="run( false )"
      >Step by step</button>
      <button mat-raised-button
        color="primary"
        class="toolbar-bottom-button"
        [disabled]="buttonDisabledCondition()"
        (click)="run()"
      >Run</button>
    </mat-card-actions>
  </ng-container>
</mat-card>