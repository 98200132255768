import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core-services/authentication/auth.service';
import { Router } from '@angular/router';
//import { MsalService } from '@azure/msal-angular';

@Component({
    template: ''
})
  
export class LogoutComponent implements OnInit {

    constructor(private _authService: AuthService, private router: Router //,private authADService: MsalService
        ) {}

    ngOnInit() {
        this._authService.logOut();
    //    this.authADService.logout();

        this.router.navigate(['/']);
    }
}