import { Component, Inject, ViewChild } from '@angular/core';
import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.css']
})
export class SimpleDialogComponent {
  title: string;
  titleClass: string;
  matIcon: string;
  content: string;
  value: string;
  button1Text: string;
  button2Text: string;
  hideButton1: boolean;
  hideButton2: boolean;
  button1Color: string;
  button2Color: string;
  @ViewChild('btnRef', {static: false}) buttonRef: MatButton;

  constructor( @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<SimpleDialogComponent>
  ) {
    this.title = data.title;
    this.titleClass = data.titleClass;
    this.matIcon = data.matIcon || null;
    this.button1Text = data.button1Text || 'Yes, delete it';
    this.button2Text = data.button2Text || 'No, close';
    this.content = this.getContent(data.content);
    this.hideButton1 = data.hideButton1 || false;
    this.hideButton2 = data.hideButton2 || false;
    this.button1Color = data.button1Color || "warn";
    this.button2Color = data.button2Color || "primary";
    if(data.closeOnSeconds){
      setTimeout(() => {
        dialogRef.close()
      }, data.closeOnSeconds * 1000);
    }
    
  }
  private getContent(content: string){
    if(!~content.indexOf('\r\n'))
      return content;

    return content.split('\r\n').join('<br>');
  }
    
  ngAfterViewInit() {
    this.buttonRef.focus();
  }
}
