import { RulesServiceConfiguration, UseCaseServiceConfiguration } from '@smartobjx/smart.connectors';
import { AuthService } from './authentication/auth.service';
import { environment } from '../../environments/environment';

export function useCaseConfigurationService() {
  return (_authenticationService: AuthService): UseCaseServiceConfiguration => {
    return new UseCaseServiceConfiguration({
      httpClient:_authenticationService.httpClient,
      basePath: environment.ruleServiceBasePath,
      SubscriberToken: _authenticationService.getTokenId(),
      POVToken: _authenticationService.getPOV()
    });
  };
}