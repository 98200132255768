
import UseCaseMediator, { factory as UseCaseMediatorFactory } from './use-case-browser.mediator';
import RuleSetEditorMediator, { factory as RuleSetEditorMediatorFactory } from './rule-set-editor.mediator';
import RuleEditorMediator, { factory as RuleEditorMediatorFactory } from './rule-editor.mediator';
import RuleDebuggerMediator, { factory as RuleDebuggerMediatorFactory } from './rule-debugger.mediator';
import RuleSetNotReplaceableMediator, { factory as RuleSetNotReplaceableMediatorFactory } from './rule-set-not-replaceable.mediator';
import RuleVersionsMediator, { factory as RuleVersionsMediatorFactory } from './rule-versions.mediator';
import CardListMediator, { factory as CardListMediatorFactory } from './card-list.mediator';
import LoginPortalMediator, { factory as LoginPortalMediatorFactory } from './login-portal.mediator';
import UseCaseGeneratorMediator, { factory as UseCaseGeneratorMediatorFactory } from './use-case-generator.mediator';
import FixesMediator, { factory as FixesMediatorFactory } from './fixes.mediator';
import { RulesService, StructuresService, SettingsService, UseCaseService } from '@smartobjx/smart.connectors';
import { ViewControllerService } from '../view/ViewControllerService';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../authentication/auth.service';
//import { BroadcastService } from '@azure/msal-angular';

export function combined() {
  return [
    {
      provide: UseCaseMediator,
      useFactory: UseCaseMediatorFactory(),
      deps: [RulesService, UseCaseService, ViewControllerService,AuthService]
    },
    {
      provide: RuleSetEditorMediator,
      useFactory: RuleSetEditorMediatorFactory(),
      deps: [RulesService, UseCaseService, ViewControllerService, MatSnackBar,AuthService]
    },
    {
      provide: RuleEditorMediator,
      useFactory: RuleEditorMediatorFactory(),
      deps: [RulesService, ViewControllerService, MatSnackBar ,AuthService]
    },
    {
      provide: RuleDebuggerMediator,
      useFactory: RuleDebuggerMediatorFactory(),
      deps: [RulesService, ViewControllerService]
    },
    {
      provide: RuleVersionsMediator,
      useFactory: RuleVersionsMediatorFactory(),
      deps: [RulesService, ViewControllerService]
    },
    {
      provide: CardListMediator,
      useFactory: CardListMediatorFactory(),
      deps: [RulesService]
    },
    {
      provide: LoginPortalMediator,
      useFactory: LoginPortalMediatorFactory(),
      deps: [RulesService, StructuresService, SettingsService,AuthService
      //  , BroadcastService
      ]
    },
    {
      provide: RuleSetNotReplaceableMediator,
      useFactory: RuleSetNotReplaceableMediatorFactory(),
      deps: [RulesService, ViewControllerService]
    },
    {
      provide: UseCaseGeneratorMediator,
      useFactory: UseCaseGeneratorMediatorFactory(),
      deps: [RulesService]
    },
    {
      provide: FixesMediator,
      useFactory: FixesMediatorFactory(),
      deps: [RulesService, AuthService]
    }
  ];
}
