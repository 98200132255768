import { RulesServiceConfiguration } from '@smartobjx/smart.connectors';
import { AuthService } from './authentication/auth.service';
import { environment } from '../../environments/environment';

export function rulesConfigurationService() {
  return (_authenticationService: AuthService): RulesServiceConfiguration => {
    return new RulesServiceConfiguration({
      httpClient:_authenticationService.httpClient,
      basePath: environment.ruleServiceBasePath,
      SubscriberToken: _authenticationService.getTokenId(),
      POVToken: _authenticationService.getPOV()
    });
  };
}