import { Component, Input, Output, EventEmitter, OnChanges, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { AbstractRule, Factbase } from '@smartobjx/smart.objx.models';
import { MatDialog } from '@angular/material/dialog';
import { ViewControllerMember } from '../core-services/view/ViewControllerService';
import { debug } from 'util';
import { error } from 'protractor';

@Component({
  selector: 'rule-debugger-advanced',
  templateUrl: './rule-debugger-advanced.component.html',
  styleUrls: ['./rule-debugger-advanced.component.scss'],
  host: {'class': 'test-advanced-div'}
})
export class RuleDebuggerAdvancedComponent implements OnChanges {

  // #region Event Handlers
  booleanIcon( value: any ) {
    if(typeof value === 'boolean'){
      if( value ){
        return 'true';
      }
      return 'false';
    }
    return undefined;
  }
  jumpPage(to: string){
    switch (to) {
      case 'first':
        this.seletectedStep = 1
        break;
      case 'last':
        this.seletectedStep = this.steps;
        break;
      case 'prev':
        if ( this.seletectedStep <= 1 ) return; // first reached
        this.seletectedStep--;
        break;
      case 'next':
        if ( this.seletectedStep >= this.steps) return; // last reached
        this.seletectedStep++;
        break;
    }
    this.record = this.Results[this.seletectedStep];
  }

  boldNames(text: string){
    if(!text) return;
    let separators = ['If ', ' OR ',' AND ', ' AND(True) ', ' AND(False) ', ' OR(True) ', ' OR(False) '];
    separators.forEach(sep => {
      text = this.splitJoinBy(text, sep);
    })
    return `<b>${text}</b>`;
  }

  private splitJoinBy(text: string, separator: string){
    return text.split(separator).join(`</b>${separator}<b>`);
  }

  getIconByType(type: string){
    switch(type.toLowerCase()){
        case 'ruleset': return 'file_copy';
        case 'rule': return 'insert_drive_file';
        case 'calculationrule': return 'exposure';
        case 'callbackrule': return 'link';
        case 'algorithm': return 'calculate';
    }
  }
  getIconForStepper( record: any){
    const icon = this.getIconByType(record.Type);
    if(icon === 'link' && this.stepHasErrors(record)) return 'link_off';
    return icon;
  }
  getIconForRun( fact: any ){
    const type = fact.RuleType;
    if(type){
      const namespace = 'smart.Objx.Rules.';
      const namespacesEndIndex = type.indexOf(namespace)+namespace.length;
      const firstCommaIndex = type.indexOf(', ');

      const t = type.substring(namespacesEndIndex, firstCommaIndex).toLowerCase();

      const icon = this.getIconByType(t);

      if(icon === 'link' && this.hasErrors(fact)) return 'link_off';
      
      return icon;
    }
  }
  private stepHasErrors( record: any ): boolean{
    const errors = this.resultErrors.filter((o: any) => o.Name === record.Name + "URLerror");
    return errors.length > 0;
  }
  private hasErrors( fact: any ): boolean {
    const errors = this.factbaseErrors.filter((o: any) => o.Name === fact.Name + "URLerror");
    return errors.length > 0;
  }
  private getRecordContent( record: any ): any[]{
    return record.Content.filter((o: any) => o.Type !== 'ErrorConstant');
  }
  getTooltipForStepper( record: any ){
    return this.getErrorByName( record.Name, this.resultErrors );
  }
  getTooltipForRun( fact: any ){
    return this.getErrorByName( fact.Name, this.factbaseErrors );
  }
  private getErrorByName( name: string, source: any ): string {
    const errors = source.filter((o: any) => o.Name === name + "URLerror");
    if( errors.length > 0 ){
      return errors[0].Value;
    }
  }
  runIsNotVariable( index: number ){
    return index >= this.InitialFactbase.Facts.length;
  }
  stepIsVariable( step: any ){
    const t = step.Type.toLowerCase();
    return t != 'ruleset' && t != 'rule' && t != 'calculationrule' && t != 'callbackrule';
  }
  getIndex( indexPrefix: string, index: number ){
    return index ? indexPrefix ? `${indexPrefix}.${index}` : index : "";
  }
  removeErrors( content: any ){
    return content.filter(o => o.Type !== "ErrorConstant");
  }
  // #endregion
  
  // #region Construction & Finalization
  constructor (
    public _dialog: MatDialog
  ) {}

  ngOnChanges(){
    if (this.Results) {
      this.seletectedStep = 1;
      this.record = this.Results[0];
       
      const info = new pageInfo( this.Results.Tree );
      this.stepsBase = info.getSteps( this.Results.StepsCount );
      this.resultErrors = info.getErrors();
    }
  }
  // #endregion

  // #region Properties
  @Input() model: AbstractRule;
  @Input() Factbase: Factbase;
  get Results(): any {
    return this.view.Data ? this.view.Data.Results : null
  };
  get lastPage(): any[]{
    return [this.Results.Tree];
  }
  get steps(): number {
    const errorCount = this.resultErrors ? this.resultErrors.length : 0;
    return this.Results && this.Results.StepsCount - errorCount > 0 ? this.Results.StepsCount - errorCount : 0;
  }
  seletectedStep: number = 1;

  get getSteps(): any[] {
    if(this.seletectedStep == this.steps) {
      return this.stepsBase;
    }
      
    return this.stepsBase.slice(0, this.seletectedStep);
  }
  stepsBase: any[];
  stepsCollapsed: any

  private stepSelected: number = 0;
  @Input() InitialFactbase: Factbase;

  private contains( s1: string, s2: string ){
    return !!~s1.indexOf(s2);
  }
  showFullErrorMessage(){
    this.showErrorMessage = true;  
  }
  showErrorMessage: boolean = false; 
  get ErrorMessageBrief(): string{
    let message = this.view.Data.ErrorMessage;
    let messagelc = message.toLowerCase();
    return this.contains( messagelc, 'bad request' ) ? 'Bad request'
      : this.contains( messagelc, 'internal server error' ) ? 'Internal Server Error'
      : message;
  }
  get ErrorDetails(): string {
    return this.view.Data ? this.view.Data.ErrorDetails : undefined;
  }
  get ErrorMessage(): string{
    return this.view.Data ? this.view.Data.ErrorMessage : undefined;
  }
  
  @Input() view: ViewControllerMember;
  get isLoading(): boolean { return this.view.Loading; };
  get isActive(): boolean { return this.view.Active; };

  private get factbaseErrors(): any[]{
    return (this.Factbase.Facts as any).filter((o: any) => !!~o.$type.indexOf('ErrorConstant'));
  }
  private get factbaseFacts(): any[]{
    return (this.Factbase.Facts as any).filter((o: any) => !~o.$type.indexOf('ErrorConstant'));
  }
  // private get resultErrors(): any[]{
  //   return this.stepsBase.filter((o: any) => o.Type === 'ErrorConstant');
  // }
  resultErrors: any[];
  
  selfLoading: boolean = false;
  record: any;
  @ViewChildren("stepListElement") stepListElement: QueryList<ElementRef>;
  @ViewChildren("stepListItemElement") stepListItemElement: QueryList<ElementRef>;
  
  ngAfterViewInit() {
    this.stepListItemElement.changes.subscribe(() => {
      if (this.stepListItemElement && this.stepListElement.first && this.stepListItemElement.last) {
        const list = this.stepListElement.first.nativeElement;
        const el = (this.stepListItemElement.last as any)._element.nativeElement as HTMLInputElement;
        el.focus();
        list.scrollTo(0, list.scrollHeight);
      }
    });
  }
  // #endregion

  // #region Event Emitters
  // #endregion
}

class pageInfo {
  constructor( node: any ){
    this.mainNode = node;
  }

  mainNode: any;
  amount: any;
  obtained: number = 0;
  private steps: any[] = [];
  private errors: any[] = [];

  get incompleted(): boolean {
    return this.obtained < this.amount;
  }

  getSteps( amount: number ): any[]{
    this.amount = amount;
    this.steps = [];
    this.getPageFromNode( this.mainNode );
    return this.steps;
  }
  getErrors(){
    return this.errors;
  }
  private add( page: any ) {
    this.steps.push(page);
    this.obtained++;
  }
  private getPageFromNode( node: any, indexPrefix: string = "" ){
    const { Name, Index, Value, Type, Expression } = node;
    const newIndexPrefix = Index ? indexPrefix ? `${indexPrefix}.${Index}` : Index : "";

    if(Type === 'ErrorConstant') {
      this.errors.push(node);
      return;
    }
    if(node.Content.length){
      for( var i = 0; i < node.Content.length; i++ ){
        if(!this.incompleted) return;

        this.getPageFromNode(node.Content[i], newIndexPrefix );
      }
    } 
    if(!this.incompleted) return;
    this.add({ Name, Index: newIndexPrefix, Value, Type, Expression });
  }
}