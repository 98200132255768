import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewControllerMember } from '../core-services/view/ViewControllerService';

@Component({
  selector: 'version-caption',
  templateUrl: './version-caption.component.html',
  styleUrls: ['./version-caption.component.scss']
})
export class VersionCaptionComponent implements OnInit {

  onChangeSelectedDate( date: Date ){
    this.onChange.emit(date);
  }

  constructor() { }

  ngOnInit() {
  }

  get useDatepicker(): boolean {
    return typeof this.firstVersionsDate !== 'undefined' && this.firstVersionsDate !== null;
  }

  @Input() view: ViewControllerMember;

  @Input() selectedDate: Date;
  @Input() versionDates: Date[] = [];
  @Input() firstVersionsDate: Date;
  @Input() initialDate: Date;
  @Input() actionText: string = 'viewing';
  @Input() showTime: boolean;
  // @Input() useDatepicker: boolean = true;
  @Input() isNotInitialDate: boolean;

  @Output() onChange = new EventEmitter<any>();

  versionTime: Date;
}
