import { Injectable } from '@angular/core';
import { RulesService } from '@smartobjx/smart.connectors';
import { Observable, Subscriber } from 'rxjs';
import { Tools } from 'src/app/shared/Tools';
import { ViewControllerMember, ViewControllerService } from '../view/ViewControllerService';
import { AbstractRule, RuleSet } from '@smartobjx/smart.objx.models';
import { tap } from 'rxjs/operators';
import { CustomValidator } from 'src/app/shared/validation';

export function factory() {
  return (_server: RulesService, _vc: ViewControllerService): Mediator => {
    return new Mediator(
      _server,
      _vc
    );
  };
}

@Injectable({
    providedIn: 'root'
})
export default class Mediator {
  constructor(
    private server: any,
    private viewController: ViewControllerService
  ) {}
  
  showRuleVersions( rule: AbstractRule, parent: RuleSet, date: Date, versionDates: any[], callback: () => void ){
    this.simpleRuleAction(
        subscriber => {
            let viewRef = this.viewController.showVersions( date, versionDates, parent, subscriber );
            if(CustomValidator.is( rule, 'ruleset' )){
                this.findRuleOnWithVersionInfo( viewRef, rule.OID, date ).toPromise();
            } else {
                this.findRuleOn( viewRef, rule.OID, date ).toPromise();
            }
        },
        callback
    );
  }
  

  simpleRuleAction(action: (subscriber: Subscriber<any>) => void, callback: () => void){
    const observable = new Observable(action);
    
    observable.subscribe({
        error: e => console.error(e),
        complete: callback
    })
  }

  findRuleOn( view: ViewControllerMember, ruleID: string, date: Date ): Observable<any>{
    view.Loading = true;
    return this.server.findRuleOn( ruleID, Tools.dateToURLStringAsDate( date ) )
        .pipe( tap( (abstractRule: any) => {
            view.Loading = false;
            view.updateModel( abstractRule as RuleSet); // update the ruleset view already shown
        })
    );
  }

  findRuleOnWithVersionInfo( view: ViewControllerMember, ruleID: string, date: Date ){
      view.Loading = true;
      return this.server.findRuleOnWithVersionInfo( ruleID, Tools.dateToURLStringAsDate( date ) )
          .pipe( tap(
              ( data: any ) => {
                  view.Loading = false;
                  view.updateModel( data.rule as AbstractRule );
                  view.updateData({ changedChildren: data.changedChildren });
              },
              e => { 
                  view.Loading = false;
              }
          )
      );
  }
}