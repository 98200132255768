import { Injectable } from '@angular/core';
import { RulesService } from '@smartobjx/smart.connectors';
import { ViewControllerService, ViewControllerMember } from '../view/ViewControllerService';
import { MatSnackBar } from '@angular/material/snack-bar';
import AbstractRuleMediator from './abstract-rule.mediator';

export function factory() {
  return (
    _server: RulesService,
    _vc: ViewControllerService,
  ): Mediator => {
    return new Mediator( _server, _vc );
  };
}

@Injectable({
  providedIn: 'root'
})
export default class Mediator {
  constructor(
    private server: any,
    viewController: ViewControllerService,
  ) {
  }

  public generateUseCase( useCaseName: string, amount: number, expression: string, algorithmExpression: string, view: ViewControllerMember ){
    view.Loading = true;
    this.server.generateUseCase( useCaseName, amount, expression, algorithmExpression )
      .subscribe({
        next( res: any ) {
          // console.log(res);
          setTimeout(() => {
            view.observable.resolve({ updateList: true })
            view.Loading = false;
            view.close();
          }, 100)
        },
        error: e => {
          view.Loading = false;
          console.error(e);
        }
      });
  }
  
  parse( expression: string ): any {
    return this.server.parseRule( expression );
  }
}