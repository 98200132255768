import { Component, Input, ViewChild } from '@angular/core';
import { Factbase, IConstant } from '@smartobjx/smart.objx.models';

@Component({
  selector: 'factbase-display',
  templateUrl: './factbase-display.component.html',
  styleUrls: ['./factbase-display.component.css']
})
export class FactbaseDisplayComponent {
    @Input() model: Factbase = new Factbase();
    @Input() editable: boolean = true;
   // @ViewChild(matTable, {static: false}) private table: matTable<any>;

    refresh() {
     //   this.table.renderRows();
    }

    getDisplayedColumns() {
        let displayedColumns: string[];
        if (this.editable) {
            displayedColumns=['Name', 'Value', 'Remove'];
        }
        else {
            displayedColumns=['Name', 'Value'];
        }
        return displayedColumns;
    }

    removeRow(fact: IConstant) {
        if (this.editable) {
            let index = this.model.Facts.indexOf(fact, 0);
            if (index > -1) {
                this.model.Facts.splice(index, 1);
            }
            //Since the table assumes constant data, it needs to be manually refreshed
            this.refresh();
        }
    }
}