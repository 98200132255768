import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AbstractRule, RuleSet } from '@smartobjx/smart.objx.models';
import Mediator from '../core-services/mediator/rule-versions.mediator';
import { ViewControllerService, ViewControllerMember } from '../core-services/view/ViewControllerService';
import { Tools } from '../shared/Tools';
import { CustomValidator } from '../shared/validation';

@Component( {
    selector: 'rule-versions',
    templateUrl: './rule-versions.component.html',
    styleUrls: [ './rule-versions.component.scss' ]
} )

export class RuleVersionsComponent implements OnInit, OnDestroy
{
    // #region Event Handlers
    onChangeSelectedDate( date: Date ){
        this.selectedDate = date;
        this.mediator.changeVersion(
            this.model,
            date
        );
    }

    debug(){        
        this.mediator.debugRule(this.model, this.selectedDate, this.showTime,this, this.isNotInitialDate, null);
    }

    edit(){
        this.mediator.editRule(this.model, this.selectedDate, this.showTime, this.isNotInitialDate, null,this);
    }
    showVersionOn() {
        return (record: any) => {
            return record ? !!~this.changedChildren.indexOf(record.OID) : false;
        }
    }

    private getVersionsForDay(d: Date) : Date[]{
        return this.versionDates.filter(o =>
            o.getFullYear() === d.getFullYear()
            && o.getMonth() === d.getMonth()
            && o.getDate() === d.getDate()
        );
    }

    // #endregion
    // #region Construction & Finalization
    constructor ( 
        private viewController: ViewControllerService,
        private mediator: Mediator,
        public _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private router: Router
    ) {
    }

    ngOnInit()
    {
        this.initialDate = this.selectedDate;
        if(this.versionDates.length){
            this.firstVersionsDate = this.versionDates[0];
        }
    }

    ngOnDestroy()
    {
    }
    // #endregion

    // #region Properties
    get model(): AbstractRule
    {
        return this.i_Model;
    }

    @Input()
    set model( newModel: AbstractRule )
    {
        this.i_Model = newModel;
    }
    
    get isNotInitialDate(): boolean{
        return this.initialDate.getTime() !== this.selectedDate.getTime();
    }
    get showTime(): boolean {
        return this.getVersionsForDay(this.selectedDate).length > 1;
    }
    get viewType() {
        return Tools.getType( this.model );
    }
    get startDateAsString(): Date {
        return CustomValidator.ensureDate( this.model.Version );
    }
    
    get conjunctiveType(): string {
        let model = this.model as RuleSet;
        return (model.Conjunctive as any).$type.includes('smart.Objx.Rules.AndConjunctive') ? 'And' : 'Or';
    }
    get shortCircuit(): string {
        let model = this.model as RuleSet;
        return (model.Conjunctive as any).ShortCircuit ? 'Yes' : 'No';
    }
    get changedChildren() {
        return this.view.Data && this.view.Data.changedChildren
            ? this.view.Data.changedChildren
            : [];
    }
    // #endregion

    // #region Data Elements
    private i_Model: AbstractRule;
    
    @ViewChild('picker' , {static: false}) picker: any;

    @Input() view: ViewControllerMember;
    get isLoading(): boolean { return this.view.Loading; };
    get isActive(): boolean { return this.view.Active; };

    
    @Input() selectedDate: Date;
    private initialDate: Date;
    versionTime: Date;
    // demo
    @Input() versionDates: Date[] = [];
    private firstVersionsDate: Date;
    
    // #endregion

    // #region Events
    // #endregion
}