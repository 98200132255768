import { Injectable } from '@angular/core';
import { RulesService } from '@smartobjx/smart.connectors';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ViewControllerMember, ViewControllerService } from '../view/ViewControllerService';
import { Tools } from 'src/app/shared/Tools';
import { AbstractRule } from '@smartobjx/smart.objx.models';
import { CustomValidator } from 'src/app/shared/validation';

export function factory() {
  return (_server: RulesService, _viewController: ViewControllerService): Mediator => {
    return new Mediator( _server, _viewController );
  };
}

@Injectable({
    providedIn: 'root'
})
export default class Mediator {
  constructor( private server: any, private viewController: ViewControllerService ) {}

  findRuleOn( view: ViewControllerMember, ruleID: string, date: Date ): Observable<AbstractRule>{
    view.Loading = true;
    return this.server.findRuleOn( ruleID, Tools.dateToURLStringAsDate( date ) )
        .pipe( tap(
            ( abstractRule: AbstractRule ) => {
                view.Loading = false;
                view.updateModel( abstractRule );
            },
            e => { view.Loading = false; }
        )
    );
  }
  findRuleOnWithVersionInfo( view: ViewControllerMember, ruleID: string, date: Date ){
	view.Loading = true;
	return this.server.findRuleOnWithVersionInfo( ruleID, Tools.dateToURLStringAsDate( date ) )
		.pipe( tap(
			( data: any ) => {
				view.Loading = false;
				view.updateModel( data.rule as AbstractRule );
				view.updateData({ changedChildren: data.changedChildren });
			},
			e => { 
				view.Loading = false;
			}
		)
	);
  }
  
  changeVersion( rule: AbstractRule, date: Date ){
    let viewRef = this.viewController.getView( rule );
    if(CustomValidator.is( rule, 'ruleset' )){
        this.findRuleOnWithVersionInfo( viewRef, rule.OID, date ).toPromise();
    } else {
        this.findRuleOn( viewRef, rule.OID, date ).toPromise();
    }
  }
  
  debugRule( parent: AbstractRule, selectedDate: Date, showTime: boolean, parentComponent:any, isNotInitialDate:boolean, callback: () => void ){
    let rule = Tools.createCopy(parent);      
    let date = CustomValidator.ensureDate( selectedDate );        

    const observable = new Observable(subscriber => {
      let data = {
        versionDateData: { date, showTime:showTime, altClass: isNotInitialDate },
        subscriber
      };
      this.viewController.showDebugger(parentComponent, rule, parent, data );
    });        
    
    observable.subscribe({
          error: e => console.error(e),
          complete: callback
    });
  }

  editRule( parent: AbstractRule, selectedDate: Date, showTime: boolean, isNotInitialDate: boolean, callback: null, parentComponent) {
    let rule = Tools.createCopy(parent);      
    let date = CustomValidator.ensureDate( selectedDate );        
    
    const observable = new Observable(subscriber => {
      this.viewController.clearBy( parent );
      // find this rule with version
      let viewRef = this.prepareByType(rule,parentComponent);
      let data = {
        versionDateData: { date, showTime: showTime, altClass: isNotInitialDate },
        subscriber
      };
      viewRef.replaceData(Object.assign({}, viewRef.Data, data ));
      this.findRuleOn( viewRef, parent.OID,  date ).toPromise();
    });        
    
    observable.subscribe({
          error: e => console.error(e),
          complete: callback
    });
  }

  
  private prepareByType( rule: AbstractRule,parentComponent, parent?: AbstractRule ){
    if( CustomValidator.is( rule, 'ruleset' ) ) {
        return this.viewController.showRuleset(rule,parentComponent, parent);
    } else if( CustomValidator.is( rule, 'calculationrule' ) ) {
        return this.viewController.showCalculationRule(rule, parent,parentComponent);
    } else {
        return this.viewController.showRule(rule,parentComponent, parent);
    }
  }
}