import { Injectable } from '@angular/core';
import { RulesService } from '@smartobjx/smart.connectors';
import { Observable } from 'rxjs';

export function factory() {
  return (_server: RulesService): Mediator => {
    return new Mediator( _server );
  };
}

@Injectable({
    providedIn: 'root'
})
export default class Mediator {
    constructor( private server: any ) {}

    findRuleVersions( ruleID: string ): Observable<any>{
        return this.server.findRuleVersions( ruleID );
    }
}