import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import { BroadcastService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { RulesService, StructuresService, SettingsService } from '@smartobjx/smart.connectors';
import { Perspective } from '@smartobjx/smart.objx.models';
import { environment } from 'src/environments/environment';
import { AuthService } from '../authentication/auth.service';

export function factory() {
  return (
    _server: RulesService,
    _structuresServer: StructuresService,
    _settingsServer: SettingsService,
    _authService: AuthService
    //  broadcastService: BroadcastService,
  ): Mediator => {
    return new Mediator(_server, _structuresServer, _settingsServer, _authService
      // , broadcastService
    );
  };
}

@Injectable({
  providedIn: 'root'
})
export default class Mediator {


  constructor(
    private server: RulesService,
    private structuresServer: StructuresService,
    private settingsServer: SettingsService,
    private authService: AuthService
    //  private broadcastService: BroadcastService,

  ) { }

  checkLoginAndGetName(ownerId: string, subscriberToken: string, perspective: any) {
    this.server.configuration.SubscriberToken = subscriberToken;
    this.server.configuration.POVToken = ownerId;
    return this.server.checkLoginAndGetName(perspective);
  }

  GetTokensforSmartrules(ownerId: string, subscriberToken: string) {
    this.structuresServer.configuration.SubscriberToken = subscriberToken;
    this.structuresServer.configuration.POVToken = ownerId;
    return this.structuresServer.gettokenforsmartrules()
  }

  GetPerspectives(subscriberToken: string, ownerId: string): Observable<Array<Perspective>> {
    this.structuresServer.configuration.SubscriberToken = subscriberToken;
    this.structuresServer.configuration.POVToken = ownerId;
    return this.structuresServer.getAllPerspectives()
  }

  GetNestedClients(ownerId: string, subscriberToken: string, perspectiveId) {
    this.structuresServer.configuration.SubscriberToken = subscriberToken;
    this.structuresServer.configuration.POVToken = ownerId;
    return this.structuresServer.findNestedClients(perspectiveId)
  }

  GetMsalSubcriptions() {
    //   return this.broadcastService.getMSALSubject()
  }


  ActiveMarketPlaceOffer(token, companyName) {


    let headerRequest = {}
    headerRequest['Content-Type'] = "application/json"
    let body = { marketOffer: token, companyName: companyName }
    return this.authService.httpClient.post(`${environment.SubcriptionService}/ResolveRulesOffer/`, body,
      {
        withCredentials: null,
        headers: headerRequest,
      })
  }


}