import { Injectable } from '@angular/core';

import { AbstractRule, RuleSet, Rule } from '@smartobjx/smart.objx.models';

import EventData from 'src/app/shared/EventData.class';
import EventBusService from 'src/app/shared/EventBusService';
import SimpleEvent from 'src/app/shared/SimpleEvent.class';
import { AuthService } from '../authentication/auth.service';
import { Observable, Subject, Subscriber } from 'rxjs';
import { CustomValidator } from 'src/app/shared/validation';
export class ViewControllerService {

    constructor(private _authService: AuthService) {
        // constructor(debug: boolean = false){
        this.i_views = [];
        // this.debug = debug;
    }
    private debug: boolean;
    private i_views: ViewControllerMember[];
    public Events: EventBusService = new EventBusService(); // emits 'viewAdded'
    public updateRootForReplace = new Subject<boolean>();

    get Views(): ViewControllerMember[] {
        return this.i_views;
    }
    getParentComponent(rule: AbstractRule): ViewControllerMember {
        const i = this.i_views.findIndex(o => o.Model === rule);
        if (i > 0)
            return this.i_views[i];
        return undefined;
    }
    getParentComponentForOid(rule: AbstractRule): ViewControllerMember {
        const i = this.i_views.findIndex(o => o.Model.OID === rule.OID && o.Model.OwnerID == rule.OwnerID);
        if (i > 0)
            return this.i_views[i];
        return undefined;
    }
    private showViews() {
        console.log(this.i_views.map((o, i) => `${i}-${o.Active ? 1 : 0}${o.Disabled ? 1 : 0} - ${o.Type}${o.Type == 'usecases' ? '' : ` ${o.Loading ? 'loading...' : `"${o.Model.Name}"`}`}`).join(' > '));
    }
    private truncateViewFrom(i: number) {
        //let passes = true;
        //this.i_views.slice(i, this.i_views.length).forEach(o => {
        //    if (passes){
        //        passes = o.close();
        //    }
        //});
        this.i_views = this.i_views.slice(0, i);
        this.updateActive();
    }
    private updateActive() {
        // if(this.i_views.length > 1)
        this.i_views[this.i_views.length - 1].Active = true;    // set active the last element
    }
    private add(
        Type: string,
        Model: AbstractRule,
        Active: boolean = false,
        Disabled: boolean = false,
        Loading: boolean = false,
        Parent?: AbstractRule,
        Data?: any,
        ParentComponent?: any
    ): ViewControllerMember {
        if (Active) { // set active just the new one
            this.i_views.forEach(v => v.Active = false);
        }
        let newMember = new ViewControllerMember(
            { Type: Type || 'undefined', Model, Active, Disabled, Loading, Parent, Data, ParentComponent },
            this.closeByModel.bind(this),
            this, // controller
            this.debug,
            this._authService,
            () => { this.showViews(); } // updateVerbose
        );
        this.i_views.push(newMember);
        if (this.debug) this.showViews();
        return newMember;
    }
    // NOTES:
    /**
     * First records is ALWAYS the 'usecases'
     * 'usecases' view don't need model to map 
     */
    // #region public methods
    clearBy(rule: AbstractRule) {
        const i = this.i_views.findIndex(o => o.Model === rule);
        if (i > 0)
            this.tryCloseBy(i + 1);

        return this.i_views.length == i + 1;
    }
    private tryCloseBy(i: number) {
        const list = this.i_views.slice(i, this.i_views.length).reverse(); // to start closing for the rightmost
        for (let vi in list) {
            if (!list[vi].close()) {
                this.updateActive();
                return;
            }
        }
        this.updateActive();
    }
    private tryCloseByBase() {
        this.tryCloseBy(1);

        return this.i_views.length === 1;
    }
    dispose() {
        this.i_views = [];
    }
    getParent(rule: AbstractRule): AbstractRule {
        const i = this.i_views.findIndex(o => o.Model === rule);
        if (i > 0)
            return this.i_views[i].Parent;
        return undefined;
    }
    getParentView(rule: AbstractRule): ViewControllerMember {
        const i = this.i_views.findIndex(o => o.Model === rule);
        if (i > 0)
            return this.i_views[i];
        return undefined;
    }
    closeByModel(rule: AbstractRule) {
        const i = this.i_views.findIndex(o => o.Model === rule);
        if (i > 0)
            this.truncateViewFrom(i);
        //this.i_views = this.i_views.slice(0, i);
    }
    getViews() {
        return this.i_views;
    }
    getView(rule: AbstractRule): ViewControllerMember {
        const i = this.i_views.findIndex(o => o.Model === rule);
        if (i > 0)
            return this.i_views[i];
        return undefined;
    }
    showUseCases(parentComponent) {
        this.i_views = [];
        return this.add('usecases', new Rule(), true, false, true, null, null, parentComponent);
    }
    getUseCasesView(): ViewControllerMember {
        return this.i_views[0];
    }
    showUseCaseRuleset(_newUseCase: boolean = false, parentComponent, subscriber: Subscriber<any> = null): ViewControllerMember {
        if (this.tryCloseByBase())
            return this.addUseCaseRuleset(new RuleSet(), { asUseCase: true, newUseCase: _newUseCase, subscriber }, parentComponent) // create a empty view and returns reference 
    }
    addUseCaseRuleset(rule: AbstractRule, data: any, parentComponent): ViewControllerMember {
        this.i_views = this.i_views.slice(0, 1);
        return this.add(this.getRuleView(rule, "ruleset"), rule, true, false, true, null, data, parentComponent); // show ruleset and drop all other views
    }
    showRuleset(rule: AbstractRule, parentComponent, parent?: AbstractRule, subscriber: Subscriber<any> = null) {
        let viewRef;
        if (!parent || this.clearBy(parent))
            viewRef = this.add(this.getRuleView(rule, "ruleset"), rule, true, false, false, parent, { subscriber }, parentComponent);

        if (viewRef) {
            this.moveViewToEnd();
            return viewRef;
        }
    }

    addUseCaseGenerator(data: any, parentComponent): ViewControllerMember {
        this.i_views = this.i_views.slice(0, 1);
        return this.add("use-case-generator", new Rule(), true, false, false, null, data, parentComponent); // show ruleset and drop all other views
    }
    showUseCaseGenerator(parentComponent, subscriber: Subscriber<any> = null): ViewControllerMember {
        if (this.tryCloseByBase())
            return this.addUseCaseGenerator({ subscriber }, parentComponent) // create a empty view and returns reference 
    }

    getRuleView(rule: AbstractRule, view: string): string {
        let ownerId = this._authService.getPOV();
        if (rule.NotReplaceable === true && rule.OwnerID != ownerId)
            return "rule-set-not-replaceable"
        else
            return view;
    }


    // hideRuleset() {
    //     this.i_views.pop();
    //     if(this.i_views.filter(o => o.Type == 'ruleset').length) {  // if any 'ruleset'
    //         this.i_views[this.i_views.length - 1].Active = true;    // set active the last element
    //     }
    // }
    showRule(rule: AbstractRule, parentComponent, parent?: AbstractRule, subscriber: Subscriber<any> = null) {
        let viewRef;
        if (!parent || this.clearBy(parent))
            viewRef = this.add(this.getRuleView(rule, "rule"), rule, true, false, false, parent, { subscriber }, parentComponent);

        if (viewRef) {
            this.moveViewToEnd();
            return viewRef;
        }
    }
    showCallbackRule(rule: AbstractRule, parent: AbstractRule, parentComponent, subscriber?: Subscriber<any>) {
        let viewRef;
        if (!parent || this.clearBy(parent))
            viewRef = this.add(this.getRuleView(rule, 'callbackrule'), rule, true, false, false, parent, { subscriber }, parentComponent);

        if (viewRef) {
            this.moveViewToEnd();
            return viewRef;
        }
    }
    showCalculationRule(rule: AbstractRule, parent: AbstractRule, parentComponent, subscriber?: Subscriber<any>) {
        let viewRef;
        if (!parent || this.clearBy(parent))
            viewRef = this.add(this.getRuleView(rule, 'calculationrule'), rule, true, false, false, parent, { subscriber }, parentComponent);

        if (viewRef) {
            this.moveViewToEnd();
            return viewRef;
        }
    }
    showDebugger(parentComponent, rule?: AbstractRule, parent?: AbstractRule, data?: any): ViewControllerMember {
        let viewRef;
        if (parent) {
            if (this.clearBy(parent))
                viewRef = this.add('debugger', rule || new RuleSet(), true, null, null, null, null, parentComponent); // show ruleset and drop all other views
        } else {
            if (this.tryCloseByBase())
                viewRef = this.add('debugger', rule || new RuleSet(), true, null, null, null, null, parentComponent); // show ruleset and drop all other views
        }

        if (viewRef) {
            if (data != null)
                viewRef.replaceData(Object.assign({}, viewRef.Data, data));

            this.moveViewToEnd();
            return viewRef;
        }
    }
    showDebuggerAdvanced(): ViewControllerMember {
        // return this.add('debugger-advanced', new Rule(), false, false, false, null, { Factbase: fb, InitialFactbase: ifb });
        return this.add('debugger-advanced', new Rule(), false, false, true);
    }
    showVersions(date: Date, versionDates: any[], rule: AbstractRule = null, subscriber?: Subscriber<any>): ViewControllerMember {
        let view;
        if (rule) {
            if (this.clearBy(rule))
                view = this.add('rule-versions', new RuleSet(), true, false, true, null, { Date: date, VersionsDates: versionDates, subscriber }); // show ruleset and drop all other views
        } else {
            if (this.tryCloseByBase())
                view = this.add('rule-versions', new RuleSet(), true, false, true, null, { Date: date, VersionsDates: versionDates, subscriber }); // show ruleset and drop all other views
        }
        if (view) {
            this.moveViewToEnd();
            return view;
        }
    }
    replaceModel(currentModel: AbstractRule, newModel: AbstractRule) {
        this.getView(currentModel).Model = newModel;
    }
    showRuleAdvancedView(parent: Rule, text: string, subscriber: Subscriber<any>) {
        this.clearBy(parent);
        this.add('rule-advanced-view', null, true, false, false, parent, { Expression: text, subscriber });
        this.moveViewToEnd();
    }

    // only for special cases
    query(q: string) {
        switch (q) {
            case 'any(debugger-advanced) and loading':
                return !!this.i_views.filter(o => o.Type == 'debugger-advanced' && o.Loading).length;
            default:
                return false;
        }
    }
    // #endregion

    // #region migrated

    editRuleWithParent(data: any, parentComponent, versionDateData: any = null) {
        let viewRef;
        if (CustomValidator.is(data, 'ruleset') || CustomValidator.is(data, 'rule') || CustomValidator.is(data, 'calculationrule') || CustomValidator.is(data, 'callbackrule')) {
            viewRef = this.showByType(data, parentComponent);
        } else {
            viewRef = this.showByType(data.rule, parentComponent, data.parent, data.subscriber);
        }
        if (!viewRef) return; // any changes of previous view are not saved or cancelled
        viewRef.replaceData(Object.assign({}, viewRef.Data, { versionDateData }, { startDate: data.startDate }));

        // this.moveViewToEnd(); // fire event
        return viewRef;
    }
    private showByType(rule: AbstractRule, parentComponent, parent?: AbstractRule, subscriber: Subscriber<any> = null) {
        let viewRef;
        if (CustomValidator.is(rule, 'ruleset')) {
            viewRef = this.showRuleset(rule, parentComponent, parent, subscriber);
        } else if (CustomValidator.is(rule, 'calculationrule')) {
            viewRef = this.showCalculationRule(rule, parent, parentComponent, subscriber);
        } else if (CustomValidator.is(rule, 'callbackrule')) {
            viewRef = this.showCallbackRule(rule, parent, parentComponent, subscriber);
        } else {
            viewRef = this.showRule(rule, parentComponent, parent, subscriber);
        }
        this.moveViewToEnd();
        return viewRef;
    }
    // maybe we could move this to a FireEvent method
    moveViewToEnd() {
        this.Events.emit(new EventData('viewAdded'));
    }
    set selectedUseCaseID(useCaseID: string) {
        this.i_selectedUseCaseID = useCaseID;
    }
    get selectedUseCaseID(): string {
        return this.i_selectedUseCaseID;
    }
    private i_selectedUseCaseID: string;
    // //#endregion
}

@Injectable({
    providedIn: 'root'
})
export class ViewControllerMember {

    constructor(config: Partial<ViewControllerMember>,
        private _closeByModel: (rule: AbstractRule) => void,
        private controller: ViewControllerService,
        private debug: boolean = false,
        private _authService: AuthService,
        private updateVerbose?: any
    ) {
        Object.assign(this, config);
    }
    // private updateVerbose: () => void;
    Type: string; // 'usecases' | 'ruleset' | 'rule' | 'debugger' | 'debugger-stepper' | 'calculationrule' | 'rule-advanced-view'
    Model: AbstractRule;
    Active: boolean;
    Disabled: boolean;
    Loading: boolean = false;
    Parent: AbstractRule;
    ParentComponent: any;
    Data: any;
    Events: {
        onClose$: () => void,
        onClosed$: SimpleEvent
    } = { onClose$: undefined, onClosed$: new SimpleEvent() };


    IsRuleComponentParent() {
        return 'SaveParents' in this.ParentComponent
    }
    SaveParentsList(version) {
        if (this.IsRuleComponentParent()) {
            this.ParentComponent.SaveParents(version)
        }
        else {
            this.controller.updateRootForReplace.next()
        }
    }


    updateChildren(configRoot) {
        if (!_.isNil(configRoot.PrimRules)) {
            configRoot.PrimRules.forEach(element => {
                let view = this.controller.Views.find(view => view.Model.Name == element.Name)
                if (!_.isNil(view)) { view.updateModelWithRootSave(element) }
                this.updateChildren(element)
            });
        }


    }


    // #region public methods
    updateModel(rule: AbstractRule) {
        let oldname = this.Model.Name; // for debug
        this.Model = rule;
        if (this.debug) {
            console.log(`updating model: "${oldname}" > "${rule.Name}"`);
            this.updateVerbose();
        }
    }

    updateModelWithRootSave(rule: AbstractRule) {
        let oldname = this.Model.Name; // for debug
        this.Model = rule;
        let configRoot = rule as any
        this.updateChildren(configRoot);
        if (this.debug) {
            console.log(`updating model: "${oldname}" > "${rule.Name}"`);
            this.updateVerbose();
        }
    }


    close(force: boolean = false): boolean {
        if (force || typeof this.Events.onClose$ === 'undefined' || this.Events.onClose$()) {
            this._closeByModel(this.Model);
            this.Events.onClosed$.resolve();
            if (this.hasObservable) this.observable.resolve();
            return true;
        }
        return false;
    }
    showVersionDate() {
        this.Data.versionDateData = {
            date: this.Model.Version//, 
            //showTime: showTime, 
            //altClass: isNotInitialDate 
        };
    }
    // 'ruleset (usecase)'
    asUseCase() {
        return this.Data && this.Data.asUseCase;
    }

    newUseCase() {
        return this.Data && this.Data.newUseCase;
    }

    showAdvancedView(text: string, subscriber: Subscriber<any>) {
        this.controller.showRuleAdvancedView(this.Model as Rule, text, subscriber);
    }

    showViewAsAdvanced() {
        return this.Type == 'debugger-advanced'
            || this.Type == 'rule-advanced-view';
    }
    replaceData(data: any) {
        return this.Data = data;
    }
    updateData(data: any) {
        return this.Data = Object.assign({}, this.Data, data);
    }

    get hasObservable(): boolean {
        return typeof this.Data !== 'undefined'             // has data
            && typeof this.Data.subscriber !== 'undefined'  // subscriber is defined
            && this.Data.subscriber !== null;               // subscriber is not empty
    }

    get observable() {
        const { subscriber } = this.Data;

        if (!subscriber)
            throw 'there is not subscriber defined';

        return {
            update(data: any = null) {
                if (this.debug) console.log('updating');
                subscriber.next(data);
            },
            resolve(data: any = null) {
                if (this.debug) console.log('resolving');
                if (data !== null) subscriber.next(data); // if you need a single action. Y you need more than one, call update first.
                subscriber.complete();
            },
            error(data: any) {
                if (this.debug) console.log('throwing error');
                subscriber.error(data || 'something went wrong');
                subscriber.complete();
            }
        }
    }

    changeType(newType: string) {
        this.Type = newType;
    }
    // #endregion
}

