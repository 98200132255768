<mat-card class="mat-main-card">
    <mat-card-title class="overline">
        <span>EDIT RULE</span>
    </mat-card-title>
    <mat-icon class="close" (click)="close()">close</mat-icon>
    <mat-card-content>
        <mat-form-field>
            <input matInput placeholder="Name" [(ngModel)]="data.Name" />
        </mat-form-field>
        <mat-hint>0000 characters</mat-hint>

        <mat-form-field>
            <textarea matInput
                placeholder="Description"
                [(ngModel)]="data.Description"
            ></textarea>
        </mat-form-field>
        <mat-hint>This text should be a description of the actions the <b>Ruleset</b> performs. This text must help the users to undertand what the use case does.</mat-hint>

        <mat-form-field>
            <input matInput [matDatepicker]="version" placeholder="Start date" [(ngModel)]="data.Version" />
            <mat-datepicker-toggle matSuffix [for]="version"></mat-datepicker-toggle>
            <mat-datepicker #version></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <input matInput placeholder="Version name" />
        </mat-form-field>
        <mat-hint>This name will help you to navigate the versions in the future.</mat-hint>

        <mat-form-field>
            <input matInput placeholder="Condition" [(ngModel)]="ruleText" />
        </mat-form-field>
        <mat-hint>You can use <b>simple expressions</b> such as:<br>
            Age > 21 - SSN Is Not Blank - Documents At Least (2)</mat-hint>
        <!-- fix view -->
        <!-- <div style="height: 25px;"></div> -->
        <!-- <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button> -->
    </mat-card-content>
    <div class="rd-adv-info">
        <span class="toolbar-spacer"></span>
        <a color="primary" class="link">View advanced options</a>
    </div>

    <mat-card-actions fxFlexAlign="end" align="end">
        <button mat-raised-button [mat-dialog-close]="data" (click)="modelSaved()" color="primary">Save rule</button>
        <!-- <button mat-raised-button [mat-dialog-close] color="secondary">Discard Changes</button> -->
        <!-- <button mat-raised-button color="primary" [disabled]="!this.modelChanged" (click)="this.onSaveModel()">Save rule set</button> -->
    </mat-card-actions>
</mat-card>