<mat-card class="full-height">
    <mat-card-title>Execution: Step {{stepCount}}</mat-card-title>
    <factbase-display [editable]="false" [(model)]="currentFactbase"></factbase-display>
    <button mat-icon-button [ngStyle]="{visibility: (stepCount > 0) ? 'visible' : 'hidden'}" (click)="stepBackwards()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <button mat-icon-button [ngStyle]="{visibility: (stepCount < finalFactbase.Facts.length - initialFactbase.Facts.length) ? 'visible' : 'hidden'}" (click)="stepForwards()">
        <mat-icon>arrow_forward</mat-icon>
    </button>
</mat-card>
