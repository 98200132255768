<mat-card class="mat-main-card" [formGroup]="form">
    <mat-card-title><span class="primary">Calculation rule - Advanced options</span></mat-card-title>
    <mat-icon class="close" [matTooltip]="helpText" matTooltipClass="multiline-centered">help_outline</mat-icon>
    <mat-card-content class="rav-content" formArrayName="mainValidations">
        <h3>Use the advanced options to write more complex rules.</h3>

        <ng-container *ngFor="let control of mainValidations.controls; index as i" [formGroupName]="i">
            <span class="connector">{{(mainValidations.value[i].Connector || 'if').toLowerCase()}}</span>
            <mat-form-field>
                <input matInput formControlName="First" (change)="refresh()" (keyup)="refreshIf( $event )" />
                <div *ngIf="firstInputHasError(i)" class="error-info">
                    {{ invalidAllLettersOrAllNumberMessage }}
                </div>
            </mat-form-field>
            <mat-form-field>
                <mat-select formControlName="Operator" (selectionChange)="refresh()" > <!-- [ngModelOptions]="{standalone: true}" temp -->
                    <mat-option *ngFor="let operator of operators" [value]="operator.value">
                        {{operator.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <input matInput formControlName="Second" (change)="refresh()" (keyup)="refreshIf( $event )" />
                <div *ngIf="secondInputHasError(i)" class="error-info">
                    {{ invalidAllLettersOrAllNumberMessage }}
                </div>
            </mat-form-field>
            <mat-icon *ngIf="mainValidations.length == 1" class="options" color="primary" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{name: i}">add_box</mat-icon>
            <mat-icon *ngIf="mainValidations.value[i].Connector" class="options" color="primary" (click)="remove(i)">delete</mat-icon>
            <br>
        </ng-container>
        <mat-menu #menu="matMenu">
            <ng-template matMenuContent let-name="name">
                <button mat-menu-item (click)="add('AND', name)">and</button>
                <button mat-menu-item (click)="add('OR', name)">or</button>
            </ng-template>
        </mat-menu>
        <div *ngIf="finalExpression" class="result-view">
            <div class="title">Result</div>
            <div class="content">{{finalExpression}}</div>
        </div>
    </mat-card-content>
    <mat-card-actions fxFlexAlign="end" align="end">
        <button mat-button [mat-dialog-close] color="secondary">Close</button>
        <button mat-raised-button [mat-dialog-close]="finalExpression" color="primary" [disabled]="form.invalid" >OK</button> <!--(click)="accept()"-->
    </mat-card-actions>
</mat-card>