import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ViewControllerService } from '../core-services/view/ViewControllerService';
import Mediator from '../core-services/mediator/fixes.mediator';
import { Title } from '@angular/platform-browser';
import { trigger, transition, style, animate, state } from '@angular/animations'

@Component( {
    selector: 'rule-set-adm',
    templateUrl: './rule-set-adm.component.html',
    styleUrls: [ './rule-set-adm.component.scss' ],
    animations: [
      trigger(
          'card-movement', [
            transition(':enter', [
                style({transform: 'translateX(-10%)', opacity: 0}),
                animate('100ms', style({transform: 'translateX(0)', 'opacity': 1}))
            ]),
            transition(':leave', [
                style({transform: 'translateX(0)', 'opacity': 1}),
                animate('100ms', style({transform: 'translateX(-10%)', 'opacity': 0}))
            ]),
        ]
      )
    ]
} )
export class RuleSetAdmComponent implements OnInit, OnDestroy
{
    // #region Event Handlers
    moveViewToEnd(){
        let win = this.mainViewRef.nativeElement;

        setTimeout(() => {
            win.scrollLeft = win.scrollWidth - win.clientWidth;
        }, 300);
    }
    // #endregion

    // #region Construction & Finalization
    constructor (
        mediator: Mediator,
        title: Title,
        public viewController: ViewControllerService
    )
    {
        // fix - force update POV
        mediator.fixUpdateToken();
        
        title.setTitle('smartObjx - Adm.');
    }

    ngOnInit()
    {
          
        this.viewAddedEvent = this.viewController.Events.on('viewAdded', () =>{
            this.moveViewToEnd();
        });
        // #region horizontal scroll
        // let win = this.mainViewRef.nativeElement;
        // function scrollHorizontally(e: any) {
        //     e = window.event || e;
        //     let delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
        //     win.scrollLeft -= (delta*40); // Multiplied by 40
        //     e.preventDefault();
        // }
        // if (win.addEventListener) {
        //     // IE9, Chrome, Safari, Opera
        //     win.addEventListener("mousewheel", scrollHorizontally, false);
        //     // Firefox
        //     win.addEventListener("DOMMouseScroll", scrollHorizontally, false);
        // } else {
        //     // IE 6/7/8
        //     win.attachEvent("onmousewheel", scrollHorizontally);
        // }
        // #endregion
    }
    
    ngOnDestroy() {
        this.viewAddedEvent.unsubscribe();
    }

    ngAfterViewInit(){
        setTimeout(() => {
            this.viewController.showUseCases(this);
        });
    }
    // #endregion

    // #region Properties
    viewAddedEvent: any;
    // #endregion

    // #region Data Elements
    @ViewChild('mainView', {static: false}) mainViewRef: any; // to move the view when a new card is added
    // #endregion
}