import { Component, Input, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { CustomValidator, ALL_LETTERS_OR_ALL_NUMBER_OR_CONSTANT_REGEXP } from '../shared/validation';
import { ViewControllerMember } from '../core-services/view/ViewControllerService';

@Component({
    selector: 'rule-advanced-view',
    templateUrl: './rule-advanced-view.component.html',
    styleUrls: [ './rule-advanced-view.component.scss' ],
    host: {'class': 'test-advanced-div'}
})
export class RuleAdvancedViewComponent implements OnInit, AfterContentChecked  {
    // #region Event Handlers
    add(andOr: string, previousIndex: number) {
        this.mainValidations.insert(previousIndex + 1, this.getNewValidations(andOr));
    }
    remove(elementIndex: number) {
        this.mainValidations.removeAt(elementIndex);
        if(this.mainValidations.length === 1) { // if there is a only item, removes the connector
            (this.mainValidations.controls[0] as UntypedFormGroup).controls.Connector.setValue(null);
        }
        this.refresh();
    }
    
    refreshIf(ev: any) {
        if(ev.keyCode >= 32 && ev.keyCode <= 111 )
            this.refresh();
    }
    refresh() {
        let result = '', valid = true;
        let expressions: SimpleRuleExpression[] = this.mainValidations.value.map((o: Partial<SimpleRuleExpression>) => new SimpleRuleExpression(o));
        for (let i = 0; i < expressions.length && valid; i++) {
            let e = expressions[i];
            if(valid = e.isValid()) {
                result += e.toString();
            }
        }
        if (valid)
            this.finalExpression = result;
    }
    accept() {
        this.view.observable.update( this.finalExpression );
        this.view.close();
    }
    firstInputHasError(index: number){
        return this.inputHasError(index, 'First');
    }
    secondInputHasError(index: number){
        return this.inputHasError(index, 'Second');
    }
    checkControl( control: any ){
        if(control.value.Operator != 'NOT'){
            control.controls.First.enable();
        } else {
            control.controls.First.disable();
        }
    }
    // #endregion
    
    // #region private methods
    private createSimpleRuleExpression(e: string, connector?: string) {
        let ep = e.split(' ');
        if(ep.length == 2){
            return new SimpleRuleExpression({
                Connector: connector,
                First: "", Operator: ep[0], Second: ep[1]
            })
        }
        return new SimpleRuleExpression({
            Connector: connector,
            First: ep[0], Operator: ep[1], Second: ep[2]
        })
    }
    private testAndAddToList(connector: string, fullExpression: string, list: SimpleRuleExpression[]) {
        const CONNECTOR: string = ` ${connector} `;
        if (!!~fullExpression.indexOf(CONNECTOR)) {
            let expressions = fullExpression.split(CONNECTOR);
            expressions.forEach((e, i) => {
                list.push(this.createSimpleRuleExpression(e, i!==0 ? connector : null));
            })
        }
    }
    private getExpressionsAsList(fullExpression: string) : SimpleRuleExpression[] {
        let list: SimpleRuleExpression[] = [];
        this.testAndAddToList('AND', fullExpression, list);
        this.testAndAddToList('OR', fullExpression, list);
        if(!list.length) {
            list.push(this.createSimpleRuleExpression(fullExpression));
        }
        return list;
    }
    private getFromGroupWithExpression(expression: SimpleRuleExpression) {
        return new UntypedFormGroup({
            Connector: new UntypedFormControl( expression.Connector ),
            First: new UntypedFormControl( { value: expression.First, disabled:  expression.Operator == 'NOT' }, { validators: [Validators.required, CustomValidator.allLettersOrAllNumbersOrConstant] } ),
            Operator: new UntypedFormControl( expression.Operator, { validators: [Validators.required] } ),
            Second: new UntypedFormControl( expression.Second, { validators: [Validators.required, CustomValidator.allLettersOrAllNumbersOrConstant] } ),
        })
    }
    private getNewValidations(andOr?: string) {
        let expression = new SimpleRuleExpression();
        if(andOr) expression.Connector = andOr;
        return this.getFromGroupWithExpression(expression);
    }
    private getControls(index: number) {
        return this.mainValidations.controls[index];
    }
    private inputHasError(index: number, name: string) {
        this.refresh();
        return this.getControls(index)
            .get(name)
            .hasError('invalidAllLettersOrAllNumber')
    }
    // #endregion
    
    // #region Construction and Initialization
    constructor(
        private changeDetector: ChangeDetectorRef
    ) {}

    ngOnInit() {
        let formArray = new UntypedFormArray([]);

        if(this.Expression){
            this.getExpressionsAsList(this.Expression)
                .forEach(o => {
                    formArray.push(this.getFromGroupWithExpression(o));
                });
        } else {
            formArray.push(this.getNewValidations());
        }
        this.form = new UntypedFormGroup({
            mainValidations: formArray
        });

        if(this.Expression)
            this.refresh();
    }
    ngAfterContentChecked() : void {
        this.changeDetector.detectChanges(); // fix finalExpression view
    }
    get mainValidations(): UntypedFormArray {
      return this.form.get("mainValidations") as UntypedFormArray;
    }
    // #endregion
    
    // #region Data Elements
    @Input() Expression: string;

    public form: UntypedFormGroup;
    public operators = [
        { value: '<', name: 'lower than' },
        { value: '>', name: 'greater than' },
        { value: '<=', name: 'lower or equal than' },
        { value: '>=', name: 'greater or equal than' },
        { value: '==', name: 'equal to' },
        { value: '!=', name: 'not equal to' },
        // { value: 'SMALLEROF', name: 'smaller of' },
        // { value: 'GREATEROF', name: 'greater of' },
        { value: 'STARTSWITH', name: 'starts with' },
        { value: 'ENDSWITH', name: 'ends with' },
        { value: 'CONTAINS', name: 'contains' },
        { value: 'NOT', name: 'not' }
    ];
    public expressionParts: SimpleRuleExpression[] = [];
    public finalExpression: string;

    public helpText: string = `Some operators:
        < (Less than) / > (Greater than) / 
        == (Equal to) / != (Not equal to) / 
        And / Or / 
        All Of / Any Of / At Least`;

    public invalidAllLettersOrAllNumberMessage = "All letters or all numbers only!";
    
    @Input() view: ViewControllerMember;
    get isLoading(): boolean { return this.view.Loading; };
    get isActive(): boolean { return this.view.Active; };
    // #endregion

    // #region Event Emitters
    // #endregion
}

// local class
class SimpleRuleExpression {
    constructor(config?: Partial<SimpleRuleExpression>) {
        Object.assign(this, config);
    }
    Connector: string;
    First: string = '';
    Operator: string = '';
    Second: string = '';

    ValidOperators = [
        '<', 
        '>',
        '<=',
        '>=',
        '!=',
        '==',
        // 'SMALLEROF',
        // 'GREATEROF',
        'STARTSWITH',
        'ENDSWITH',
        'CONTAINS',
        'NOT'
    ];
    ValidBinaryOperators = ['AND', 'OR']; // connectors

    shouldHideFirst(){
        return this.Operator == 'NOT';
    }
    toString() {
        if(this.shouldHideFirst()){
            return (this.Connector ? ` ${this.Connector} ` : '') + `${this.Operator} ${this.fixDot(this.Second)}`
        }
        return (this.Connector ? ` ${this.Connector} ` : '') + `${this.fixDot(this.First)} ${this.Operator} ${this.fixDot(this.Second)}`
    }
    isValid() : boolean {
        if(this.shouldHideFirst()){
            return ( !this.Connector || this.isPartOf(this.Connector, this.ValidBinaryOperators) )  // no connector | connector is in [ValidBinaryOperators]
                && ALL_LETTERS_OR_ALL_NUMBER_OR_CONSTANT_REGEXP.test(this.Second);
        }

        return ( !this.Connector || this.isPartOf(this.Connector, this.ValidBinaryOperators) )  // no connector | connector is in [ValidBinaryOperators]
            && ALL_LETTERS_OR_ALL_NUMBER_OR_CONSTANT_REGEXP.test(this.First)
            && this.isPartOf(this.Operator, this.ValidOperators)                                // operator is in [ValidOperators]
            && ALL_LETTERS_OR_ALL_NUMBER_OR_CONSTANT_REGEXP.test(this.Second);
    }
    private isPartOf(item: any, list: any){
        return !!~list.indexOf(item)
    }
    private fixDot(number: string){
        return number[number.length-1] === '.'
            ? number.substring(0, number.length-1)
            : number;
    }
}