import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { SimpleDialogComponent } from './simple-dialog/simple-dialog.component';
import { RuleSetAdmComponent } from './rule-set-adm/rule-set-adm.component';
import { StructuresComponent } from './structures-adm/structures-adm.component';

//#region material
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
//#endregion

import { RuleSetAdmModule } from './rule-set-adm/rule-set-adm.module';
import { AuthGuardService } from './core-services/authentication/guards/auth-guard.service';
import { LoginPortalComponent } from './login-portal/login-portal.component';
import { LogoutComponent } from './login-portal/logout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './core-services/interceptor.service';
import { MsalModule } from '@azure/msal-angular';
import { LoginPortalDemoComponent } from './login-portal-demo/login-portal-demo.component';
import { RegisterSubscriptionComponent } from './register-subscription/register-subscription.component';
import { environment } from 'src/environments/environment';
import {  MatButtonModule } from '@angular/material/button';
import {  MatCardModule } from '@angular/material/card';
import {  MatCheckboxModule } from '@angular/material/checkbox';
import {  MatFormFieldModule } from '@angular/material/form-field';
import {  MatInputModule } from '@angular/material/input';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
export const protectedResourceMap: [string, string[]][] = [
    ['https://graph.microsoft.com/v1.0/me', ['user.read']]
  ];
const appRoutes: Routes = [
    {
        path: '',
        component: RuleSetAdmComponent,
        pathMatch: 'full',
        canActivate: [AuthGuardService],
    },
    {
        path: 'structures',
        component: StructuresComponent,
        pathMatch: 'full',
    },
  //  {
  //      path: 'login',
  //      component: LoginPortalComponent,
  //      pathMatch: 'full',
  //  },
    {
        path: 'loginDemo',
        component: LoginPortalDemoComponent,
        pathMatch: 'full',
    },
    {
        path: 'registerSubscription',
        component: RegisterSubscriptionComponent,
        pathMatch: 'full',
    },
    
    {
        path: 'logout',
        component: LogoutComponent,
    },
    { path: '**', redirectTo: '/' }
]

@NgModule({
    declarations: [
        AppComponent,
        LoginPortalComponent,
        LogoutComponent,
        LoginPortalDemoComponent,
        RegisterSubscriptionComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        MatToolbarModule,
        MatDividerModule,
        MatMenuModule,
        MatProgressBarModule,
        MatCheckboxModule,
        RuleSetAdmModule,
        RouterModule.forRoot(appRoutes, {}),
        //MsalModule.forRoot({
        //    auth: {
        //      clientId: '83cd799a-ada1-469a-bdff-0d3cea34c8a3',
        //      authority: 'https://login.microsoftonline.com/89dfcaeb-b310-4b0c-baaf-f1b8de589897/',
        //      validateAuthority: false,
        //      redirectUri: environment.loginCallback,
        //      postLogoutRedirectUri: environment.loginCallback,
        //      navigateToLoginRequestUrl: true,
        //    },
        //    cache: {
        //      cacheLocation: 'localStorage',
        //      storeAuthStateInCookie: isIE, // set to true for IE 11
        //    },
        //  },
        //  {
        //    popUp: !isIE,
        //    consentScopes: [
        //      'user.read'
        //    ],
        //    unprotectedResources: ['https://www.microsoft.com/en-us/'],
        //    protectedResourceMap,
        //    extraQueryParameters: {}
        //  })
    ],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} }, {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
