<mat-card class="full-height mat-main-card" [class.mat-card-raised]="isActive">
  <mat-progress-bar mode="indeterminate" class="card-top" [class.hidden]="!isLoading && !selfLoading"></mat-progress-bar>

  <mat-card-title class="overline">{{ Title | uppercase }}</mat-card-title>
  <mat-icon class="close" (click)="view.close()">close</mat-icon>

  <ng-container *ngIf="!isLoading">
      <form [formGroup]="form">
          <mat-card-content>
              <version-caption *ngIf="versionDateData"
                  [selectedDate]="versionDateData.date"
                  [showTime]="versionDateData.showTime"
                  [isNotInitialDate]="versionDateData.altClass"
              ></version-caption>

              <mat-form-field class="custom">
                <mat-label>Name</mat-label>
                  <input matInput
                      placeholder="Name"
                      formControlName="name"
                      (change)="checkNameWithVariables()"
                      #nameInput
                  />
              </mat-form-field>
              <mat-hint>0000 characters</mat-hint>

              <mat-form-field>
                <mat-label>Description</mat-label>

                  <textarea matInput
                      placeholder="Description"
                      formControlName="description"
                  ></textarea>
              </mat-form-field>
              <mat-hint [innerHTML]="descriptionHint"></mat-hint>

              <custom-date [(model)]="i_startDate" [(forceInvalid)]="startDateForceInvalid"></custom-date>

              <!-- calculation rule -->
              <ng-container *ngIf="viewType == 'calculationrule'; else simple_content">
                  <mat-form-field>
                    <mat-label>Condition</mat-label>
                    <textarea matInput
                      cdkTextareaAutosize
                      placeholder="Condition"
                      formControlName="ruleExpression"
                      (keyup)="setInvalidExpression( $event, 'ruleExpression' )"
                      (change)="checkExpression( 'ruleExpression' )"
                    ></textarea>
                    <mat-icon matSuffix
                        *ngIf="ruleExpression.valid"
                        class="success"
                    >check_circle_outline</mat-icon>
                    <mat-icon matSuffix
                        *ngIf="ruleExpression.errors && ruleExpression.errors.error"
                        class="error"
                        [matTooltip]="checkExpressionErrorTip"
                    >highlight_off</mat-icon>
                    <mat-icon matSuffix
                        *ngIf="ruleExpression.errors && ruleExpression.errors.loading"
                        class="loading"
                    >help_outline</mat-icon>
                  </mat-form-field>
                  <mat-progress-bar mode="indeterminate" class="simple" [class.hidden]="!ruleExpression.errors || !ruleExpression.errors.loading"></mat-progress-bar>
                  
                  <div class="re-adv-info">
                      <mat-hint>
                          You can use <b>simple expressions</b> such as:<br>
                          Age > 21 - rol STARTSWITH "super." - Documents CONTAINS "important"
                      </mat-hint>
                      <div>
                          <a color="primary" class="link" (click)="this.showAdvancedView('ruleExpression')">Advanced options</a>
                      </div>
                  </div>

                  <mat-form-field>
                      <input matInput
                          placeholder="Algorithm name" 
                          formControlName="algorithmName"
                      />
                  </mat-form-field>
                  <mat-form-field>
                      <input matInput
                          placeholder="Calculation"
                          formControlName="algorithmExpression"
                          (keyup)="setInvalidExpression( $event, 'algorithmExpression' )"
                          (change)="checkExpression( 'algorithmExpression' )"
                      />
                      <mat-icon matSuffix
                          *ngIf="algorithmExpression.valid"
                          class="success"
                      >check_circle_outline</mat-icon>
                      <mat-icon matSuffix
                          *ngIf="algorithmExpression.errors && algorithmExpression.errors.error"
                          class="error"
                          [matTooltip]="checkExpressionErrorTip"
                      >highlight_off</mat-icon>
                      <mat-icon matSuffix
                          *ngIf="algorithmExpression.errors && algorithmExpression.errors.loading"
                          class="loading"
                      >help_outline</mat-icon>
                  </mat-form-field>
                  <mat-progress-bar mode="indeterminate" class="simple" [class.hidden]="!algorithmExpression.errors || !algorithmExpression.errors.loading"></mat-progress-bar>
                  
                  <div class="re-adv-info">
                      <mat-hint>
                          You can use <b>arithmetic operators</b>.
                      </mat-hint>
                      <!-- <div>
                          <a color="primary" class="link" (click)="this.showAdvancedCalculationView()">Advanced options</a>
                      </div> -->
                  </div>
              </ng-container>

              <!-- simple rule -->
              <ng-template #simple_content>
                  <mat-form-field>
                    <mat-label>Condition</mat-label>

                    <textarea matInput
                      cdkTextareaAutosize
                      placeholder="Condition"
                      formControlName="expression"
                      (keyup)="setInvalidExpression( $event )"
                      (change)="checkExpression()"
                    ></textarea>
                    <mat-icon matSuffix
                        *ngIf="expression.valid"
                        class="success"
                    >check_circle_outline</mat-icon>
                    <mat-icon matSuffix
                        *ngIf="expression.errors && expression.errors.error"
                        class="error"
                        [matTooltip]="checkExpressionErrorTip"
                    >highlight_off</mat-icon>
                    <mat-icon matSuffix
                        *ngIf="expression.errors && expression.errors.loading"
                        class="loading"
                    >help_outline</mat-icon>
                  </mat-form-field>
                  <mat-progress-bar mode="indeterminate" class="simple" [class.hidden]="!expression.errors || !expression.errors.loading"></mat-progress-bar>
                  <mat-form-field *ngIf="false">
                    <mat-label>Variables type</mat-label>
                    <mat-select (selectionChange)="ChangeVariableType($event)" [(ngModel)]="SelectedType" [ngModelOptions]="{standalone: true}">
                        
                      <mat-option *ngFor="let type of types" [value]="type" >
                        {{type}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="re-adv-info">
                      <mat-hint>
                          You can use <b>simple expressions</b> such as:<br>
                          Age > 21 - rol STARTSWITH "super." - Documents CONTAINS "important"
                      </mat-hint>
                      <div>
                          <a color="primary" class="link" (click)="this.showAdvancedView()">Advanced options</a>
                      </div>
                  </div>

                  <ng-container *ngIf="viewType == 'callbackrule'">
                      <mat-form-field>
                          <input matInput placeholder="URL" formControlName="url" />
                      </mat-form-field>
                      <div class="url-advanced-options-label">
                          <a color="primary" class="link" (click)="this.showUrlAdvancedOptions(urlAdvancedOptions)">Advanced options</a>
                      </div>
                  </ng-container>
              </ng-template>
          </mat-card-content>

          <!-- footer -->
          <mat-card-actions fxFlexAlign="end" align="end">
              <mat-divider></mat-divider>
              <mat-checkbox
                  formControlName="notReplaceable"
                  color="accent"
                  class="check-no-override"
              >Do not override this rule</mat-checkbox>
              
              <button mat-raised-button
                  type="button"
                  color="primary"
                  class="toolbar-bottom-button"
                  [disabled]="formIsDisabled"
                  (click)="this.onSaveModel()"
              >Save rule</button>
          </mat-card-actions>
      </form>
  </ng-container>
  
  <!-- POP UPS -->
  <ng-template #urlAdvancedOptions>
      <div [formGroup]="urlAdvancedOptionsForm" class="url-advanced-options">

          <div class="h6" matDialogTitle>Configure Headers and Authorization</div>
          <mat-dialog-content>
              <mat-card-title class="rules-title">
                  <span>Authorization</span>
              </mat-card-title>
              <mat-form-field>
                  <!-- <input matInput placeholder="File name" #fileName /> -->
                  <input matInput placeholder="Username" formControlName="username" />
              </mat-form-field>
              <mat-form-field>
                  <input matInput placeholder="Password" formControlName="password" /> <!--type="password"  [type]="hide ? 'password' : 'text'"  -->
                  <!-- <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon> -->
              </mat-form-field>
              <!-- <div class="h2" matDialogTitle>Headers</div> -->
              
              <mat-card-title class="rules-title">
                  <span>Headers</span>
                  <span class="toolbar-spacer"></span>
                  <a color="primary" class="link" (click)="addHeader()">+ Add</a>
              </mat-card-title>
              <div formArrayName="headers">
                  <div 
                  [formGroupName]="i" 
                  *ngFor="let control of urlAdvancedOptionsForm.get('headers').controls; index as i"
                  >
                      <mat-form-field>
                          <input matInput
                              class="form-control" 
                              formControlName="key" 
                              placeholder="Key" 
                              [class.is-invalid]="control.invalid"
                          />
                          <!-- <small class="text-danger" *ngIf="control.invalid">Required</small> -->
                      </mat-form-field>
                      <mat-form-field>
                          <input matInput
                              class="form-control" 
                              formControlName="value" 
                              placeholder="Value" 
                              [class.is-invalid]="control.invalid"
                          />
                              <!-- <small class="text-danger" *ngIf="control.invalid">Required</small> -->
                      </mat-form-field>
                      <div class="options">
                          <mat-icon color="primary" (click)="removeHeader(control)">delete</mat-icon>
                      </div>
                  </div>
              </div>
          </mat-dialog-content>
          <mat-dialog-actions align="end">
              <button mat-button matDialogClose>close</button>
              <button mat-raised-button matDialogClose color="primary" (click)="saveUrlAdvancedOptions()">OK</button>
          </mat-dialog-actions>
      </div>

  </ng-template>

  <ng-template #startDateMinVerification>
      <div class="h6 start-date-message" matDialogTitle>
          <mat-icon>warning</mat-icon>
          <div class="text">Attention</div>
      </div>
      <mat-dialog-content class="start-date-message">
          <ng-container *ngIf="priorToParent; else priorToParentMessageForStartDate">
              A Start date prior to the Parent's Start date has been chosen. <br />
              Do you want to inherit the Start date from the Parent?
          </ng-container>
          <ng-template #priorToParentMessageForStartDate>
              The chosen Start date has already passed. <br />
              Do you want to make these changes active immediately?
          </ng-template>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
          <button mat-button matDialogClose (click)="fixStartDateAndSave()">Yes</button>
          <button mat-raised-button matDialogClose color="primary" (click)="markStartDateAsDirty()">No</button>
      </mat-dialog-actions>
  </ng-template>
</mat-card>