import { Component, Input } from '@angular/core';
import { RuleSet, UseCase, Organization } from '@smartobjx/smart.objx.models';
import { ViewControllerService, ViewControllerMember } from '../core-services/view/ViewControllerService';
import Mediator from '../core-services/mediator/use-case-browser.mediator';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Tools } from '../shared/Tools';

@Component({
    selector: 'use-case-browser',
    templateUrl: './use-case-browser.component.html',
    styleUrls: ['./use-case-browser.component.scss']
})
export class UseCaseBrowserComponent {
    //#region Event Handlers
    getUseCases() {
        this.useCasesError = null; // clean errors

        this.mediator.getUseCases(this.view)
            .subscribe(usecases => {
                this.fillModel(usecases);
            }
                , (error: HttpErrorResponse) => {
                    this.useCasesError = error.message;
                });
    }

    private fillModel(usecases: UseCase[]) {
        if (usecases.length == 0) {
            this.isEmpty = true;
        }
        this.useCasesFull = usecases;
        this.model = usecases;
    }

    filterUseCases(type: string) {
        switch (type) {
            case 'active':
                this.useCases = this.useCasesFull.filter(o => !(o as any).Disabled);
                break;
            case 'disabled':
                this.useCases = this.useCasesFull.filter(o => (o as any).Disabled);
                break;
            default:
                this.useCases = this.useCasesFull;
        }
    }

    useCaseCreated(name: string) {
        this._snackBar.open(`Your Use Case ${name} was successfully added.`, '', {
            duration: 2000,
            verticalPosition: 'top',
            horizontalPosition: 'end',
            panelClass: 'info-primary'
        });

        setTimeout(() => {
            this.getUseCases();
            this.isEmpty = false;
        }, 500)
    }

    //#region list events
    onUseCaseGenerator() {
        this.mediator.showGenerateUseCase(this.view, (updateList) => {
            if (updateList) {
                this.getUseCases();
            }
        });
    }
    onCreate() {
        this.mediator.newUseCase(this.view, this, (name, oid) => {
            this.useCaseCreated(name);
            this.selectedUseCaseID = oid;
        });
    }
    onEdit(usecase: UseCase) {
        this.mediator.editUseCase(
            usecase,
            () => this.selectedUseCaseID = null,
            () => {
                this.selectedUseCaseID = usecase.OID;
            },
            rule => {
                if (this.viewController.getRuleView(rule, "ruleset") === "rule-set-not-replaceable") {
                    let view = this.viewController.getView(rule);
                    view.changeType("rule-set-not-replaceable");
                }
            }
        );
    }
    onVersions(record: any, date: Date, versionDates: any[]) {
        this.mediator.showRuleVersions(
            record,
            date,
            versionDates,
            () => this.selectedUseCaseID = null
        );
        this.selectedUseCaseID = record.OID;
    }
    onDelete(usecase: UseCase) {
        this.selectedUseCaseID = usecase.OID;
        this._dialog.open(SimpleDialogComponent, {
            panelClass: 'smart-objx',
            autoFocus: false,
            data: {
                title: 'Are you sure?',
                titleClass: 'error',
                matIcon: 'close',
                button1Text: 'Yes, disable it',
                content: 'Do you really want to want to disable the use case '
                    + `<b>${usecase.Name}</b> ?`
            }
        }).afterClosed().toPromise()
            .then(action => {
                (document.activeElement as any).blur(); // fix force blur on x
                this.selectedUseCaseID = null;
                if (action) {
                    this.view.Loading = true;
                    this.mediator.disableUseCaseWith(usecase.Name)
                        .then(() => {
                            this._dialog.open(SimpleDialogComponent, {
                                panelClass: 'smart-objx',
                                data: {
                                    title: 'Success!',
                                    titleClass: 'success',
                                    matIcon: 'check',
                                    hideButton1: true,
                                    closeOnSeconds: 3,
                                    button2Text: 'close',
                                    content: 'Your use case '
                                        + `<b>${usecase.Name}</b>`
                                        + ' was succefully disabled.'
                                }
                            }).afterClosed().toPromise()
                            this.getUseCases();
                        });
                }
            });
    }
    onDebug(usecase: UseCase) {
        this.mediator.debugUseCase(this,
            usecase,
            () => this.selectedUseCaseID = null,
            () => this.selectedUseCaseID = usecase.OID
        );
        this.selectedUseCaseID = usecase.OID;
    }
    //#endregion

    searchPreview(e: any, el: any) {
        if (e.key === 'Enter') {
            this.search(el);
        }
        // this.updateAutocomplete( el );
    }
    search(el: any, clean: boolean = true) {
        const value = el.value.trim();
        if (value && Tools.isNotInArray(value, this.searchChips))
            this.searchChips.push(value.toLowerCase());
        if (clean) el.value = '';
    }
    removeChip(value: string) {
        const i = this.searchChips.indexOf(value);
        this.searchChips.splice(i, 1);
    }
    clearSearch() {
        this.searchChips = [];
        this.currentSearch = '';
    }
    // updateAutocomplete( el: any ){
    //     const value = el.value;
    //     if(!value){
    //         this.autocompleteList = [];    
    //     } else {
    //         const items = this.useCasesFull
    //             .filter(o => !!~o.Name.toLowerCase().indexOf(value.toLowerCase()))
    //             .map(o => o.Name);

    //         // console.log('for:', value, '- items:', items);
    //         this.autocompleteList = items;
    //     }
    // }
    // autocompleteSelected( e: any, el: any){
    //     this.search(e.option, false);
    //     el.value = '';
    // }
    //#endregion

    // #region Construction & Finalization
    constructor(
        public _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private viewController: ViewControllerService,
        private mediator: Mediator
    ) { }

    ngAfterViewInit() {
        setTimeout(() => {
            this.getUseCases();
        });
    }
    // #endregion

    // #region Properties
    get model(): UseCase[] {
        return this.i_Model;
    }

    @Input()
    set model(newModel: UseCase[]) {
        this.i_Model = newModel;
    }

    get selectedUseCaseID(): string {
        return this.i_SelectedUseCaseID;
    }

    set selectedUseCaseID(oid: string) {
        this.i_SelectedUseCaseID = oid;
        if (oid)
            this.viewController.selectedUseCaseID = oid;
    }
    get selectedUseCaseIndex(): number {
        return this.useCasesFiltered ? this.useCasesFiltered.map(o => o.OID).indexOf(this.selectedUseCaseID) : -1;
    }

    get useCasesFull(): UseCase[] {
        return this.i_UseCasesFull;
    }
    set useCasesFull(newUseCases: UseCase[]) {
        this.i_UseCasesFull = newUseCases;
        this.useCases = newUseCases;
    }

    get useCases(): UseCase[] {
        return this.i_UseCases;
    }

    set useCases(newUseCases: UseCase[]) {
        this.i_UseCases = newUseCases;
    }

    get useCasesFiltered(): UseCase[] {
        return this.searchList.length
            ? this.useCasesFull.filter(o =>
                this.searchList
                    .filter(c =>
                        Tools.isInArray(c, o.Name.toLowerCase())
                    )
                    .length
            )
            : this.useCasesFull;
    }
    get searchList(): string[] {
        const list = this.searchChips.slice();
        if (this.currentSearch) {
            list.push(this.currentSearch.toLowerCase());
        }
        return list;
    }
    private searchChips: string[] = [];
    private currentSearch: string;
    private autocompleteList: string[];
    // get autocompleteList(): string[]{
    //     return [
    //         'asd', 'ddd', 'gffg'
    //     ];
    // }
    // #endregion

    // #region Data Elements
    i_Model: UseCase[];
    i_UseCases: UseCase[];
    i_SelectedUseCaseID: string;
    useCasesError: string;
    debugging: RuleSet;

    @Input() view: ViewControllerMember;
    get isLoading(): boolean { return this.view.Loading; };
    get isActive(): boolean { return this.view.Active; };
    isEmpty: boolean = false;
    // @Input() selectedUseCaseIndex: number;
    showFilterMessage: string;
    private i_UseCasesFull: UseCase[];
    // #endregion

    // #region Events
    // #endregion
}
