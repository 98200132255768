<mat-card class="full-height mat-main-card" [class.mat-card-raised]="isActive">
  <mat-progress-bar mode="indeterminate" class="card-top" [class.hidden]="!isLoading && !selfLoading"></mat-progress-bar>

  <mat-card-title class="overline">{{ Title | uppercase }}</mat-card-title>
  <mat-icon class="close" (click)="view.close()">close</mat-icon>

  <ng-container *ngIf="!isLoading">
    <form [formGroup]="form">
      <mat-card-content>

        <mat-form-field class="custom">
          <input matInput
            placeholder="Use case name"
            formControlName="usecaseName"
            #nameInput
          />
        </mat-form-field>
        <mat-form-field class="custom">
          <input matInput type="number"
          placeholder="Amount"
          formControlName="amount"
          min="1"
          />
        </mat-form-field>
                
        <fieldset>
          <legend>Rule info:</legend>
          <mat-form-field class="custom">
            <input matInput
              placeholder="Expression"
              formControlName="expression"
              (keyup)="setInvalidExpression( $event )"
              (change)="checkExpression()"
            />
            <mat-icon matSuffix
              *ngIf="expression.valid"
              class="success"
            >check_circle_outline</mat-icon>
            <mat-icon matSuffix
              *ngIf="expression.errors && expression.errors.error"
              class="error"
              [matTooltip]="checkExpressionErrorTip"
            >highlight_off</mat-icon>
            <mat-icon matSuffix
              *ngIf="expression.errors && expression.errors.loading"
              class="loading"
            >help_outline</mat-icon>
          </mat-form-field>
          
          <mat-checkbox [(ngModel)]="convertCheck" style="display:block;" [ngModelOptions]="{standalone: true}">Convert to Calculation Rule</mat-checkbox>
          <mat-form-field class="custom">
            <input matInput
              placeholder="Algorithm expression"
              formControlName="algorithmExpression"
              (keyup)="setInvalidExpression( $event, 'algorithmExpression' )"
              (change)="checkExpression( 'algorithmExpression' )"
            />
            <mat-icon matSuffix
              *ngIf="algorithmExpression.valid"
              class="success"
            >check_circle_outline</mat-icon>
            <mat-icon matSuffix
              *ngIf="algorithmExpression.errors && algorithmExpression.errors.error"
              class="error"
              [matTooltip]="checkExpressionErrorTip"
            >highlight_off</mat-icon>
            <mat-icon matSuffix
              *ngIf="algorithmExpression.errors && algorithmExpression.errors.loading"
              class="loading"
            >help_outline</mat-icon>
          </mat-form-field>
        </fieldset>

      </mat-card-content>

      <!-- footer -->
      <mat-card-actions fxFlexAlign="end" align="end">
        <mat-divider></mat-divider>
        <button mat-raised-button
          type="button"
          color="primary"
          class="toolbar-bottom-button"
          [disabled]="formIsDisabled"
          (click)="onCreate()"
        >Create</button>
      </mat-card-actions>
    </form>
  </ng-container>
</mat-card>