import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Rule } from '@smartobjx/smart.objx.models';
import { RulesService } from '@smartobjx/smart.connectors';

@Component({
    selector: 'rule-dialog',
    templateUrl: './rule-dialog.component.html',
    styleUrls: [ './rule-dialog.component.scss' ]
})
export class RuleDialogComponent implements OnInit {
    // #region Event Handlers
    modelSaved()
    {
        if ( this.ruleText )
        {
            this.data.Operation = this.ruleText;
        }
    }
    getByType(data: any){
        let type = data.$type.substring(17, data.$type.indexOf(','))
        switch(type){
            case 'NumericVariable':
            case 'ComparisonOperator':
            case 'PropositionVariable':
            case 'LogicalOperator':
                return data.Name;
            case 'NumericConstant':
            case 'PropositionConstant':
                return data.Value;
        }
    }
    getExpression(list: any){
        if (list.length === 1)
            return this.getByType(list[0]);
        let mv = this.getByType(list.pop()), i = list.length/2;
        return this.getExpression(list.slice(0, i)) + ' ' + mv + ' ' + this.getExpression(list.slice(i, i*2));
    }
    createExpression(list: any){
        return this.getExpression(list.slice());
    }
    close() {
        this.dialogRef.close();
    }
    // #endregion

    // #region Properties
    get model(): Rule
    {
        return this.i_Model;
    }

    @Input()
    set model( newModel: Rule )
    {
        this.i_Model = newModel;
    }

    get ruleText(): string
    {
        return this.i_RuleText;
    }

    set ruleText( newText: string )
    {
        this.i_RuleText = newText;
    }
    // #endregion
    
    // #region Construction and Initialization
    constructor(
        public dialogRef: MatDialogRef<RuleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        // @Inject(MAT_DIALOG_DATA) public data: Rule,
        private server: RulesService ) {
        if(data.PrimRuleElements && data.PrimRuleElements.length){
            this.ruleText = this.createExpression(data.PrimRuleElements);
        }
    }

    onNoClick(): void {
        this.close();
    }

    ngOnInit()
    {
    }
    // #endregion

    // #region Data Elements
    private i_Model: Rule;
    private i_RuleText: string;
    // #endregion
}