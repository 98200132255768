<mat-card class="center">
  <mat-progress-bar mode="indeterminate" class="card-top"
    [class.hidden]="!isLoading && !isLoadingTokens"></mat-progress-bar>
  <mat-card-content>
    <img src="assets/microsoft.png" style="width: 78%;">
    <br>
    <div>please login with a Microsoft account</div>
    <br>
    <button mat-raised-button *ngIf="!loggedIn" (click)="loginAD()">Login</button>
    <button mat-raised-button *ngIf="loggedIn" (click)="logout()">Logout</button>

  </mat-card-content>
</mat-card>