<div class="simple-layout">
    <div style="overflow: auto; position: relative; height: 100%;" #mainView>
        <mat-grid-tile *ngFor="let view of viewController.getViews(); let i = index"
            [class.test-advanced]="view.showViewAsAdvanced()"
            [@card-movement]
            style="top: 0; height: 100%; width: 32%;"
            [ngStyle]="{ 'left': 'calc(32% * ' + i + ')' }"
        >
            <span *ngIf="view.Type == 'undefined'">undefined type</span>
            <use-case-browser *ngIf="view.Type == 'usecases'"
                class="md-card-full"
                [(model)]="useCases"
                [view]="view"
            ></use-case-browser>
            <rule-set-editor *ngIf="view.Type == 'ruleset'"
                class="md-card-full"
                [(model)]="view.Model"
                [view]="view"
            ></rule-set-editor>
            <rule-editor *ngIf="view.Type == 'rule' || view.Type == 'calculationrule' || view.Type == 'callbackrule'"
                class="md-card-full"
                [(model)]="view.Model"
                [view]="view"
            ></rule-editor>
            <rule-versions *ngIf="view.Type == 'rule-versions'"
                class="md-card-full"
                [(model)]="view.Model"
                [view]="view"
                [versionDates]="view.Data.VersionsDates"
                [selectedDate]="view.Data.Date"
            ></rule-versions>
            <rule-set-not-replaceable *ngIf="view.Type == 'rule-set-not-replaceable'"
                class="md-card-full"
                [(model)]="view.Model"
                [view]="view"
            ></rule-set-not-replaceable>
            <rule-debugger *ngIf="view.Type == 'debugger'"
                class="md-card-full"
                [(model)]="view.Model"
                [view]="view"
            ></rule-debugger>
            <rule-debugger-advanced *ngIf="view.Type == 'debugger-advanced'"
                class="md-card-full"
                [(model)]="view.Model"
                [view]="view"
                [Factbase]="view.Data ? view.Data.Factbase : null"
                [InitialFactbase]="view.Data ? view.Data.InitialFactbase : null"
            ></rule-debugger-advanced>
            <rule-advanced-view *ngIf="view.Type == 'rule-advanced-view'"
                class="md-card-full"
                [view]="view"
                [Expression]="view.Data.Expression"
            ></rule-advanced-view>
             <use-case-generator *ngIf="view.Type == 'use-case-generator'"
                class="md-card-full"
                [(model)]="view.Model"
                [view]="view"
            ></use-case-generator> 
        </mat-grid-tile>
    </div>
</div>
