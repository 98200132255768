<mat-card class="full-height mat-main-card" [class.mat-card-raised]="isActive">
  <mat-progress-bar mode="indeterminate" class="card-top" [class.hidden]="!isLoading"></mat-progress-bar>

  <mat-card-title class="overline">EDIT {{ asUseCase ? "use case" : 'ruleset' | uppercase }}</mat-card-title>
  <mat-icon class="close" (click)="this.view.close()">close</mat-icon>
  <ng-container *ngIf="!isLoading">
    <mat-card-content>
      <version-caption *ngIf="versionDateData"
          [selectedDate]="versionDateData.date"
          [showTime]="versionDateData.showTime"
          [isNotInitialDate]="versionDateData.altClass"
      ></version-caption>
      <form [formGroup]="form">
          <mat-form-field class="custom">
            <mat-label>Name</mat-label>
              <input matInput
                  placeholder="Name"
                  formControlName="name"
                  #nameInput
              />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Description</mat-label>
              <textarea matInput placeholder="Description" 
                  formControlName="description"
              >
              </textarea>
          </mat-form-field>
          <mat-hint [innerHTML]="descriptionHint"></mat-hint>

          <custom-date [(model)]="i_startDate" [(forceInvalid)]="startDateForceInvalid"></custom-date>

          <!-- list of child rules -->
          <ng-container *ngIf="!this.isNew">
              <mat-card-title class="rules-title">
                  <span>Rules</span>
                  <span class="toolbar-spacer"></span>
                  <a [matMenuTriggerFor]="add" color="primary" class="link">+ Add</a>
                  <mat-menu #add [overlapTrigger]="false">
                      <button mat-menu-item (click)="appendRule()">Rule</button>
                      <button mat-menu-item (click)="appendRuleSet()">RuleSet</button>
                      <button mat-menu-item (click)="appendActivityRule()">ActivityRule</button>
                      <button mat-menu-item (click)="appendCalculationRule()">CalculationRule</button>
                  </mat-menu>
                  <a color="primary" class="link" (click)="toggleAdvancedOptions()">Advanced Options {{advOptEnabled ? "▲": "▼"}}</a>                                       
              </mat-card-title>
              <div id="advanced-options" *ngIf="advOptEnabled">
                  <div class="adv-opt">
                      <div style="flex:1">
                          <span style="font-weight: bold">Combination:</span><br>
                          <span>How the results of multiple rules will be combined.</span>
                      </div>
                      <mat-radio-group formControlName="conjunctiveType">
                          <mat-radio-button value="And">And</mat-radio-button>
                          <mat-radio-button value="Or">Or</mat-radio-button>
                      </mat-radio-group>
                  </div>
                  <div class="adv-opt">
                      <div style="flex:1">
                          <span style="font-weight: bold">Short Circuit:</span><br>
                          <span>Evaluate all rules or stop after the first failure.</span>
                      </div>
                      <mat-radio-group formControlName="shortCircuit">
                          <mat-radio-button [value]="true">Yes</mat-radio-button>
                          <mat-radio-button [value]="false">No</mat-radio-button>
                      </mat-radio-group>
                  </div>
              </div>
              <div *ngIf="model.PrimRules && model.PrimRules.length == 0" class="h5 no-children">No rules defined</div>
          </ng-container>            
      </form>
            
      <ng-container *ngIf="!this.isNew">
        <!-- <div>
            <div *ngFor="let item of disableOrEnableStack"> {{ item.rule.Name }} - Disabled? {{ item.disable }}</div>
        </div> -->
        <card-list [(model)]="model.PrimRules"
          (onEdit)="append( $event )"
          (onDelete)="onDisableOrEnable( $event )"
          (onDebug)="onDebug( $event )" showDebug="true"
          (onVersions)="onVersions( $event.record, $event.date, $event.versionDates )"
          (onChanges)="onChanges()"
          [selectedIndex]="selectedIndex"
          [forceRuleStatus]="disableOrEnableStack"
          showType="true"
          [pov]="pov"
          showVersions="true"
          hideOptions="true"
          showSorter="true"
        ></card-list>
      </ng-container>            
    </mat-card-content>        
    <!-- footer -->
    <mat-card-actions fxFlexAlign="end" align="end">
      <mat-divider></mat-divider>
      <form [formGroup]="form">
          <mat-checkbox
              formControlName="notReplaceable"
              color="accent"
              class="check-no-override"
          >Do not override this rule</mat-checkbox>
      </form>
      <button mat-raised-button
          type="button"
          color="primary"
          class="toolbar-bottom-button"
          [disabled]="formIsDisabled"
          (click)="this.onSaveModel()">Save {{ asUseCase ? 'use case' : 'rule set' }}</button> 
    </mat-card-actions>
  </ng-container>

  <!-- POP UPS -->
  <ng-template #startDateMinVerification>
    <div class="h6 start-date-message" matDialogTitle>
      <mat-icon>warning</mat-icon>
      <div class="text">Attention</div>
    </div>
    <mat-dialog-content class="start-date-message">
      <ng-container *ngIf="asUseCase || !priorToParent; else noUseCaseMessageForStartDate">
          The chosen Start date has already passed. <br />
          Do you want to make these changes active immediately?
      </ng-container>
      <ng-template #noUseCaseMessageForStartDate>
        A Start date prior to the Parent's Start date has been chosen. <br />
        Do you want to inherit the Start date from the Parent?
      </ng-template>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button matDialogClose (click)="fixStartDateAndSave()">Yes</button>
      <button mat-raised-button matDialogClose color="primary" (click)="markStartDateAsDirty()">No</button>
    </mat-dialog-actions>
  </ng-template>
</mat-card>